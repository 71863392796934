import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RolePermissionComponent from "../../permissions/RolePermissionComponent";

const defaultStyles = {
  fontSize: "12px",
  fontWeight: 400,
  mb: "23px",
};

function createPageInfo(label, url) {
  return { label, url };
}

const reports = [
  createPageInfo("LG Report", "/report/lg"),
  createPageInfo("CU Report", "/report/cu"),
  createPageInfo("Lead Report", "/report/lead"),
  createPageInfo("Assignment Logging Report", "/report/assignment-logging"),
];

export default function ReportsMenu(props) {
  const { anchorEl, openMenu, closeMenu } = props;

  return (
    <RolePermissionComponent roles={["admin", "manager"]}>
      <Button
        onClick={openMenu}
        sx={{
          mb: defaultStyles.mb,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: defaultStyles.fontSize,
          fontWeight: defaultStyles.fontWeight,
        }}
      >
        <p style={{ margin: 0 }}>Reports</p>
        <KeyboardArrowDownIcon fontSize="small" sx={{ pb: "3px" }} />
      </Button>
      <Menu
        sx={{ mt: "25px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "left",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {reports.map((management, index) => (
          <MenuItem key={index} onClick={closeMenu}>
            <Typography
              component={Link}
              to={management.url}
              sx={{
                textDecoration: "none",
                color: "#222",
                width: "100%",
              }}
            >
              {management.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </RolePermissionComponent>
  );
}
