import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// routes
// import PublicRoutes from "./routes/PublicRoutes";
import routes from "./routes";

// 404
import NotFound from "./pages/NotFound";

// theme
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import theme from "./theme";

// state
import { useSelector } from "react-redux";
import { redirectRoutes } from "./constants/permissions";
import Login from "./pages/Login";

function App() {
  const { status, user } = useSelector((state) => state.auth);

  // handle auth and allow only if auth or permitted
  const handleAuthRoutes = (route, index) => {
    if (!status) {
      return <Route path="*" key={index} element={<Login />} />;
    }

    // redirect if user doesn't have access permission
    const canAccess = route.canAccessBy.find(
      (role) => role === user.accountType
    );

    // console.log("canAccess", canAccess);

    if (!canAccess) {
      const redirect = redirectRoutes.find(
        (redirectRoute) => redirectRoute.accountType === user.accountType
      );

      // console.log("redirect", redirect);

      return (
        <Route
          path="*"
          key={index}
          element={<Navigate to={redirect.redirectTo} replace />}
        />
      );
    }

    // all auth routes will be available in the DOM tree
    return <Route path={route.path} element={route.element} key={index} />;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            {routes.map((route, index) => handleAuthRoutes(route, index))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
