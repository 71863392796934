import { useEffect, useState, useCallback } from "react";

// mui
import Box from "@mui/material/Box";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import ZipcodeAssignment from "../../components/tables/ZipcodeAssignment";

// state
import store from "../../store";
import { fetchUserListOnly } from "../../store/actions/CustomerActions";
import SmallFormModalComponent from "../../components/modals/SmallFormModalComponent";

function Assignment() {
  useEffect(() => {
    store.dispatch(fetchUserListOnly());
  }, []);

  const [modalOptions, setModalOptions] = useState({
    open: false,
    for: "customer-form",
    data: {},
    size: "md",
  });

  const handleOpen = useCallback((type, data, size) => {
    setModalOptions({
      open: true,
      for: type,
      data: data,
      size: size,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalOptions({ open: false, for: "", data: {} });
  }, []);

  return (
    <MainRootElement>
      <Box sx={{ mb: 2 }}></Box>

      <ZipcodeAssignment openModal={handleOpen} />

      {modalOptions.for === "edit-user-form" && modalOptions.open ? (
        <SmallFormModalComponent
          modalOptions={modalOptions}
          closeModal={closeModal}
        />
      ) : null}
    </MainRootElement>
  );
}

export default Assignment;
