import { useState, useCallback, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";

// components
import ManagementMenu from "./ManagementMenu";
import ReportstMenu from "./ReportsMenu";
import InfoMenu from "./InfoMenu";
import PagesMenu from "./PagesMenu";
import CustomMenu from "./CustomMenu";
import ProfileMenu from "./ProfileMenu";

function Nav() {
  const [anchorElInfo, setAnchorInfo] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElReport, setAnchorElReport] = useState(null);
  const [anchorElManagement, setAnchorElManagement] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);

  /**
   * Nav Menu Handler
   */
  const handleOpenNavMenu = useCallback((event) => {
    setAnchorElNav(event.currentTarget);
  }, []);
  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  /**
   * Profile Menu Handler
   */
  const handleOpenProfileMenu = useCallback((event) => {
    setAnchorElProfile(event.currentTarget);
  }, []);
  const handleCloseProfileMenu = useCallback(() => {
    setAnchorElProfile(null);
  }, []);

  /**
   * Reports Menu Handler
   */
  const handleOpenReportMenu = useCallback((event) => {
    setAnchorElReport(event.currentTarget);
  }, []);
  const handleCloseReportMenu = useCallback(() => {
    setAnchorElReport(null);
  }, []);

  /**
   * management Menu Handler
   */
  const handleOpenManagementMenu = useCallback((event) => {
    setAnchorElManagement(event.currentTarget);
  }, []);

  const handleCloseManagementMenu = useCallback(() => {
    setAnchorElManagement(null);
  }, []);

  /* const handleCloseCloserMenu = () => {
    setAnchorElCloser(null);
  }; */

  /**
   * Info Menu Handler
   */
  const handleOpenInfoMenu = useCallback((event) => {
    setAnchorInfo(event.currentTarget);
  }, []);
  const handleCloseListMenu = useCallback(() => {
    setAnchorInfo(null);
  }, []);

  return (
    <Box sx={{ display: "flex", maxHeight: 40 }} className="root-ele">
      <AppBar
        color="custom"
        component="nav"
        className="ws-app-bar"
        elevation={0}
        sx={{ maxHeight: 40 }}
      >
        <Container maxWidth="xl" className="ws-container">
          <Toolbar disableGutters className="ws-tool-bar">
            {/* <HomeIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            ></Typography>

            <PagesMenu
              anchorEl={anchorElNav}
              openMenu={handleOpenNavMenu}
              closeMenu={handleCloseNavMenu}
            />
            {/* <HomeIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LOGO
            </Typography>

            <CustomMenu
              label="Home"
              url="/"
              roles={["admin", "staff", "manager"]}
            />

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <InfoMenu
                anchorEl={anchorElInfo}
                openMenu={handleOpenInfoMenu}
                closeMenu={handleCloseListMenu}
              />

              <CustomMenu
                label="Lead Generator"
                url="/lead-generator"
                roles={["admin", "staff", "manager"]}
              />

              <CustomMenu
                label="Lead"
                url="/lead?page=lead"
                roles={["admin", "central-user", "staff", "manager"]}
              />

              <CustomMenu
                label="Central"
                url="/central?tab=tbstc&category=assign-to-closer"
                roles={["admin", "central-user", "manager"]}
              />

              <CustomMenu
                label="Central"
                url="/central?tab=atv&category=assign-to-vendor"
                roles={["closer"]}
              />

              <CustomMenu
                label="Leads"
                url="/central?tab=va&category=assigned-vendor"
                roles={["vendor"]}
              />

              <CustomMenu label="Vendor" url="/vendor" roles={["admin"]} />

              <CustomMenu
                label="Closer"
                url="/closer?page=closer"
                roles={["admin", "closer"]}
              />

              <CustomMenu label="Approval" url="/approval" roles={["admin"]} />

              <ReportstMenu
                anchorEl={anchorElReport}
                openMenu={handleOpenReportMenu}
                closeMenu={handleCloseReportMenu}
              />
              <ManagementMenu
                anchorEl={anchorElManagement}
                openMenu={handleOpenManagementMenu}
                closeMenu={handleCloseManagementMenu}
              />
            </Box>

            <ProfileMenu
              anchorEl={anchorElProfile}
              openMenu={handleOpenProfileMenu}
              closeMenu={handleCloseProfileMenu}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="main" sx={{ p: 2 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

export default memo(Nav);
