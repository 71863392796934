import { useState, useCallback } from "react";
import Swal from "sweetalert2";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// components
import NewAccountForm from "../../components/forms/NewAccountForm";
import MainRootElement from "../../components/layouts/MainRootElement";

// img
import LoginCover from "../../assets/images/cover-2.jpg";

// service
import { createAccount } from "../../services/AuthServices";

// xycyxepiqo@mailinator.com

function NewAccount() {
  const [accountCreated, setAccountCreated] = useState({
    loading: false,
    status: false,
  });

  const handleSetAccountCreated = (loading, status) => {
    setAccountCreated({ loading, status });
  };

  const handleCreateAccount = useCallback(async (data) => {
    handleSetAccountCreated(true, false);

    try {
      await createAccount(data);

      handleSetAccountCreated(false, true);

      await Swal.fire({
        icon: "success",
        title: "Successfully registered.",
        text: "Please click on the link in your email to validate your registration",
      });

      handleSetAccountCreated(false, false);
    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.data.message,
      });

      handleSetAccountCreated(false, false);
    }
  }, []);

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff" }}>
        <Grid container spacing={1}>
          <Grid xs={7}>
            <img
              src={LoginCover}
              className="register-form-cover"
              alt="register-form-cover"
            />
          </Grid>

          <Grid item xs={5}>
            <h3 style={{ marginTop: 0 }}>Register a New User</h3>
            <hr />
            <br />
            <NewAccountForm
              loading={accountCreated.loading}
              accountCreated={accountCreated.status}
              createAccount={handleCreateAccount}
            />
          </Grid>
        </Grid>
      </Box>
    </MainRootElement>
  );
}

export default NewAccount;
