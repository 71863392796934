// mui
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const TotalComponent = (props) => {
  const { totalRecords } = useSelector((state) => state.customer);

  return (
    <Box
      sx={{
        backgroundColor: "#023e8a",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <p
        style={{
          margin: 0,
          padding: "5px 5px",
          fontWeight: 600,
          color: "#fff",
          fontSize: 13,
        }}
      >
        Total: {props.totalRecords ?? totalRecords}
      </p>
    </Box>
  );
};

export default TotalComponent;
