import React from "react";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import Typography from "@mui/material/Typography";

// state
import { useSelector } from "react-redux";
import TotalComponent from "../TotalComponent";
import AppModel from "../../models/AppModel";
import InfiniteScrollComponent from "../InfiniteScrollComponent";

const tableBodyTitleStyle = {
  color: "#484848",
  fontSize: "0.830rem",
  padding: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const tableCellStyle = {
  paddingTop: 0,
  paddingRight: 15,
  paddingBottom: 0,
  // paddingLeft: 1,
};

export const LeadReportTable = ({ loadMore, handlePrint }) => {
  console.log("Rendering => LeadReportTable");
  const { loading, customers, totalRecords, loadMoreOptions } = useSelector(
    (state) => state.customer
  );

  /* const loadMore = async () => {
    const newUrlParams = {
      ...urlParams,
      skip: loadMoreOptions.skip + 100,
    };

    store.dispatch(loadMoreCustomers(newUrlParams, loadMoreOptions.skip + 100));
  }; */

  return (
    <TableContainer component={Paper} sx={{ height: "520px" }}>
      <InfiniteScrollComponent
        hasMore={loadMoreOptions.hasMore}
        loadMore={loadMore}
      >
        <h2 style={{ textAlign: "center" }}>Lead Report</h2>

        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>First Name</Typography>
              </TableCell>

              <TableCell>
                <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 70 }}>
                  Middle Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Last Name</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Birth Date</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Phone</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Address</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>City</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>State</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Zip Code</Typography>
              </TableCell>

              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Import Date</Typography>
              </TableCell>

              <TableCell>
                <Typography sx={tableBodyTitleStyle}>LG Assign</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={tableBodyTitleStyle}>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              [1, 2, 3, 4, 5].map((nr) => {
                return (
                  <TableRow key={nr}>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>

                    <TableCell>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={30}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading &&
              customers.map((customer, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 50 }}>
                      {customer.firstName}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 70 }}>
                      {customer.middleName}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 50 }}>
                      {customer.lastName}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={tableBodyTitleStyle}>
                      {AppModel.formatDate(customer.birthDate)}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 80 }}>
                      {customer.phone}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 200 }}>
                      {customer.address}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 80 }}>
                      {customer.city}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 50 }}>
                      {customer.state}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={tableBodyTitleStyle}>
                      {customer.zipCode}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={tableBodyTitleStyle}>
                      {AppModel.formatDate(customer.importDate)}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 80 }}>
                      {customer.staffAssigned && customer.staffAssigned.fname
                        ? customer.staffAssigned.fname
                        : ""}{" "}
                      {customer.staffAssigned && customer.staffAssigned.lname
                        ? customer.staffAssigned.lname
                        : ""}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Tooltip title="Print">
                      <IconButton
                        color="info"
                        aria-label="Print"
                        onClick={() => handlePrint(customer._id)}
                      >
                        <PrintOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </InfiniteScrollComponent>
      <TotalComponent loading={loading} totalRecords={totalRecords} />
    </TableContainer>
  );
};

export default React.memo(LeadReportTable);
