import { useEffect } from "react";

// mui
import Box from "@mui/material/Box";

// state
import { useSelector } from "react-redux";
import store from "../store";
import {
  saveCustomers,
  saveUserList,
  saveZipCodes,
  setLoading,
} from "../store/actions/CustomerActions";

// components
import MuiDataTable from "../components/tables/MuiDataTable";
import MainRootElement from "../components/layouts/MainRootElement";
import CustomerModel from "../models/CustomerModel";
import {
  fetchUserListService,
  fetchZipcodesService,
  getCustomerInfo,
} from "../services/CustomerServices";

function Lead() {
  console.log(`Rendering lead page`);
  const { loading } = useSelector((state) => state.customer);

  const _urlParams = {
    process: "LE",
    isLeadPage: true,
    limit: 100,
    skip: 0,
  };

  const _fetchCustomers = async () => {
    if (!loading) store.dispatch(setLoading(true));
    let res = await CustomerModel.fetchCustomers(_urlParams);

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get("id") !== null) {
      const customer = await getCustomerInfo(searchParams.get("id"));
      res.customers.unshift(customer);
    }

    store.dispatch(saveCustomers(res));
  };

  useEffect(() => {
    const fetchOterDatas = async () => {
      const res = await fetchZipcodesService();
      const userList = await fetchUserListService();

      store.dispatch(saveUserList(userList));
      store.dispatch(saveZipCodes(res));
    };

    fetchOterDatas();

    _fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff", pl: 1 }}>
        <MuiDataTable />
      </Box>
    </MainRootElement>
  );
}

export default Lead;
