// import { useEffect, useState } from "react";
import Swal from "sweetalert2";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import TotalComponent from "../TotalComponent";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { deleteUser } from "../../store/actions/CustomerActions";

const tableCellStyle = {
  paddingTop: 0,
  paddingRight: 25,
  paddingBottom: 0,
  border: "none",
};

const tableBodyTitleStyle = {
  color: "#484848",
  fontSize: "0.830rem",
  padding: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const accountTypeLabel = {
  staff: "Lead Generator",
  "central-user": "Central User",
  closer: "Closer",
  manager: "Manager",
  admin: "Admin",
};

export const ZipcodeAssignment = ({ openModal }) => {
  const { loading, userList } = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.auth);

  const handleDeleteUser = async (selectedUser) => {
    if (user._id === selectedUser._id) {
      Swal.fire("Oops", "You are not permitted to delete yourself!", "error");

      return;
    }

    const res = await Swal.fire({
      title: "Are you sure?",
      html: `Do you want to delete <strong>${selectedUser.fname} ${selectedUser.lname}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Yes, delete it!",
    });

    if (res.isConfirmed) {
      console.log("start delete");
      // delete selectedUser
      store.dispatch(deleteUser(selectedUser._id));
    }
  };

  return (
    <TableContainer component={Paper} sx={{ minHeight: 1 }}>
      <h2 style={{ textAlign: "center" }}>Zipcode and Phone Assignment</h2>

      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                First Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                Email Address
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                Twillo Phone Number
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                Role
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                List of Zipcodes
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                Leads
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                Total Zipcodes
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                Actions
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((nr) => {
              return (
                <TableRow key={nr}>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((header, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={35}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

          {!loading &&
            userList.map((user, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  verticalAlign: "baseline",
                }}
              >
                <TableCell style={tableCellStyle}>
                  <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                    {user.fname} {user.lname}
                  </Typography>
                </TableCell>

                <TableCell style={tableCellStyle}>
                  <Typography style={{ ...tableBodyTitleStyle, maxWidth: 180 }}>
                    {user.email}
                  </Typography>
                </TableCell>
                <TableCell style={tableCellStyle}>
                  <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                    {user.phonecall}
                  </Typography>
                </TableCell>
                <TableCell style={tableCellStyle}>
                  <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                    {accountTypeLabel[user.accountType]}
                  </Typography>
                </TableCell>

                <TableCell style={{ ...tableCellStyle, width: 350 }}>
                  <Typography
                    style={{
                      fontSize: 13,
                      wordBreak: "break-word",
                    }}
                  >
                    {user.zipCodes}
                  </Typography>
                </TableCell>
                <TableCell style={tableCellStyle}>
                  <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                    {user.noOfZipCodes ?? 0}
                  </Typography>
                </TableCell>
                <TableCell style={tableCellStyle}>
                  <Typography style={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                    {user.noOfLeads ?? 0}
                  </Typography>
                </TableCell>
                <TableCell style={tableCellStyle}>
                  <Stack direction="row">
                    <Tooltip title="Edit">
                      <IconButton
                        color="actionButton"
                        aria-label="Edit"
                        onClick={() => openModal("edit-user-form", user, "xs")}
                      >
                        <EditIcon style={{ fontSize: 17 }} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        color="actionButton"
                        aria-label="Delete"
                        onClick={() => handleDeleteUser(user)}
                      >
                        <DeleteIcon style={{ fontSize: 17 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TotalComponent loading={loading} totalRecords={userList.length} />
    </TableContainer>
  );
};

export default ZipcodeAssignment;
