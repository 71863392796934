import { useCallback, useEffect, useState } from "react";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import ManageUsersTable from "../../components/tables/ManageUsersTable";
import SmallFormModalComponent from "../../components/modals/SmallFormModalComponent";

// state
import store from "../../store";
import { fetchUserListOnly } from "../../store/actions/CustomerActions";

function ManageUsers() {
  useEffect(() => {
    store.dispatch(fetchUserListOnly({ deletedUsers: true }));
  }, []);

  const [modalOptions, setModalOptions] = useState({
    open: false,
    for: "customer-form",
    data: {},
    size: "md",
  });

  const handleOpen = useCallback((type, data, size) => {
    setModalOptions({
      open: true,
      for: type,
      data: data,
      size: size,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalOptions({ open: false, for: "", data: {} });
  }, []);


  return (
    <MainRootElement>
      <ManageUsersTable openModal={handleOpen}/>

      {modalOptions.for === "manage-user-form" && modalOptions.open ? (
        <SmallFormModalComponent
          modalOptions={modalOptions}
          closeModal={closeModal}
        />
      ) : null}
    </MainRootElement>
  );
}

export default ManageUsers;
