import { useEffect } from "react";

// mui
import Box from "@mui/material/Box";

// state
import { useSelector } from "react-redux";
import store from "../store";
import {
  saveCustomers,
  saveUserList,
  saveZipCodes,
  setLoading,
} from "../store/actions/CustomerActions";

// components
import MuiDataTable from "../components/tables/MuiDataTable";
import MainRootElement from "../components/layouts/MainRootElement";
import CustomerModel from "../models/CustomerModel";
import { useSearchParams } from "react-router-dom";
import {
  fetchUserListService,
  fetchZipcodesService,
} from "../services/CustomerServices";

function InfoList() {
  let [searchParams] = useSearchParams();

  const { loading, zipCodes, staffs } = useSelector((state) => state.customer);

  const _urlParams = {
    tag: searchParams.get("tag") ?? "",
    process: "",
    filterYearStart: 0,
    filterYearEnd: 0,
    filterSearch: "",
    q: "",
    isInfoPage: true,
    limit: 100,
    skip: 0,
  };

  const _fetchCustomers = async () => {
    if (!loading) store.dispatch(setLoading(true));

    const res = await CustomerModel.fetchCustomers(_urlParams);
    store.dispatch(saveCustomers(res));
  };

  const fetchZipCodes = async () => {
    const res = await fetchZipcodesService();

    store.dispatch(saveZipCodes(res));
  };

  const fetchUserList = async () => {
    const userList = await fetchUserListService();
    store.dispatch(saveUserList(userList));
  };

  useEffect(() => {
    if (zipCodes.length <= 0) fetchZipCodes();
    if (staffs.length <= 0) fetchUserList();

    _fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("tag")]);

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff", pl: 1 }}>
        <MuiDataTable />
      </Box>
    </MainRootElement>
  );
}

export default InfoList;
