import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

function LoadingButtonComponent({
  handleClick,
  loading,
  label,
  disabled = false,
}) {
  return (
    <div>
      <LoadingButton
        color="success"
        onClick={handleClick}
        loading={loading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        disabled={disabled}
      >
        {label}
      </LoadingButton>
    </div>
  );
}

export default LoadingButtonComponent;
