import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BirthYearSelect(props) {
  const [years, setYears] = React.useState([]);

  const generateYears = React.useCallback(() => {
    const currentYear = new Date().getFullYear() + 1;
    let newYear = [];

    for (let i = 1900; i < currentYear; i++) {
      newYear.push(i);
    }

    setYears(newYear);
  }, []);

  React.useEffect(() => {
    generateYears();
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl sx={{ minWidth: 100, mr: 1 }} size="small">
        <InputLabel id="demo-select-small">Start</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small1"
          value={props.startDate}
          label="Start"
          onChange={(e) => props.setStartDate(e.target.value)}
        >
          <MenuItem value="--">--</MenuItem>
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">End</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-smal2"
          value={props.endDate}
          label="End"
          onChange={(e) => props.setEndDate(e.target.value)}
        >
          <MenuItem value="--">--</MenuItem>
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
