import Swal from "sweetalert2";
import config from "../config/app";

// mui
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// others
import FormCloseButton from "./buttons/FormCloseButton";
import { useSelector } from "react-redux";
import {
  saveCustomers,
  setFormSubmitting,
} from "../store/actions/CustomerActions";
import store from "../store";
import { deleteDocService } from "../services/CustomerServices";

const InsuranceDocs = (props) => {
  const { data, closeForm, user } = props;

  const { customers, totalRecords, urlParams, loadMoreOptions } = useSelector(
    (state) => state.customer
  );

  // console.log(`data`, data);

  const showPDF = (doc) => {
    Swal.fire({
      title: `<strong>${doc.filename}</strong>`,
      width: 600,
      html: `<iframe
        src="${config.baseUrl}/customer/get-insurance-doc/${doc.id}?token=${user.token}"
        width="500px"
        height="500px"
      ></iframe>`,
      showCloseButton: true,
    });
  };

  const deleteTheDoc = async (docId) => {
    try {
      const result = await Swal.fire({
        title: "Would you like to delete this document?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) return;

      //   console.log("customerId, docId", data._id, docId);

      store.dispatch(setFormSubmitting(true));

      await deleteDocService(data._id, docId);

      let newCustomers = customers;
      let newCustomersLength = newCustomers.length;

      for (let i = 0; i < newCustomersLength; i++) {
        const newCustomer = newCustomers[i];

        if (newCustomer._id === data._id) {
          //   console.log("newCustomer", newCustomer);
          if (newCustomer.insurancedoc && newCustomer.insurancedoc.length > 0) {
            newCustomers[i].insurancedoc = newCustomer.insurancedoc.filter(
              (doc) => doc.id !== docId
            );
          }

          break;
        }
      }

      store.dispatch(
        saveCustomers({
          customers: newCustomers,
          total: totalRecords,
          loadMoreOptions: { ...loadMoreOptions },
          urlParams,
        })
      );
    } catch (error) {
      //   console.log("error", error);
      alert("Server timeout");
      store.dispatch(setFormSubmitting(false));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="d-flex justify-between">
          <h3 style={{ marginBottom: 0 }}>Insurance Documents</h3>
          <FormCloseButton closeForm={closeForm} />
        </div>
      </Grid>

      {data.insurancedoc && data.insurancedoc.length > 0 ? (
        <Grid item xs={12}>
          {data.insurancedoc.map((doc, index) => (
            <div key={index}>
              {doc.filename ? (
                <div className="d-flex" style={{ marginBottom: 5 }}>
                  <p style={{ fontSize: 14, margin: 0 }}>{doc.filename} -</p>
                  <div style={{ paddingLeft: 5 }}>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        aria-label="view"
                        color="primary"
                        size="small"
                        onClick={() => showPDF(doc)}
                      >
                        <VisibilityRoundedIcon style={{ fontSize: 17 }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        size="small"
                        onClick={() => deleteTheDoc(doc.id)}
                      >
                        <DeleteRoundedIcon style={{ fontSize: 17 }} />
                      </IconButton>
                    </Stack>
                  </div>
                </div>
              ) : null}
            </div>
          ))}

          {/*  <iframe
              src={`${config.baseUrl}/customer/get-insurance-doc/639ff0a9a994e76a12b0ff78?token=${user.token}`}
              width="500px"
              height="500px"
            ></iframe> */}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default InsuranceDocs;
