import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CustomerModel from "../models/CustomerModel";
import store from "../store";
import { saveCustomers } from "../store/actions/CustomerActions";

function InfiniteScrollComponent(props) {
  const { loading, urlParams, loadMoreOptions, customers } = useSelector(
    (state) => state.customer
  );

  const loadMore = async () => {
    if (loading) return;

    const res = await CustomerModel.loadMoreCustomers(
      urlParams,
      loadMoreOptions,
      customers
    );

    store.dispatch(saveCustomers(res));
  };

  return (
    <InfiniteScroll
      style={{ position: "relative" }}
      initialLoad={false}
      loadMore={loadMore}
      hasMore={!loading && loadMoreOptions.hasMore}
      loader={
        <div className="loader" key={0} style={{ textAlign: "center" }}>
          {!loading && <CircularProgress size={30} />}
        </div>
      }
      useWindow={false}
    >
      {props.children}
    </InfiniteScroll>
  );
}

export default InfiniteScrollComponent;
