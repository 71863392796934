// import React from "react";

// mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
// import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";

// const numbers = [1, 2, 3, 4, 5];

// function SkeletonLoader() {
//   return <Skeleton animation="wave" height={60} />;
// }

function MuiDataTableLoader() {
  return (
    <TableRow>
      <TableCell colSpan={8} align="right" sx={{ borderBottom: "none" }}>
        <CircularProgress thickness={2} />;
      </TableCell>
    </TableRow>
  );
  /* return (
    <>
      {numbers.map((n) => (
        <TableRow
          key={n}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 150,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 100,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 150,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 50,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 100,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 70,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              paddingTop: 0,
              paddingRight: 3,
              paddingBottom: 0,
              paddingLeft: 1,
              width: 125,
            }}
          >
            <SkeletonLoader />
          </TableCell>
        </TableRow>
      ))}
    </>
  ); */
}

export default MuiDataTableLoader;
