import { useEffect, useState } from "react";

// mui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import StaffActivitiesTable from "../../components/tables/StaffActivitiesTable";
import SelectSearchInput from "../../components/inputs/SelectSearchInput";
import DateRangePickerComponent from "../../components/inputs/DateRangePickerTwo";

// state
import { fetchUserListService } from "../../services/CustomerServices";
import { fetchStaffActivitesService } from "../../services/ReportServices";

const options = [
  { key: "token", email: "Refresh Web" },
  { key: "login", email: "Login" },
  { key: "logout", email: "Logout" },
  { key: "break-out", email: "Break Out" },
  { key: "break-in", email: "Break In" },
  { key: "load-customer", email: "Load Customer" },
  { key: "update-customer", email: "Update Customer" },
  { key: "hang-up", email: "Hang up" },
  { key: "calling", email: "Calling" },
];

function StaffActivities() {
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState("all");
  const [staff, setStaff] = useState("all");
  const [userList, setUserList] = useState([]);
  const [activities, setActivities] = useState([]);

  const fetchData = async () => {
    const res = await fetchStaffActivitesService(
      `activity=${activity}&staff=${staff}`
    );

    setActivities(res);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) setLoading(true);

    fetchData();
  }, [activity, staff]);

  const dateRangeFilter = async (start, end) => {
    setLoading(true);

    const res = await fetchStaffActivitesService(
      `activity=${activity}&staff=${staff}&start=${start}&end=${end}`
    );

    setActivities(res);

    setLoading(false);
  };

  const fetchUserList = async () => {
    const res = await fetchUserListService();

    setUserList(res);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff", pl: 1, pt: 5 }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SelectSearchInput
              label="User"
              value=""
              options={userList}
              onChange={(event, value) => {
                console.log("value", value);
                value.email === "All" ? setStaff("all") : setStaff(value._id);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <SelectSearchInput
              label="Activity"
              value=""
              options={options}
              onChange={(event, value) =>
                value.email === "All"
                  ? setActivity("all")
                  : setActivity(value.key)
              }
            />
          </Grid>

          <Grid item xs={2}>
            <DateRangePickerComponent dateRangeFilter={dateRangeFilter} />
          </Grid>
        </Grid>
      </Box>

      <StaffActivitiesTable loading={loading} data={activities} />
    </MainRootElement>
  );
}

export default StaffActivities;
