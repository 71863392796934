// mui
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// state
import { useSelector } from "react-redux";
import store from "../../../store";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

const FutureReminderFilter = () => {
  const { loading, urlParams } = useSelector((state) => state.customer);

  const handleFutureReminder = async (e) => {
    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = { ...urlParams, filterReminder: e.target.checked };

    const customZipCodes = newUrlParams.customZipCodes ?? null;

    const res = await fetchCustomersService(newUrlParams, customZipCodes);

    let payload = {
      ...res,
      urlParams: newUrlParams,
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      label="Future Reminder Only"
      onChange={handleFutureReminder}
    />
  );
};

export default FutureReminderFilter;
