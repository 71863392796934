// state
import { useSelector } from "react-redux";
import store from "../../../store";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

// components
import SelectSearchInput from "../../inputs/SelectSearchInput";

const leadClasses = [
  { key: "served", fname: "Served" },
  { key: "good", fname: "Good" },
  { key: "bad", fname: "Bad" },
];

const LeadClassFilter = () => {
  const { loading, urlParams } = useSelector((state) => state.customer);

  const handleSetLeadClass = async (e) => {
    const value = e.target.textContent.toLowerCase() || "";

    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = {
      ...urlParams,
      leadClass: value === "all" ? "" : value,
    };
    // delete newUrlParams.staffAssignedId;

    const customZipCodes = newUrlParams.customZipCodes ?? null;

    const res = await fetchCustomersService(newUrlParams, customZipCodes);

    let payload = {
      ...res,
      urlParams: newUrlParams,
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  return (
    <SelectSearchInput
      label="Filter by Lead Class"
      options={leadClasses}
      onChange={handleSetLeadClass}
    />
  );
};

export default LeadClassFilter;
