import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

function NumberInput({
  error,
  errorMsg,
  value,
  label,
  onBlur,
  getValue,
  variant,
  size,
  disabled,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    // Clear the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to wait for the user to finish typing
    const newTypingTimeout = setTimeout(() => {
      // Do something with the final input value
      getValue(value);
    }, 1000); // Adjust the delay as needed (e.g., 1000ms = 1 second)

    // Update the timeout state
    setTypingTimeout(newTypingTimeout);

    // Update the input value
    setInputValue(value);
  };
  return (
    <TextField
      id={`text-input-${label}`}
      variant={variant ?? "standard"}
      size={size ?? "small"}
      label={label}
      type="number"
      value={inputValue}
      onBlur={onBlur}
      onChange={handleInputChange}
      error={error}
      helperText={errorMsg}
      fullWidth
      disabled={disabled ?? false}
      InputLabelProps={{ shrink: true }}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
    />
  );
}

export default NumberInput;
