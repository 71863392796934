import { useSelector } from "react-redux";
import store from "../../../store";

// components
import SelectSearchInput from "../../inputs/SelectSearchInput";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

// others
import { LEAD_CATEGORY_LIST } from "../../../constants/lists";

const leadCategoryLists = LEAD_CATEGORY_LIST.filter((li) => li.key !== "all");

const ProcessResultFilter = () => {
  const { loading, urlParams } = useSelector((state) => state.customer);

  const handleFilterMenu = async (data) => {
    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = { ...urlParams };

    if (data.filterByProcessResult && data.filterByProcessResult.status) {
      newUrlParams.process = data.filterByProcessResult.value;
    }

    if (data.staffAssignedId) {
      newUrlParams.staffAssignedId = data.staffAssignedId;
    }

    if (data.staffAssignedId) {
      newUrlParams.leadClass = data.leadClass;
    }

    // console.log("filtering results", newUrlParams);
    // fetchCustomers(false, newUrlParams);
    const customZipCodes = urlParams.customZipCodes ?? null;
    const res = await fetchCustomersService(newUrlParams, customZipCodes);

    let payload = {
      ...res,
      urlParams: newUrlParams,
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  const handleFilterByProcessResult = (e) => {
    const found = leadCategoryLists.find(
      (list) => list.value === e.target.textContent
    );

    handleFilterMenu({
      filterByProcessResult: {
        status: true,
        value: found !== undefined ? found.key : "",
      },
    });
  };

  return (
    <SelectSearchInput
      label="Filter by Process Result"
      options={leadCategoryLists.map((li) => {
        return {
          key: li.key,
          fname: li.value,
        };
      })}
      onChange={handleFilterByProcessResult}
    />
  );
};

export default ProcessResultFilter;
