// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

const ALLOWED_PATHNAMES = [
  "/lead-generator",
  "/info-list",
  "/lead",
  "/closer",
  "/central",
];

export default function EditCustomerButton({ customer, openModal }) {
  const pathname = useGetCurrentPathname();

  if (!ALLOWED_PATHNAMES.includes(pathname)) {
    return null;
  }

  return (
    <Tooltip title="Edit Customer">
      <IconButton
        color="actionButton"
        aria-label="Edit Customer"
        onClick={() => openModal("customer-form", customer, "md")}
      >
        <EditOutlinedIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  );
}
