import { useState, useEffect } from "react";

function CustomTextInput({ getValue, placeholder, value }) {
  const [inputValue, setInputValue] = useState(value);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    // Clear the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to wait for the user to finish typing
    const newTypingTimeout = setTimeout(() => {
      // Do something with the final input value
      getValue(value);
    }, 1000); // Adjust the delay as needed (e.g., 1000ms = 1 second)

    // Update the timeout state
    setTypingTimeout(newTypingTimeout);

    // Update the input value
    setInputValue(value);
  };
  return (
    <input
      className="custom-text-input"
      type="text"
      value={inputValue}
      placeholder={placeholder ?? ""}
      onChange={handleInputChange}
    />
  );
}

export default CustomTextInput;
