import { useState, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const LGFilter = ({ textFilter }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);

  const [selected, setSelected] = useState("");
  const { staffs, loading } = useSelector((state) => state.customer);

  const handleSelect = (e) => {
    //   filterStaff
    console.log("e", e.target.value);
    setSelected(e.target.value);
    textFilter("filterStaff", e.target.value);

    // Set URL params
    let options = {
      ...paramsObject,
      filterStaff: encodeURIComponent(e.target.value),
    };
    setSearchParams(options);
  };

  useEffect(() => {
    const filterStaffParam = searchParams.get("filterStaff");
    const filterStaff = staffs.find(
      (staff) => staff.email === decodeURIComponent(filterStaffParam)
    );

    if (filterStaff) setSelected(filterStaff.email);
  }, [searchParams, staffs]);

  if (loading) {
    return <div style={{ width: 150, height: 27 }}></div>;
  }

  return (
    <select className="select" onChange={handleSelect}>
      <option>All</option>
      {staffs &&
        staffs.length > 0 &&
        staffs.map((user) => (
          <option
            key={user._id}
            value={user.email}
            selected={selected === user.email ? true : false}
          >
            {user.fname} {user.lname}
          </option>
        ))}
    </select>
  );
};

export default memo(LGFilter);
