import React from "react";
import Swal from "sweetalert2";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import EditIcon from "@mui/icons-material/Edit";

import TotalComponent from "../TotalComponent";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { updateUser } from "../../store/actions/CustomerActions";
import { updateUserService } from "../../services/CustomerServices";

const accountTypeLabel = {
  staff: "Lead Generator",
  "central-user": "Central User",
  closer: "Closer",
  manager: "Manager",
  admin: "Admin",
};

export const ManageUsersTable = ({ openModal }) => {
  const { loading, userList } = useSelector((state) => state.customer);

  const { user } = useSelector((state) => state.auth);

  const handleActivation = async (selectedUser, type) => {
    if (user._id === selectedUser._id) {
      Swal.fire("Oops", "You are not permitted to edit yourself!", "error");

      return;
    }

    const res = await Swal.fire({
      title: "Are you sure?",
      html: `Do you want to ${type.toLowerCase()} the account <strong>${
        selectedUser.fname
      } ${selectedUser.lname}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: type === "Activate" ? "#006400" : "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: `Yes, ${type} it!`,
    });

    if (res.isConfirmed) {
      await updateUserService({
        _id: selectedUser._id,
        deleted: type === "Activate" ? false : true,
      });

      store.dispatch(
        updateUser(
          {
            _id: selectedUser._id,
            deleted: type === "Activate" ? false : true,
          },
          false
        )
      );
    }
  };

  return (
    <TableContainer component={Paper} sx={{ height: "520px" }}>
      <h2 style={{ textAlign: "center" }}>Manage Users</h2>

      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Deleted</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            [1, 2, 3, 4, 5].map((nr) => {
              return (
                <TableRow key={nr}>
                  {[1, 2, 3, 4, 5, 6].map((header, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={35}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

          {!loading &&
            userList.map((user, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  verticalAlign: "baseline",
                }}
              >
                <TableCell>{user.fname}</TableCell>
                <TableCell>{user.lname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{accountTypeLabel[user.accountType]}</TableCell>

                <TableCell>{user.deleted ? "Deleted" : ""}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      color="actionButton"
                      aria-label="Edit"
                      onClick={() => openModal("manage-user-form", user, "xs")}
                    >
                      <EditIcon style={{ fontSize: 17 }} />
                    </IconButton>
                  </Tooltip>

                  {!user.deleted ? (
                    <Tooltip title="Deactivate the account">
                      <IconButton
                        color="success"
                        aria-label="Edit"
                        onClick={() => handleActivation(user, "Deactivate")}
                      >
                        <ToggleOnIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Activate the account">
                      <IconButton
                        color="error"
                        aria-label="Delete"
                        onClick={() => handleActivation(user, "Activate")}
                      >
                        <ToggleOffIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TotalComponent loading={loading} totalRecords={userList.length} />
    </TableContainer>
  );
};

export default React.memo(ManageUsersTable);
