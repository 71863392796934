import {
  UPDATE_CUSTOMERS,
  SET_CUSTOMERS,
  SET_LOADING,
  FILTERED_CUSTOMERS,
  SET_ZIPCODES,
  SHOW_MESSAGE,
  SET_USER_LIST,
  SET_FORM_SUBMITTING,
  LOAD_MORE_CUSTOMERS,
  RESET_LOAD_MORE_OPTIONS,
  RESET_URL_PARAMS,
  SET_USER_LIST_ONLY,
  SET_HEALTH_PLANS,
  SAVE_CUSTOMERS,
  SAVE_USER_LIST,
  ASSIGN_CATEGORY,
  SAVE_FILES,
  SAVE_VENDOR_LIST,
  SAVE_CUSTOMERS_AND_USER_LIST,
  CLEAR_CUSTOMERS,
  REMOVE_CUSTOMER_FROM_STATE,
} from "../constants/ActionTypes.js";

// models
import MuiDataTable from "../../models/MuiDataTable.js";

// services
import {
  fetchCustomersService,
  fetchUserListService,
  fetchZipcodesService,
  fetchVendorsService,
  reverseTheOperationService,
  updateCustomerService,
  deleteUserService,
  fetchZipcodeListcodeService,
  fetchHealthPlansService,
  deleteHealthPlansService,
  customerApproveServices,
} from "../../services/CustomerServices.js";
import { remove } from "lodash";

export const setLoading = (value) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const setFormSubmitting = (value) => (dispatch) => {
  dispatch({ type: SET_FORM_SUBMITTING, payload: value });
};

export const fetchCustomers =
  (filterParams, zipCodes, skipAmount) => async (dispatch, getState) => {
    // console.error("CA Start=> fetchCustomers");
    try {
      if (getState().customer.loading === false) {
        dispatch({ type: SET_LOADING, payload: true });
      }

      const res = await fetchCustomersService(filterParams, zipCodes);

      const payload = {
        totalRecords: res.total,
        customers: res.customers,
        loadMoreOptions: {
          hasMore: res.customers.length < 90 ? false : true,
          skip: skipAmount ?? 100,
        },
        urlParams: filterParams,
      };
      // console.log("fetchCustomers payload", payload);

      setTimeout(() => {
        dispatch({
          type: SET_CUSTOMERS,
          urlParams: filterParams,
          payload,
        });

        // console.error("CA End=> fetchCustomers");
      }, 1000);
    } catch (error) {
      // console.log(error);
    }
  };

export const loadMoreCustomers =
  (filterParams, skipAmount) => async (dispatch, getState) => {
    // console.warn("Loading more customers");

    try {
      let customers = getState().customer.customers;
      const newCustomers = await fetchCustomersService(filterParams, null);

      const payload = {
        customers: [...customers, ...newCustomers.customers],
        loadMoreOptions: {
          hasMore: newCustomers.customers.length < 90 ? false : true,
          skip: skipAmount ?? 100,
        },
        urlParams: filterParams,
      };

      // console.log("loadMoreCustomers payload", payload);

      setTimeout(() => {
        dispatch({
          type: LOAD_MORE_CUSTOMERS,
          payload,
        });
      }, 1000);
    } catch (error) {
      // console.log(error);
    }
  };

export const updateCustomer =
  (formData, updateInTheServer) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_FORM_SUBMITTING, payload: true });

      const res = await updateCustomerService(formData);

      let customers = getState().customer.customers;

      if (!formData._id) {
        customers.unshift(res);
      } else {
        if (getState().customer.loading === false) {
          dispatch({ type: SET_LOADING, payload: true });
        }

        const res = await fetchCustomersService(
          getState().customer.urlParams,
          false
        );

        const payload = {
          totalRecords: res.total,
          customers: res.customers,
          loadMoreOptions: {
            hasMore: res.customers.length < 90 ? false : true,
            skip: getState().customer.loadMoreOptions.skip ?? 100,
          },
          urlParams: getState().customer.urlParams,
        };
        // console.log("fetchCustomers payload", payload);

        setTimeout(() => {
          dispatch({ type: SET_FORM_SUBMITTING, payload: false });
          dispatch({
            type: SET_CUSTOMERS,
            urlParams: getState().customer.urlParams,
            payload,
          });

          // console.error("CA End=> fetchCustomers");
        }, 1000);
        return;
      }

      setTimeout(() => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: { type: "success", msg: "Information updated successfully" },
        });

        dispatch({
          type: UPDATE_CUSTOMERS,
          payload: { customers, modifiedCustomers: customers },
        });

        dispatch({ type: SET_FORM_SUBMITTING, payload: false });
      }, 1000);

      return true;
    } catch (error) {
      // console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          type: "error",
          msg: "Error when trying to update the information",
        },
      });

      return false;
    }
  };

export const setCustomers = (payload) => (dispatch) => {
  dispatch({ type: SET_CUSTOMERS, payload });
};

export const assignCategory = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_FORM_SUBMITTING, payload: true });

    await updateCustomerService(formData);

    let customers = getState().customer.customers.filter(
      (customer) => customer._id !== formData._id
    );

    setTimeout(() => {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          type: "success",
          msg: "Customer information updated successfully",
        },
      });

      dispatch({ type: ASSIGN_CATEGORY, payload: customers });

      dispatch({ type: SET_FORM_SUBMITTING, payload: false });
    }, 1000);

    return true;
  } catch (error) {
    // console.log(error);
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        type: "error",
        msg: "Error when trying to update the information",
      },
    });

    dispatch({ type: SET_FORM_SUBMITTING, payload: false });

    return false;
  }
};

export const filterCustomersByText =
  (customers, filterBy, value) => (dispatch) => {
    const _muiDataTable = new MuiDataTable(customers);
    const filteredCustomers = _muiDataTable.textFilter(
      filterBy,
      value.toLowerCase()
    );

    setTimeout(() => {
      dispatch({ type: FILTERED_CUSTOMERS, payload: filteredCustomers });
    }, 1000);
  };

export const filterCustomersByDateRange =
  (customers, filterBy, start, end) => (dispatch) => {
    const _muiDataTable = new MuiDataTable(customers);
    const filteredCustomers = _muiDataTable.dateRangeFilter(
      filterBy,
      start,
      end
    );

    setTimeout(() => {
      dispatch({ type: FILTERED_CUSTOMERS, payload: filteredCustomers });
    }, 1000);
  };

export const fetchUserList = (filterParams) => async (dispatch, getState) => {
  // console.log("Fetching user list");
  // console.log("-------------------");

  // console.log("filterParams", filterParams);

  // console.log("WTF is happening???????????????????");

  try {
    if (getState().customer.loading === false) {
      dispatch({ type: SET_LOADING, payload: true });
    }

    const users = await fetchUserListService();
    const vendors = await fetchVendorsService();

    // fetch the customer data here to avoid multiple re-renders
    const res = await fetchCustomersService(filterParams, false);

    setTimeout(() => {
      dispatch({
        type: SET_USER_LIST,
        payload: {
          customers: res.customers,
          totalRecords: res.total,
          users,
          vendors,
          urlParams: filterParams,
          loadMoreOptions: {
            hasMore: res.customers.length < 90 ? false : true,
            skip: 100,
          },
        },
      });
    }, 1000);
  } catch (error) {
    // console.log(error);
  }
};

export const getZipCodes = () => async (dispatch) => {
  try {
    const res = await fetchZipcodesService();

    // remove duplicates
    let payload = res.filter((item, i, array) => array.indexOf(item) === i);

    setTimeout(() => {
      dispatch({ type: SET_ZIPCODES, payload });
    }, 1000);
  } catch (error) {
    // console.log("error", error);
  }
};

export const reverseTheOperation =
  (endpoint, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });

      await reverseTheOperationService(endpoint, { _id: id });

      let _customers = getState().customer.customers;
      let customers = _customers.filter((e) => e._id !== id);

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          type: "success",
          msg: "Reverse operation successfully completed",
        },
      });

      setTimeout(() => {
        dispatch({
          type: UPDATE_CUSTOMERS,
          payload: { customers, modifiedCustomers: customers },
        });
      }, 1000);
    } catch (error) {
      // console.log("error", error);

      dispatch({
        type: SHOW_MESSAGE,
        payload: { type: "error", msg: "Something went wrong" },
      });
    }
  };

export const updateApproval =
  (cutomerId, checked, type) => (dispatch, getState) => {
    let modifiedCustomers = getState().customer.modifiedCustomers;
    let customers = getState().customer.customers;
    const customersLength = customers.length;

    const newDate = new Date();

    for (let i = 0; i < customersLength; i++) {
      const customer = customers[i];

      if (customer._id === cutomerId) {
        if (checked) {
          customers[i].patientAssignmentApproval = type;
          customers[i].patientAssignmentApprovalDate = newDate;

          remove(modifiedCustomers, { _id: cutomerId });

          modifiedCustomers.push({
            _id: customers[i]._id,
            patientAssignmentApproval: type,
          });
        }

        if (!checked) {
          customers[i].patientAssignmentApproval = "";
          customers[i].patientAssignmentApprovalDate = newDate;

          remove(modifiedCustomers, { _id: cutomerId });

          modifiedCustomers.push({
            _id: customers[i]._id,
            patientAssignmentApproval: type,
          });
        }

        break;
      }
    }

    dispatch({
      type: UPDATE_CUSTOMERS,
      payload: { customers, modifiedCustomers },
    });
  };

export const resetLoadMoreOptions = () => (dispatch) => {
  dispatch({ type: RESET_LOAD_MORE_OPTIONS });
};

export const resetUrlParams = () => (dispatch) => {
  dispatch({ type: RESET_URL_PARAMS });
};

export const fetchUserListOnly = (params) => async (dispatch, getState) => {
  try {
    if (getState().customer.loading === false) {
      dispatch({ type: SET_LOADING, payload: true });
    }

    const userList = await fetchUserListService(params);
    const listCodes = await fetchZipcodeListcodeService();

    dispatch({ type: SET_USER_LIST_ONLY, payload: { userList, listCodes } });
  } catch (error) {
    // console.log("error", error);
  }
};

export const updateUser = (formData, res) => async (dispatch, getState) => {
  try {
    let userList = getState().customer.userList;
    const userListLength = userList.length;

    for (let i = 0; i < userListLength; i++) {
      const user = userList[i];

      if (user._id === formData._id) {
        // userList[i].fname = formData.fname;
        // userList[i].lname = formData.lname;
        // userList[i].deleted = formData.deleted;

        userList[i] = res;
        break;
      }
    }

    dispatch({
      type: SET_USER_LIST_ONLY,
      payload: { userList },
    });
    dispatch({ type: SET_FORM_SUBMITTING, payload: false });
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });

    await deleteUserService(userId);

    let userList = getState().customer.userList.filter(
      (user) => user._id !== userId
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: { type: "success", msg: "User deleted successfully" },
    });

    dispatch({ type: SET_USER_LIST_ONLY, payload: userList });
  } catch (error) {
    // console.log("error", error);
  }
};

export const fetchHealthPlans = () => async (dispatch, getState) => {
  try {
    if (getState().customer.loading === false) {
      dispatch({ type: SET_LOADING, payload: true });
    }

    const healthPlans = await fetchHealthPlansService();

    dispatch({ type: SET_HEALTH_PLANS, payload: healthPlans });
  } catch (error) {
    // console.log("error", error);
  }
};

export const updateHealthPlans = (res) => async (dispatch, getState) => {
  try {
    let healthPlans = getState().customer.healthPlans;

    if (res) healthPlans.unshift(res);

    dispatch({ type: SET_HEALTH_PLANS, payload: healthPlans });
    dispatch({ type: SET_FORM_SUBMITTING, payload: false });
  } catch (error) {
    // console.log("error", error);
  }
};

export const deleteHealthPlans = (planId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });

    await deleteHealthPlansService(planId);

    dispatch({
      type: SHOW_MESSAGE,
      payload: { type: "success", msg: "Plan deleted successfully" },
    });

    let healthPlans = getState().customer.healthPlans.filter(
      (plan) => plan._id !== planId
    );

    dispatch({ type: SET_HEALTH_PLANS, payload: healthPlans });
  } catch (error) {
    // console.log("error", error);
  }
};

export const saveCustomers = (customers) => async (dispatch) => {
  dispatch({ type: SAVE_CUSTOMERS, payload: customers });
};

export const clearCustomers = (callBack) => async (dispatch) => {
  dispatch({ type: CLEAR_CUSTOMERS, payload: [] });

  setTimeout(() => {
    callBack();
  }, 1000);
};

export const saveCustomersAndUserList = (customers) => async (dispatch) => {
  dispatch({ type: SAVE_CUSTOMERS_AND_USER_LIST, payload: customers });
};

export const saveZipCodes = (zipcodes) => async (dispatch) => {
  dispatch({ type: SET_ZIPCODES, payload: zipcodes });
};

export const saveUserList = (userList) => async (dispatch) => {
  dispatch({ type: SAVE_USER_LIST, payload: userList });
};

export const saveFiles = (files) => async (dispatch) => {
  dispatch({ type: SAVE_FILES, payload: files });
};

export const saveVendorList = (vendorList) => async (dispatch) => {
  dispatch({ type: SAVE_VENDOR_LIST, payload: vendorList });
};

export const vendorCustomerApprove = (id) => async (dispatch, getState) => {
  if (getState().customer.loading === false) {
    dispatch({ type: SET_LOADING, payload: true });
  }

  await customerApproveServices(id);

  let _customers = getState().customer.customers;
  let customers = _customers.filter((e) => e._id !== id);

  dispatch({
    type: UPDATE_CUSTOMERS,
    payload: { customers, modifiedCustomers: customers },
  });
};

export const removeCustomerFromState = (id) => async (dispatch, getState) => {
  let _customers = getState().customer.customers;
  let customers = _customers.filter((e) => e._id !== id);
  const totalRecords = getState().customer.totalRecords - 1;

  dispatch({
    type: REMOVE_CUSTOMER_FROM_STATE,
    payload: { customers, totalRecords },
  });
};
