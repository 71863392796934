import {
  SET_LOADING,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  SET_MODAL,
  SET_DIALOG,
  SET_FORM_SUBMITTING,
  SET_TWILIO,
  SET_TWILIO_CALL_STATUS,
  SET_BANDWIDTH,
} from "../constants/ActionTypes.js";

export const setLoading = (value) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const setFormSubmitting = (value) => (dispatch) => {
  dispatch({ type: SET_FORM_SUBMITTING, payload: value });
};

export const setModal = (open, openFor, data, size) => (dispatch, getState) => {
  let payload = {
    open,
    for: open ? openFor : "",
    data: open ? data : {},
    size: size ?? "sm",
  };

  if (!open) {
    payload = {
      ...getState().app.modal,
      open,
    };
  }

  dispatch({ type: SET_MODAL, payload });
};

export const setDialog = (open, openFor, data) => (dispatch) => {
  const payload = {
    open,
    for: open ? openFor : "",
    data: open ? data : {},
  };

  dispatch({ type: SET_DIALOG, payload });
};

export const showMessage = (messageType, text) => (dispatch) => {
  dispatch({ type: SHOW_MESSAGE, payload: { type: messageType, msg: text } });
};

export const hideMessage = () => (dispatch) => {
  dispatch({ type: HIDE_MESSAGE });
};

export const saveTwilio = (data) => (dispatch, getState) => {
  if (getState().app.twilio.device) delete data.device;
  dispatch({ type: SET_TWILIO, payload: data });
};

export const updateTwilioCallStatus = (data) => async (dispatch, getState) => {
  const twilio = getState().app.twilio;

  if (data.callStatus === "Calling" && data.to) {
    const params = {
      params: {
        To: data.to,
        phoneNumber: data.to,
        From: "+13236452105",
        callFrom: "+13236452105",
      },
    };
    console.log(`calling. . .`);
    await twilio.browser.connect(params);
  } else {
    console.log(`disconnecting. . .`);
    await twilio.browser.disconnectAll();
  }

  dispatch({ type: SET_TWILIO_CALL_STATUS, payload: data });
};

export const setBandwidth = (data) => async (dispatch, getState) => {
  dispatch({ type: SET_BANDWIDTH, payload: data });
};

export const endBandwidthCall = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_BANDWIDTH,
    payload: { callStatus: "End", customer: "", to: "" },
  });
};
