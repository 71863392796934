// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
// import SelectSearchInput from "../inputs/SelectSearchInput";
import { useSelector } from "react-redux";
import FormCloseButton from "../buttons/FormCloseButton";

function AssignToVendorForm({ closeForm, data, loading, handleForm }) {
  console.log("Rendering AssignToVendorForm");
  const { vendors } = useSelector((state) => state.customer);

  console.log("vendors", vendors);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assignedToVendorId: "",
    },
    validationSchema: Yup.object({
      assignedToVendorId: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      let newData = {
        _id: data._id,
        assignedToVendorId: values.assignedToVendorId || "",
      };

      handleForm("assign-to-vendor", newData);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Assign to Vendor</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={12}>
          {data.assignedToVendorId ? (
            <div>
              <p>Vendor Already Assigned: {data.assignedToVendor.email}</p>
            </div>
          ) : (
            <div>
              <p>No Vendor Assigned: Please select a vendor</p>
            </div>
          )}
        </Grid>
        {/*   <Grid item xs={6}>
          <SelectSearchInput
            label="Select or change vendor"
            value={formik.values.assignedToVendorId}
            options={vendors}
            onChange={(event, value) => {
              console.log("value", value);
              formik.setFieldValue("assignedToVendorId", value._id);
            }}
          />
          {formik.errors.assignedToVendorId && (
            <p style={{ color: "#f00", margin: 0, paddingLeft: 3 }}>
              <small>{formik.errors.assignedToVendorId}</small>
            </p>
          )}
        </Grid> */}

        <Grid item xs={6}>
          <select
            className="select"
            onChange={(e) => {
              // alert(e.target.value);
              formik.setFieldValue("assignedToVendorId", e.target.value);
            }}
          >
            <option value=""></option>
            {vendors &&
              vendors.map((vendor) => (
                <option
                  key={vendor._id}
                  value={vendor._id}
                  selected={
                    vendor._id === formik.values.assignedToVendorId
                      ? true
                      : false
                  }
                >
                  {vendor.name}
                </option>
              ))}
          </select>
          {formik.errors.assignedToVendorId && (
            <p style={{ color: "#f00", margin: 0, paddingLeft: 3 }}>
              <small>{formik.errors.assignedToVendorId}</small>
            </p>
          )}
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default AssignToVendorForm;
