// general
export const SET_LOADING = "SET_LOADING";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const SET_MODAL = "SET_MODAL";
export const SET_DIALOG = "SET_DIALOG";
export const SET_FORM_SUBMITTING = "SET_FORM_SUBMITTING";
export const SAVE_FILES = "SAVE_FILES";

// auth
export const SET_AUTH = "SET_AUTH";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const REMOVE_AUTH = "REMOVE_AUTH";

// user
export const SET_USER = "SET_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";

// customer
export const SAVE_VENDOR_LIST = "SAVE_VENDOR_LIST";
export const SAVE_USER_LIST = "SAVE_USER_LIST";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_USER_LIST_ONLY = "SET_USER_LIST_ONLY";
export const SET_ZIPCODES = "SET_ZIPCODES";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const LOAD_MORE_CUSTOMERS = "LOAD_MORE_CUSTOMERS";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const ASSIGN_CATEGORY = "ASSIGN_CATEGORY";
export const FILTERED_CUSTOMERS = "FILTERED_CUSTOMERS";
export const RESET_LOAD_MORE_OPTIONS = "RESET_LOAD_MORE_OPTIONS";
export const RESET_URL_PARAMS = "RESET_URL_PARAMS";
export const SET_HEALTH_PLANS = "SET_HEALTH_PLANS";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const REMOVE_CUSTOMER_FROM_STATE = "REMOVE_CUSTOMER_FROM_STATE";

export const SAVE_CUSTOMERS = "SAVE_CUSTOMERS";
export const SAVE_CUSTOMERS_AND_USER_LIST = "SAVE_CUSTOMERS_AND_USER_LIST";

// vendor
export const SET_VENDOR = "SET_VENDOR";

// report
export const SET_STAFF_REPORT = "SET_STAFF_REPORT";
export const SET_CU_REPORT = "SET_CU_REPORT";

// Twilio
export const SET_TWILIO = "SET_TWILIO";
export const SET_TWILIO_CALL_STATUS = "SET_TWILIO_CALL_STATUS";

// Bandwidth
export const SET_BANDWIDTH = "SET_BANDWIDTH";
