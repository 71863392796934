/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { useLocation } from "react-router";

// mui
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

// Model
import MuiDataTableLoader from "../loaders/MuiDataTableLoader";

// components
import FilterMenu from "../layouts/FilterMenu";
import TableHeadComponent from "./components/TableHead";
import TableContent from "./components/TableContent";
import InfiniteScrollComponent from "../InfiniteScrollComponent";
import SmallFormModalComponent from "../modals/SmallFormModalComponent";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { setLoading, saveCustomers } from "../../store/actions/CustomerActions";

// forms
import MoveToWait from "../dialogs/MoveToWait";
import CustomerModel from "../../models/CustomerModel";

// components
import TotalComponent from "../TotalComponent";
import Bandwidth from "../layouts/components/Bandwidth";

// others
import Swal from "sweetalert2";
import { updateCustomerService } from "../../services/CustomerServices";

const containerStyles = {
  backgroundColor: "#fff",
  width: "100%",
  // padding: "0px 10px",
};

const tableContainerStyles = {
  width: "99%",
  overflow: "auto",
  position: "absolute",
  bottom: 31,
};

function MuiDataTable() {
  const location = useLocation();

  const [modalOptions, setModalOptions] = useState({
    open: false,
    for: "customer-form",
    data: {},
    size: "md",
  });

  const closeModal = useCallback(() => {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);

    if (searchParams.get("id") !== null) {
      searchParams.delete("id");
    }

    setModalOptions({ open: false, for: "", data: {} });
  }, []);

  const handleOpen = useCallback((formType, data, size) => {
    setModalOptions({ open: true, for: formType, data, size });
  }, []);

  const { defaultCustomers, loading, loadMoreOptions, urlParams, customers } =
    useSelector((state) => state.customer);

  const textFilter = useCallback(
    async (filterBy, value) => {
      if (value && value.length <= 2) return;

      const newUrlParams = {
        ...urlParams,
        [filterBy]: value,
      };

      if (!loading) store.dispatch(setLoading(true));

      const res = await CustomerModel.fetchCustomers(newUrlParams);
      store.dispatch(saveCustomers(res));
    },
    [customers, urlParams]
  );

  function isAtcTab() {
    if (location && location.search) {
      if (location.search.includes("tab=atc")) return true;
    }

    return false;
  }

  const dateRangeFilter = useCallback(
    async (start, end) => {
      store.dispatch(setLoading(true));

      setTimeout(async () => {
        let newUrlParams = {
          ...urlParams,
          filterCreatedStart: start,
          filterCreatedEnd: end,
          filterChangedProcessAtStart: start,
          filterChangedProcessAtEnd: end,
        };

        if (isAtcTab()) {
          delete newUrlParams.filterCreatedStart;
          delete newUrlParams.filterCreatedEnd;
          delete newUrlParams.filterChangedProcessAtStart;
          delete newUrlParams.filterChangedProcessAtEnd;

          newUrlParams = {
            ...newUrlParams,
            filterCloserAssignedStart: start,
            filterCloserAssignedEnd: end,
          };
        }

        const res = await CustomerModel.fetchCustomers(newUrlParams);
        store.dispatch(saveCustomers(res));
      }, 1000);
    },
    [defaultCustomers, urlParams]
  );

  const openForm = useCallback((data, size) => {}, []);

  const handleMoveToWait = async (action, value) => {
    try {
      if (action === "save") {
        await updateCustomerService({
          _id: modalOptions.data._id,
          reminderDate: value,
        });

        const filteredCustomers = customers.filter(
          (customer) => customer._id !== modalOptions.data._id
        );

        store.dispatch(
          saveCustomers({
            customers: filteredCustomers,
            total: filteredCustomers.length,
            loadMoreOptions: { ...loadMoreOptions },
            urlParams,
          })
        );

        Swal.fire("Moved", "", "success");
      }

      closeModal();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box style={containerStyles}>
      <FilterMenu openModal={handleOpen} />
      <Bandwidth />

      {modalOptions.for !== "move-to-wait" && modalOptions.open ? (
        <SmallFormModalComponent
          modalOptions={modalOptions}
          closeModal={closeModal}
        />
      ) : null}

      <div className="custom-mui-table-container" style={tableContainerStyles}>
        <InfiniteScrollComponent>
          <Table stickyHeader>
            <TableHeadComponent
              dateRangeFilter={dateRangeFilter}
              textFilter={textFilter}
            />
            {loading ? (
              <TableBody>
                <MuiDataTableLoader />
              </TableBody>
            ) : (
              <TableContent openForm={openForm} openModal={handleOpen} />
            )}
          </Table>
        </InfiniteScrollComponent>
      </div>

      {modalOptions.for === "move-to-wait" ? (
        <MoveToWait
          open={modalOptions.open}
          handleClose={handleMoveToWait}
          data={modalOptions.data}
        />
      ) : null}

      <TotalComponent />
    </Box>
  );
}

export default React.memo(MuiDataTable);
