import {
  SET_LOADING,
  SET_STAFF_REPORT,
  SET_CU_REPORT,
} from "../constants/ActionTypes.js";

const initialState = {
  loading: true,
  staffReport: [],
  cuReport: {
    totalClinic: 0,
    totalHavingReminder: 0,
    totalHomeHealth: 0,
    totalHospice: 0,
    totalSignUp: 0,
    totalWithCloser: 0,
    totalWithoutAssignment: 0,
    totalWithoutCloser: 0,
  },
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_STAFF_REPORT:
      return {
        ...state,
        loading: false,
        staffReport: action.payload,
      };

    case SET_CU_REPORT:
      return {
        ...state,
        loading: false,
        cuReport: action.payload,
      };

    default:
      return state;
  }
};

export default ReportReducer;
