import { useEffect, useState } from "react";

// mui
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// services
import { fetchStaffReportService } from "../services/CustomerServices";

// components
import MainRootElement from "../components/layouts/MainRootElement";
import StaffReportChart from "../components/charts/StaffReportChart";
import DateRangePickerComponent from "../components/inputs/DateRangePickerTwo";

function Home() {
  const [state, setState] = useState({
    loading: true,
    data: [],
  });

  const fetchStaffReportData = async (params) => {
    if (!state.loading) {
      setState({ loading: true, data: [] });
    }

    const res = await fetchStaffReportService(params);
    setState({ loading: false, data: res });
  };

  useEffect(() => {
    fetchStaffReportData();
  }, []);

  return (
    <MainRootElement>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: 10,
          marginBottom: 5,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DateRangePickerComponent
          dateRangeFilter={(start, end) => fetchStaffReportData({ start, end })}
        />
      </div>

      {state.loading ? (
        <Box sx={{ width: "100%", textAlign: "center", mt: 1 }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <StaffReportChart chartData={state} />
      )}
    </MainRootElement>
  );
}

export default Home;
