import Swal from "sweetalert2";

// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";

// state
import { useSelector } from "react-redux";
import store from "../../../store";
import { vendorCustomerApprove } from "../../../store/actions/CustomerActions";

const iconStyle = {
  fontSize: 18,
};

export default function VerifyVendorButton(props) {
  const { customer } = props;
  const { user } = useSelector((state) => state.auth);

  const handleVendorCustomerApprove = async (id) => {
    const result = await Swal.fire({
      title: "Would you like to verify this assignment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;

    store.dispatch(vendorCustomerApprove(id));
  };

  return (
    <>
      {user.accountType === "vendor" && !customer.isVendorApproved && (
        <Tooltip title="Verify">
          <IconButton
            color="actionButton"
            aria-label="Verify"
            onClick={() => handleVendorCustomerApprove(customer._id)}
          >
            <CheckIcon style={iconStyle} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
