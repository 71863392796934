import React from "react";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

import { STAFF_REPORT } from "../../constants/lists";

// state
import { useSelector } from "react-redux";
import TotalComponent from "../TotalComponent";

export const StaffReportTable = () => {
  console.log("Rendering => StaffReportTable");
  const { loading, staffReport } = useSelector((state) => state.report);

  return (
    <TableContainer component={Paper} sx={{ height: "520px" }}>
      <h2 style={{ textAlign: "center" }}>Staff Report</h2>

      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {STAFF_REPORT.map((header) => (
              <TableCell key={header.key}>{header.value}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            [1, 2, 3, 4, 5].map((nr) => {
              return (
                <TableRow key={nr}>
                  {STAFF_REPORT.map((header, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={35}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

          {!loading &&
            staffReport.map((sr, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {STAFF_REPORT.map((header, index) => (
                  <TableCell key={index}>
                    {header.key === "fname" && `${sr[header.key]} ${sr.lname}`}

                    {header.key !== "fname" && `${sr[header.key]}`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TotalComponent loading={loading} totalRecords={staffReport.length} />
    </TableContainer>
  );
};

export default React.memo(StaffReportTable);
