import React from "react";
import { useSearchParams } from "react-router-dom";

// mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// state
import { useSelector } from "react-redux";

// components
import { useGetCurrentPathname } from "../../hooks/useGetCurrentPathname";
import ZipcodeFilter from "./components/ZipcodeFilter";
import StaffFilter from "./components/StaffFilter";
import CloserFilter from "./components/CloserFilter";
import LeadGeneratorFilter from "./components/LeadGeneratorFilter";
import VendorFilter from "./components/VendorFilter";
import FutureReminderFilter from "./components/FutureReminderFilter";
import ProcessResultFilter from "./components/ProcessResultFilter";
import InfoYearFilter from "./components/InfoYearFilter";
import LeadClassFilter from "./components/LeadClassFilter";
import Call from "./components/Call";

const containerStyles = { pt: 0, mt: 0, pl: 1, pr: 1 };

function FilterMenu({ openModal }) {
  console.log("Rendering => FilterMenu");

  const pathname = useGetCurrentPathname();

  let [searchParams] = useSearchParams();

  const { user } = useSelector((state) => state.auth);

  // const openModal = () => {};

  return (
    <Grid container spacing={1} sx={containerStyles}>
      {pathname === "/info-list" ? (
        <Grid item xs={3}>
          <ProcessResultFilter />
        </Grid>
      ) : null}

      {pathname === "/info-list" ||
      pathname === "/lead-generator" ||
      pathname === "/central" ||
      pathname === "/closer" ||
      pathname === "/lead" ? (
        <Grid item xs={4}>
          <ZipcodeFilter />
        </Grid>
      ) : null}

      {pathname === "/info-list" ||
      pathname === "/lead-generator" ||
      pathname === "/central" ||
      pathname === "/closer" ||
      pathname === "/lead" ? (
        <Grid item xs={4}>
          <InfoYearFilter />
        </Grid>
      ) : null}

      {pathname === "/closer" || pathname === "/lead" ? (
        <Grid item xs={4}>
          <div className="d-flex">
            <Button
              style={{
                width: "50%",
                marginRight: 10,
              }}
              size="small"
              variant="contained"
              onClick={() => openModal("customer-form", {}, "md")}
            >
              Add Customer
            </Button>

            {pathname === "/closer" ? <FutureReminderFilter /> : null}
          </div>
        </Grid>
      ) : null}

      {(pathname === "/closer" || pathname === "/lead") &&
      user.accountType !== "closer" ? (
        <Grid item xs={4}>
          <LeadClassFilter />
        </Grid>
      ) : null}

      <Grid item xs={4}>
        {searchParams.get("page") === "lead" && <StaffFilter />}

        {(searchParams.get("tab") === "atc" ||
          searchParams.get("page") === "closer") &&
        user.accountType !== "closer" ? (
          <CloserFilter />
        ) : null}
      </Grid>

      <Grid item xs={12} />

      {pathname !== "/info-list" &&
      pathname !== "/lead-generator" &&
      !searchParams.get("page") &&
      searchParams.get("category") !== "vendor-assigned" ? (
        <Grid item xs={3}>
          <LeadGeneratorFilter />
        </Grid>
      ) : null}

      {pathname === "/central" && searchParams.get("tab") === "va" ? (
        <Grid item xs={3}>
          <VendorFilter />
        </Grid>
      ) : null}

      <Grid item xs={12} />

      {pathname !== "/central" ? (
        <Grid item xs={4}>
          <Call />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default React.memo(FilterMenu);
