import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import LeadGenerator from "../pages/LeadGenerator";
import ImportedData from "../pages/ImportedData";
import Central from "../pages/Central";
import Vendor from "../pages/Vendor";
import Closer from "../pages/Closer";
import Lead from "../pages/Lead";
import Approval from "../pages/Approval";
import LgReport from "../pages/report/LgReport";
import CuReport from "../pages/report/CuReport";
import LeadReport from "../pages/report/LeadReport";
import AssignmentLoggingReport from "../pages/report/AssignmentLoggingReport";
import Assignment from "../pages/management/Assignment";
import HealthPlans from "../pages/management/HealthPlans";
import ImportData from "../pages/management/ImportData";
import ManageUsers from "../pages/management/ManageUsers";
import NewAccount from "../pages/management/NewAccount";
import StaffActivities from "../pages/management/StaffActivities";
import InfoList from "../pages/InfoList";
import Login from "../pages/Login";

const routes = [
  {
    path: "/login",
    element: <Login />,
    canAccessBy: [
      "admin",
      "staff",
      "central-user",
      "closer",
      "central-user",
      "vendor",
    ],
  },
  {
    path: "/active-vendor",
    element: <Login />,
    canAccessBy: [
      "admin",
      "staff",
      "central-user",
      "closer",
      "central-user",
      "vendor",
    ],
  },
  {
    path: "/",
    element: <Home />,
    canAccessBy: [
      "admin",
      "staff",
      "central-user",
      "closer",
      "vendor",
      "manager",
    ],
  },
  {
    path: "/info-list",
    element: <InfoList />,
    canAccessBy: ["admin", "staff"],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    canAccessBy: ["admin", "staff", "central-user", "closer", "vendor"],
  },
  {
    path: "/lead-generator",
    element: <LeadGenerator />,
    canAccessBy: ["admin", "staff"],
  },
  {
    path: "/imported-data",
    element: <ImportedData />,
    canAccessBy: ["admin", "central-user", "closer", "vendor"],
  },
  {
    path: "/central",
    element: <Central />,
    canAccessBy: ["admin", "central-user", "closer", "vendor"],
  },
  {
    path: "/vendor",
    element: <Vendor />,
    canAccessBy: ["admin"],
  },
  {
    path: "/closer",
    element: <Closer />,
    canAccessBy: ["admin", "closer"],
  },
  {
    path: "/lead",
    element: <Lead />,
    canAccessBy: ["admin", "staff", "central-user"],
  },
  {
    path: "/approval",
    element: <Approval />,
    canAccessBy: ["admin"],
  },
  {
    path: "/report/lg",
    element: <LgReport />,
    canAccessBy: ["admin"],
  },
  {
    path: "/report/cu",
    element: <CuReport />,
    canAccessBy: ["admin"],
  },
  {
    path: "/report/lead",
    element: <LeadReport />,
    canAccessBy: ["admin"],
  },
  {
    path: "/report/assignment-logging",
    element: <AssignmentLoggingReport />,
    canAccessBy: ["admin"],
  },
  {
    path: "/manage/zipcode-assignment",
    element: <Assignment />,
    canAccessBy: ["admin"],
  },
  {
    path: "/manage/register",
    element: <NewAccount />,
    canAccessBy: ["admin"],
  },
  {
    path: "/manage/user",
    element: <ManageUsers />,
    canAccessBy: ["admin"],
  },
  {
    path: "/manage/import-data",
    element: <ImportData />,
    canAccessBy: ["admin"],
  },
  {
    path: "/manage/staff-activities",
    element: <StaffActivities />,
    canAccessBy: ["admin"],
  },
  {
    path: "/manage/health-plans",
    element: <HealthPlans />,
    canAccessBy: ["admin"],
  },
];

export default routes;
