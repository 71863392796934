import React from "react";

// mui
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { hideMessage } from "../../store/actions/AppActions";

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarComponent() {
  const { message } = useSelector((state) => state.app);

  const handleClose = () => {
    store.dispatch(hideMessage());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={SlideTransition}
      open={message.show}
      onClose={handleClose}
      autoHideDuration={2000}
    >
      <Alert
        onClose={handleClose}
        severity={message.type}
        sx={{ width: "100%" }}
      >
        {message.msg}
      </Alert>
    </Snackbar>
  );
}
