import _ from "lodash";
// import moment from "moment";

import {
  PA_ACTION_LIST,
  USER_TYPE_LIST,
  LEAD_STATUS_LIST,
  LEAD_CATEGORY_LIST,
} from "./lists";
import { LEAD_STATUS } from "./enums";

export const LOGGING_COLUMNS = [
  {
    key: "customer",
    name: "Customer",
    fieldName: "customer",
    minWidth: 250,
    maxWidth: 250,
    onRender: (item) =>
      `${_.get(item, "customer.firstName", "")} ${_.get(
        item,
        "customer.lastName",
        ""
      )}`,
  },
  {
    key: "assigner",
    name: "Assigner",
    fieldName: "user",
    minWidth: 170,
    maxWidth: 170,
    onRender: (item) =>
      `${_.get(item, "user.fname", "")} ${_.get(item, "user.lname", "")}`,
  },
  {
    key: "assigner_type",
    name: "Assigner Type",
    fieldName: "userType",
    minWidth: 200,
    maxWidth: 200,
    onRender: (item) => {
      return _.get(
        USER_TYPE_LIST.find((opt) => opt.key === item.userType),
        "text",
        ""
      );
    },
  },
  {
    key: "action",
    name: "Action",
    fieldName: "action",
    minWidth: 200,
    maxWidth: 200,
    onRender: (item) => {
      return (
        <div className="ms-fontColor-themePrimary">
          {_.get(
            PA_ACTION_LIST.find((opt) => opt.key === item.action),
            "text",
            ""
          )}
        </div>
      );
    },
  },
  {
    key: "assignee",
    name: "Assignee",
    fieldName: "assignTo",
    minWidth: 200,
    maxWidth: 200,
    onRender: (item) =>
      `${_.get(item, "assignTo.fname", "")} ${_.get(
        item,
        "assignTo.lname",
        ""
      )}`,
  },
  {
    key: "assignee_type",
    name: "Assignee Type",
    fieldName: "assignToUserType",
    minWidth: 150,
    onRender: (item) => {
      return _.get(
        USER_TYPE_LIST.find((opt) => opt.key === item.assignToUserType),
        "text",
        ""
      );
    },
  },
  {
    key: "created_at",
    name: "Date of modification",
    fieldName: "createdAt",
    minWidth: 200,
    maxWidth: 200,
  },
];

export const CENTRAL_DASHBOARD_COLUMNS = [
  {
    key: "seqNumber",
    name: "#",
    fieldName: "seqNumber",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "firstName",
    name: "First Name",
    fieldName: "firstName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "middleName",
    name: "Middle Name",
    fieldName: "middleName",
    minWidth: 60,
    maxWidth: 60,
  },
  {
    key: "lastName",
    name: "Last Name",
    fieldName: "lastName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "birthDate",
    name: "Birth Date",
    fieldName: "birthDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "phone",
    name: "Phone",
    fieldName: "phone",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "address",
    name: "Address",
    fieldName: "address",
    minWidth: 120,
    maxWidth: 150,
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: "zipCode",
    name: "Zip Code",
    fieldName: "zipCode",
    minWidth: 50,
  },
  {
    key: "moveToWait",
    name: "",
    fieldName: "moveToWait",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: "status",
    name: "Status",
    fieldName: "status",
    minWidth: 50,
    minWidth: 50,
    onRender: (item) => {
      const label = _.get(
        LEAD_STATUS_LIST.find((opt) => opt.key === item.status),
        "text",
        "Open"
      );
      const colorClass =
        (item.status === LEAD_STATUS.DELETED && "ms-fontColor-redDark") ||
        "ms-fontColor-themePrimary";
      return (
        item.key !== "filterRow" && <span className={colorClass}>{label}</span>
      );
    },
  },
  {
    key: "importdate",
    name: "Import Date",
    fieldName: "importDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "assignedToCloserAt",
    name: "Assigned Date",
    fieldName: "assignedToCloserAt",
    minWidth: 130,
    maxWidth: 130,
  },
  {
    key: "staff",
    name: "LG Assigned",
    fieldName: "staff",
    onRender(item) {
      if (item.staffAssigned) {
        return `${item.staffAssigned.fname} ${item.staffAssigned.lname}`;
      } else {
        return "";
      }
    },
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "process",
    name: "",
    fieldName: "process",
    minWidth: 80,
    maxWidth: 80,
  },
];

export const LEAD_COLUMNS = [
  {
    key: "seqNumber",
    name: "#",
    fieldName: "seqNumber",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "firstName",
    name: "First Name",
    fieldName: "firstName",
    minWidth: 90,
    maxWidth: 90,
  },
  // {
  //     key: 'middleName',
  //     name: 'Middle Name',
  //     fieldName: 'middleName',
  //     minWidth: 60,
  //     maxWidth: 60
  // },
  {
    key: "lastName",
    name: "Last Name",
    fieldName: "lastName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "birthDate",
    name: "Birth Date",
    fieldName: "birthDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "phone",
    name: "Phone",
    fieldName: "phone",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "address",
    name: "Address",
    fieldName: "address",
    minWidth: 120,
    maxWidth: 150,
    onRender: (item) => <span title={item.address}>{item.address}</span>,
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: "zipCode",
    name: "Zip Code",
    fieldName: "zipCode",
    minWidth: 50,
  },
  {
    key: "process",
    name: "",
    fieldName: "process",
    minWidth: 140,
    minWidth: 140,
  },
  {
    key: "status",
    name: "Status",
    fieldName: "status",
    minWidth: 50,
    minWidth: 50,
    onRender: (item) => {
      const label = _.get(
        LEAD_STATUS_LIST.find((opt) => opt.key === item.status),
        "text",
        LEAD_STATUS_LIST[0].text
      );
      const colorClass =
        (item.status === LEAD_STATUS.DELETED && "ms-fontColor-redDark") ||
        "ms-fontColor-themePrimary";
      return (
        item.key !== "filterRow" && <span className={colorClass}>{label}</span>
      );
    },
  },
  {
    key: "importdate",
    name: "Import Date",
    fieldName: "importDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "assignedToCloserAt",
    name: "Assigned Date",
    fieldName: "assignedToCloserAt",
    minWidth: 130,
    maxWidth: 130,
  },
  {
    key: "changedProcessAt",
    name: "Lead Created",
    fieldName: "changedProcessAt",
    minWidth: 130,
    maxWidth: 130,
  },
  {
    key: "staff",
    name: "LG Assigned",
    fieldName: "staff",
    onRender(item) {
      if (item.staffAssigned) {
        return item.staffAssigned.fname + " " + item.staffAssigned.lname;
      } else {
        return "";
      }
    },
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "cu",
    name: "CU Assigned",
    fieldName: "assignedToCloserBy",
    onRender(item) {
      if (item.assignedToCloserBy) {
        return (
          item.assignedToCloserBy.fname + " " + item.assignedToCloserBy.lname
        );
      } else {
        return "";
      }
    },
    minWidth: 90,
    maxWidth: 90,
  },
];

export const LEAD_REPORT_COLUMNS = [
  {
    key: "seqNumber",
    name: "#",
    fieldName: "seqNumber",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "firstName",
    name: "First Name",
    fieldName: "firstName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "middleName",
    name: "Middle Name",
    fieldName: "middleName",
    minWidth: 60,
    maxWidth: 60,
  },
  {
    key: "lastName",
    name: "Last Name",
    fieldName: "lastName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "birthDate",
    name: "Birth Date",
    fieldName: "birthDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "phone",
    name: "Phone",
    fieldName: "phone",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "address",
    name: "Address",
    fieldName: "address",
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: "zipCode",
    name: "Zip Code",
    fieldName: "zipCode",
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: "importdate",
    name: "Import Date",
    fieldName: "importDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "staff",
    name: "LG Assigned",
    fieldName: "staff",
    onRender(item) {
      if (item.staffAssigned) {
        return item.staffAssigned.fname + " " + item.staffAssigned.lname;
      } else {
        return "";
      }
    },
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "process",
    name: "",
    fieldName: "process",
    minWidth: 50,
    maxWidth: 50,
  },
];

export const INFO_COLUMNS = () => [
  {
    key: "seqNumber",
    name: "#",
    fieldName: "seqNumber",
    minWidth: 90,
    maxWidth: 90,
    onRender: (item) => {
      return item.seqNumber || item.sequentialLeadNumber;
    },
  },
  {
    key: "firstName",
    name: "First Name",
    fieldName: "firstName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "middleName",
    name: "Mid Name",
    fieldName: "middleName",
    minWidth: 70,
    maxWidth: 70,
  },
  {
    key: "lastName",
    name: "Last Name",
    fieldName: "lastName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "birthDate",
    name: "Birth Date",
    fieldName: "birthDate",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "address",
    name: "Address",
    fieldName: "address",
    minWidth: 200,
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: "phone",
    name: "Phone",
    fieldName: "phone",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: "zipCode",
    name: "Zip Code",
    fieldName: "zipCode",
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: "importdate",
    name: "Import Date",
    fieldName: "importDate",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: "process",
    name: "Process",
    fieldName: "process",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "processresults",
    name: "Process Results",
    fieldName: "processResults",
    minWidth: 200,
    maxWidth: 200,
  },
  {
    key: "comments",
    name: "Comments",
    fieldName: "comments",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "reminderDate",
    name: "Reminder Date",
    fieldName: "reminderDate",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "staff",
    name: "LG Assigned",
    fieldName: "staff",
    onRender: (item) => {
      if (item.staffAssigned) {
        return item.staffAssigned.fname + " " + item.staffAssigned.lname;
      } else {
        return "";
      }
    },
    minWidth: 150,
    maxWidth: 150,
  },
];

export const VENDOR_COLUMNS = () => [
  {
    key: "name",
    name: "Full Name",
    fieldName: "name",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "contactName",
    name: "Contact Name",
    fieldName: "contactName",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "address1",
    name: "Address 1",
    fieldName: "address1",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "address2",
    name: "Address 2",
    fieldName: "address2",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 40,
    maxWidth: 40,
  },
  {
    key: "phone",
    name: "Phone",
    fieldName: "phone",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "zipcode",
    name: "Zip Code",
    fieldName: "zipcode",
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: "contactPhone",
    name: "Contact Phone",
    fieldName: "contactPhone",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: "contactEmail",
    name: "Contact Email",
    fieldName: "contactEmail",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "registrationConfirmed",
    name: "Status",
    fieldName: "registrationConfirmed",
  },

  {
    key: "editData",
    name: "",
    fieldName: "editData",
    minWidth: 80,
    maxWidth: 80,
  },
];

export const DRIVER_COLUMNS = () => [
  {
    key: "name",
    name: "Full Name",
    fieldName: "name",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "address1",
    name: "Address 1",
    fieldName: "address1",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "address2",
    name: "Address 2",
    fieldName: "address2",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: "phone",
    name: "Phone",
    fieldName: "phone",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "mobileno",
    name: "Mobile No",
    fieldName: "mobileno",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "zipcode",
    name: "Zip Code",
    fieldName: "zipcode",
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: "contactEmail",
    name: "Email",
    fieldName: "contactEmail",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "certifications",
    name: "Certifications",
    fieldName: "certifications",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: "dateAvailable",
    name: "Date Available",
    fieldName: "dateAvailable",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "timeAvailable",
    name: "Time Available",
    fieldName: "timeAvailable",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "comments",
    name: "Comments",
    fieldName: "comments",
  },
  {
    key: "customercomments",
    name: "Customer Comments",
    fieldName: "customercomments",
  },
  {
    key: "registrationConfirmed",
    name: "Status",
    fieldName: "registrationConfirmed",
  },

  {
    key: "editData",
    name: "",
    fieldName: "editData",
    minWidth: 80,
    maxWidth: 80,
  },
];
