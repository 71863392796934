import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const LabelCustom = (props) => {
  const { x, y, value } = props;

  return (
    <text
      x={x}
      y={y}
      dy={10}
      dx={("" + value).length * 1}
      fill="#000"
      fontSize={10}
      transform={`rotate(${-90}, ${x}, ${y})`}
    >
      {value}
    </text>
  );
};

const StaffReportChart = ({ chartData }) => {
  function widthChart() {
    try {
      // var data = chartData.data;
      // var v = data.length / 10;
      // v = v > 1 ? v : 1;

      return window.innerWidth - 50;
      // return Math.round(window.innerWidth * v);
    } catch (ex) {
      return 1000;
    }
  }

  function heightChart() {
    try {
      return window.innerHeight - 100;
    } catch (ex) {
      return 600;
    }
  }

  return (
    <div width="100%" height="100%">
      <BarChart
        style={{ backgroundColor: "#fff" }}
        width={widthChart()}
        height={heightChart()}
        data={chartData.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis padding={{ top: 50 }} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar
          label={<LabelCustom />}
          name="Lead"
          dataKey="lead"
          fill="#106ebe"
        />
        <Bar
          label={<LabelCustom />}
          name="In Process"
          dataKey="inProcess"
          fill="#ffb900"
        />
        <Bar
          label={<LabelCustom />}
          name="No Answer"
          dataKey="noAnswer"
          fill="#d83b01"
        />
        <Bar
          label={<LabelCustom />}
          name="Discontinued"
          dataKey="discontinued"
          fill="#5c2d91"
        />
        <Bar
          label={<LabelCustom />}
          name="Not Interested"
          dataKey="notInterested"
          fill="#107c10"
        />
        <Bar
          label={<LabelCustom />}
          name="Wrong Number"
          dataKey="wrongNumber"
          fill="#00B294"
        />
      </BarChart>
    </div>
  );
};

export default StaffReportChart;
