// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";

// state
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

const ALLOWED_PATHNAMES = ["/lead-generator", "/info-list", "/closer"];

export default function ChangeCategoryButton({ customer, openModal }) {
  const pathname = useGetCurrentPathname();

  if (!ALLOWED_PATHNAMES.includes(pathname)) {
    return null;
  }

  return (
    <Tooltip title="Change Category">
      <IconButton
        color="actionButton"
        aria-label="Change Category"
        onClick={() => openModal("category-form", customer, "xs")}
      >
        <PeopleRoundedIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  );
}
