import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
import FormCloseButton from "../buttons/FormCloseButton";
import TextInput from "../inputs/TextInput";

function VendorForm({ closeForm, loading, handleForm, data }) {
  console.log("Rendering VendorForm");

  console.log("data", data);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name ?? "",
      contactName: data.contactName ?? "",
      phone: data.phone ?? "",
      contactPhone: data.contactPhone ?? "",
      contactEmail: data.contactEmail ?? "",
      address1: data.address1 ?? "",
      address2: data.address2 ?? "",
      city: data.city ?? "",
      state: data.state ?? "",
      zipcode: data.zipcode ?? "",
      password: "",
      orgType: "vendor",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      contactEmail: Yup.string().email("Please enter valid email"),
    }),
    onSubmit: (values) => {
      let newValues = { ...values };

      if (data && data._id) {
        newValues = { ...data, ...values };

        delete newValues.password;
        delete newValues.orgType;
      }

      handleForm("new-venfor", newValues);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>New Vendor Form</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="text"
            label="Vendor Name"
            placeholder="Please enter the vendor name"
            value={formik.values.name}
            getValue={(value) => formik.setFieldValue("name", value)}
            onBlur={() => formik.setFieldTouched("name")}
            error={Boolean(formik.errors.name) && formik.touched.name}
            errorMsg={formik.errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="text"
            label="Contact Name"
            placeholder="Please enter the contact name"
            value={formik.values.contactName}
            getValue={(value) => formik.setFieldValue("contactName", value)}
            onBlur={() => formik.setFieldTouched("contactName")}
            error={
              Boolean(formik.errors.contactName) && formik.touched.contactName
            }
            errorMsg={formik.errors.contactName}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={6}>
          <TextInput
            type="text"
            label="Contact Email"
            placeholder="Please enter the contact email address"
            value={formik.values.contactEmail}
            getValue={(value) => formik.setFieldValue("contactEmail", value)}
            onBlur={() => formik.setFieldTouched("contactEmail")}
            error={
              Boolean(formik.errors.contactEmail) && formik.touched.contactEmail
            }
            errorMsg={formik.errors.contactEmail}
          />
        </Grid>

        <Grid item xs={6} />
        <Grid item xs={6} />

        <Grid item xs={6}>
          <TextInput
            type="number"
            label="Phone Number"
            placeholder="Please enter the phone number"
            value={formik.values.phone}
            getValue={(value) => formik.setFieldValue("phone", value)}
            onBlur={() => formik.setFieldTouched("phone")}
            error={Boolean(formik.errors.phone) && formik.touched.phone}
            errorMsg={formik.errors.phone}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="number"
            label="Contact Phone Number"
            placeholder="Please enter the contact phone number"
            value={formik.values.contactPhone}
            getValue={(value) => formik.setFieldValue("contactPhone", value)}
            onBlur={() => formik.setFieldTouched("contactPhone")}
            error={
              Boolean(formik.errors.contactPhone) && formik.touched.contactPhone
            }
            errorMsg={formik.errors.contactPhone}
          />
        </Grid>

        <Grid item xs={12}>
          <h3 style={{ marginBottom: 0 }}>Address Details</h3>
          {/*  <hr /> */}
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Address 1"
            placeholder="Please enter the address 1"
            value={formik.values.address1}
            getValue={(value) => formik.setFieldValue("address1", value)}
            onBlur={() => formik.setFieldTouched("address1")}
            error={Boolean(formik.errors.address1) && formik.touched.address1}
            errorMsg={formik.errors.address1}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Address 2"
            placeholder="Please enter the address 2"
            value={formik.values.address2}
            getValue={(value) => formik.setFieldValue("address2", value)}
            onBlur={() => formik.setFieldTouched("address2")}
            error={Boolean(formik.errors.address2) && formik.touched.address2}
            errorMsg={formik.errors.address2}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="City"
            placeholder="Please enter the city"
            value={formik.values.city}
            getValue={(value) => formik.setFieldValue("city", value)}
            onBlur={() => formik.setFieldTouched("city")}
            error={Boolean(formik.errors.city) && formik.touched.city}
            errorMsg={formik.errors.city}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="State"
            placeholder="Please enter the state"
            value={formik.values.state}
            getValue={(value) => formik.setFieldValue("state", value)}
            onBlur={() => formik.setFieldTouched("state")}
            error={Boolean(formik.errors.state) && formik.touched.state}
            errorMsg={formik.errors.state}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Zipcode"
            placeholder="Please enter the zipcode"
            value={formik.values.zipcode}
            getValue={(value) => formik.setFieldValue("zipcode", value)}
            onBlur={() => formik.setFieldTouched("zipcode")}
            error={Boolean(formik.errors.zipcode) && formik.touched.zipcode}
            errorMsg={formik.errors.zipcode}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default VendorForm;
