// state
import { useSelector } from "react-redux";
import store from "../../../store";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

// components
import SelectSearchInput from "../../inputs/SelectSearchInput";

const LeadGeneratorFilter = () => {
  const { userList, loading, urlParams } = useSelector(
    (state) => state.customer
  );
  const staffs = userList.filter((user) => user.accountType === "staff");

  const _handleLeadGeneratorFilter = async (e, v) => {
    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = { ...urlParams, staffAssignedId: v._id };
    // delete newUrlParams.staffAssignedId;

    const customZipCodes = newUrlParams.customZipCodes ?? null;

    const res = await fetchCustomersService(newUrlParams, customZipCodes);

    let payload = {
      ...res,
      urlParams: newUrlParams,
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  return (
    <SelectSearchInput
      label="Filter by Lead Generator"
      options={staffs}
      onChange={_handleLeadGeneratorFilter}
    />
  );
};

export default LeadGeneratorFilter;
