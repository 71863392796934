import React from "react";
import _ from "lodash";

// MUI Components
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

// Custom Components and Utilities
import AppModel from "../../models/AppModel";
import InfiniteScrollComponent from "../InfiniteScrollComponent";
import CustomTextInput from "../inputs/CustomTextInput";

// Constants
import { LOGGING_COLUMNS } from "../../constants/tableConfig";
import { USER_TYPE_LIST, PA_ACTION_LIST } from "../../constants/lists";

export default function AssignmentLoggingReportTable({
  logs,
  loading,
  hasMore,
  loadMore,
  filterCustomer,
}) {
  const renderTableHeader = () => (
    <div style={styles.tableHeader}>
      {LOGGING_COLUMNS.map((column) => (
        <div key={column.key} style={{ minWidth: column.minWidth }}>
          {column.name}
        </div>
      ))}
    </div>
  );

  const renderFilters = () => (
    <div style={styles.filterRow}>
      {LOGGING_COLUMNS.map((column) => (
        <div key={column.key} style={{ minWidth: column.minWidth }}>
          {column.key === "customer" ? (
            <CustomTextInput
              placeholder="Find Customer"
              getValue={(value) => filterCustomer({ filterCustomer: value })}
            />
          ) : column.key === "assignee" ? (
            <CustomTextInput
              placeholder="Find Assignee"
              getValue={(value) => filterCustomer({ filterAssignTo: value })}
            />
          ) : null}
        </div>
      ))}
    </div>
  );

  const renderSkeletons = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ls) => (
      <Stack key={ls} direction="row" spacing={1} style={styles.skeletonRow}>
        {LOGGING_COLUMNS.map((column) => (
          <Skeleton
            key={column.key}
            variant="rounded"
            width={column.minWidth}
            height={40}
          />
        ))}
      </Stack>
    ));
  };

  const renderLogRow = (log) => (
    <div key={log.id} style={styles.logRow}>
      <div style={styles.logDetails}>
        {LOGGING_COLUMNS.map((column) => {
          const item = log[column.fieldName];
          switch (column.key) {
            case "customer":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {`${_.get(item, "firstName", "")} ${_.get(
                    item,
                    "lastName",
                    ""
                  )}`}
                </div>
              );
            case "assigner":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {`${_.get(item, "fname", "")} ${_.get(item, "lname", "")}`}
                </div>
              );
            case "assigner_type":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {_.get(
                    USER_TYPE_LIST.find((opt) => opt.key === item),
                    "value",
                    ""
                  )}
                </div>
              );
            case "action":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {_.get(
                    PA_ACTION_LIST.find((opt) => opt.key === item),
                    "value",
                    ""
                  )}
                </div>
              );
            case "assignee":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {`${_.get(item, "fname", "")} ${_.get(item, "lname", "")}`}
                </div>
              );
            case "assignee_type":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {_.get(
                    USER_TYPE_LIST.find(
                      (opt) => opt.key === item.assignToUserType
                    ),
                    "value",
                    ""
                  )}
                </div>
              );
            case "created_at":
              return (
                <div key={column.key} style={{ minWidth: column.minWidth }}>
                  {AppModel.formatDate(item)}
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
      <br />
      {log.description ? (
        <div>{log.description}</div>
      ) : (
        <i>--No description--</i>
      )}
    </div>
  );

  return (
    <>
      <div style={styles.headerWrapper}>
        {renderTableHeader()}
        {renderFilters()}
      </div>

      <br />
      <br />
      <br />
      <br />

      {loading ? (
        renderSkeletons()
      ) : (
        <InfiniteScrollComponent hasMore={hasMore} loadMore={loadMore}>
          {logs.length > 0 && logs.map((log) => renderLogRow(log))}
        </InfiniteScrollComponent>
      )}
    </>
  );
}

const styles = {
  headerWrapper: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #f1f1f1",
    marginBottom: 10,
    position: "fixed",
    zIndex: 99,
  },
  tableHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    color: "#777",
    padding: "10px 0px",
  },
  filterRow: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    color: "#777",
    padding: "10px 0px",
  },
  skeletonRow: {
    marginBottom: 5,
  },
  logRow: {
    fontSize: 14,
    borderBottom: "1px solid #f1f1f1",
    color: "#444",
    padding: "10px 0px",
    marginBottom: 10,
  },
  logDetails: {
    display: "flex",
    alignItems: "center",
  },
};
