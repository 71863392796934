import { useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
// import * as Yup from "yup";
import Swal from "sweetalert2";
import config from "../../config/app";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// components
import FormButtons from "../buttons/FormButtons";
import DatePickerComponent from "../inputs/DatePicker";
import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";

// others
import {
  PATIENT_ASSIGNMENT_LIST,
  LEAD_CLASS_OPTIONS,
} from "../../constants/lists";
import { PATIENT_ASSIGNMENT } from "../../constants/enums";
import FormCloseButton from "../buttons/FormCloseButton";
import { useSelector } from "react-redux";
import {
  saveCustomers,
  setFormSubmitting,
} from "../../store/actions/CustomerActions";
import store from "../../store";
import { deleteDocService } from "../../services/CustomerServices";
import PrintButton from "../tables/components/PrintButton";
// import { SignalCellularNull } from "@mui/icons-material";

function createOption(key, value) {
  return { key, value };
}

const options = [
  createOption("", ""),
  createOption("Yes", "Yes"),
  createOption("No", "No"),
];
const typeOptions = [
  createOption("", ""),
  createOption("medicare", "Medicare"),
  createOption("medical", "Medical"),
  createOption("medi/medi", "Medi/Medi"),
  createOption("hmo", "HMO"),
];

/* const HMOorFFSoptions = [
  createOption("HMO", "HMO"),
  createOption("FFS", "FFS"),
]; */

const PAGE_PA_LIST = PATIENT_ASSIGNMENT_LIST.filter(
  (opt) => opt.key !== PATIENT_ASSIGNMENT.DO_NOT_KNOW
);

function LeadForm({
  closeForm,
  data,
  loading,
  handleForm,
  leadClassOption,
  user,
}) {
  // console.log("Rendering LeadForm");
  const [selectedType, setSelectedType] = useState("");
  const [saveAndPrint, setSaveAndPrint] = useState(false);
  // console.log("data", data);
  // console.log("user", user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: data._id,
      seqNumber: data.seqNumber ?? "",
      medicareNumber: data.medicareNumber ?? "",
      medicalNumber: data.medicalNumber ?? "",
      HMOorFFS: data.HMOorFFS ?? "",
      mcHMOorFFS: data.mcHMOorFFS ?? "",
      partADate: data.partADate ?? "",
      partBDate: data.partBDate ?? "",
      medicareIssueDate: data.medicareIssueDate ?? "",
      clinicDoctor: data.clinicDoctor ?? "",
      clinicName: data.clinicName ?? "",
      hospitalTwo: data.hospitalTwo ?? "",
      serviceStartDate: data.serviceStartDate ?? "",
      serviceEndDate: data.serviceEndDate ?? "",
      hospitalOne: data.hospitalOne ?? "",
      relativeFirstName: data.relativeFirstName ?? "",
      relativeLastName: data.relativeLastName ?? "",
      relativePhone: data.relativePhone ?? "",
      medications: data.medications ?? "",
      diagnosisOne: data.diagnosisOne ?? "",
      diagnosisTwo: data.diagnosisTwo ?? "",
      hmoHappy: data.hmoHappy ? "Yes" : "No",
      homHappyReason: data.homHappyReason ?? "",
      doctorHappy: data.doctorHappy ? "Yes" : "No",
      doctorHappyReason: data.doctorHappyReason ?? "",
      comments: data.comments ?? "",
      healthPlans: data.healthPlans ?? "",
      changedProcessAt:
        dayjs(data.changedProcessAt).format("MMM DD YYYY") ?? "",
      leadClass: "",
      patientAssignment: data.patientAssignment ?? "",
      relativeAddress: data.relativeAddress ?? "",
      relativeAddress2: data.relativeAddress2 ?? "",
      relativeAge: data.relativeAge ?? 0,
      relativeCity: data.relativeCity ?? "",
      relativeEmailAddress: data.relativeEmailAddress ?? "",
      relativeMiddleName: data.relativeMiddleName ?? "",
      relativeState: data.relativeState ?? "",
      relativeZipCode: data.relativeZipCode ?? "",
      relativeZipCodePlus: data.relativeZipCodePlus ?? "",
    },
    // validationSchema: Yup.object({
    //   firstName: Yup.string().required("Required"),
    //   lastName: Yup.string().required("Required"),
    //   emailAddress: Yup.string().email("Please enter valid email"),
    // }),
    onSubmit: (values) => {
      // console.log("values", values);
      // return;

      handleForm(
        "lead",
        {
          ...data,
          ...values,
          doctorHappy: values.doctorHappy === "Yes" ? true : false,
          hmoHappy: values.hmoHappy === "Yes" ? true : false,
        },
        saveAndPrint
      );
    },
  });

  const showPDF = (doc) => {
    const pdf = `${config.baseUrl}/customer/get-insurance-doc/${doc.id}?token=${user.token}`;
    window.open(pdf, "_blank").focus();
  };

  const { customers, totalRecords, urlParams, loadMoreOptions } = useSelector(
    (state) => state.customer
  );

  const deleteTheDoc = async (docId) => {
    try {
      const result = await Swal.fire({
        title: "Would you like to delete this document?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) return;

      console.log("customerId, docId", data._id, docId);

      store.dispatch(setFormSubmitting(true));

      await deleteDocService(data._id, docId);

      let newCustomers = customers;
      let newCustomersLength = newCustomers.length;

      for (let i = 0; i < newCustomersLength; i++) {
        const newCustomer = newCustomers[i];

        if (newCustomer._id === data._id) {
          console.log("newCustomer", newCustomer);
          if (newCustomer.insurancedoc && newCustomer.insurancedoc.length > 0) {
            newCustomers[i].insurancedoc = newCustomer.insurancedoc.filter(
              (doc) => doc.id !== docId
            );
          }

          break;
        }
      }

      store.dispatch(
        saveCustomers({
          customers: newCustomers,
          total: totalRecords,
          loadMoreOptions: { ...loadMoreOptions },
          urlParams,
        })
      );
    } catch (error) {
      console.log("error", error);
      alert("Server timeout");
      store.dispatch(setFormSubmitting(false));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Lead Details</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={3}>
          <TextInput
            type="text"
            label="Sequential Lead Number"
            placeholder="Enter the first name"
            value={formik.values.seqNumber}
            getValue={(value) => formik.setFieldValue("seqNumber", value)}
            onBlur={() => formik.setFieldTouched("seqNumber")}
            error={Boolean(formik.errors.seqNumber) && formik.touched.seqNumber}
            errorMsg={formik.errors.seqNumber}
          />
        </Grid>

        <Grid item xs={3}>
          <SelectInput
            label="Select Type"
            size="small"
            options={typeOptions}
            value={selectedType}
            getValue={(value) => setSelectedType(value)}
            onBlur={() => {}}
            error={Boolean(!selectedType)}
            errorMsg="Please select a type"
          />
        </Grid>

        <Grid item xs={3}>
          <TextInput
            type="text"
            label="Medicare Number"
            placeholder="Enter the Medicare Number"
            value={formik.values.medicareNumber}
            getValue={(value) => formik.setFieldValue("medicareNumber", value)}
            onBlur={() => formik.setFieldTouched("medicareNumber")}
            error={
              Boolean(formik.errors.medicareNumber) &&
              formik.touched.medicareNumber
            }
            errorMsg={formik.errors.medicareNumber}
          />
        </Grid>

        <Grid item xs={3}>
          <TextInput
            type="text"
            label="Added To Lead At"
            placeholder="Added To Lead At"
            value={formik.values.changedProcessAt}
            getValue={(value) =>
              formik.setFieldValue("changedProcessAt", value)
            }
            onBlur={() => formik.setFieldTouched("changedProcessAt")}
            error={
              Boolean(formik.errors.changedProcessAt) &&
              formik.touched.changedProcessAt
            }
            errorMsg={formik.errors.changedProcessAt}
            disabled={true}
          />
        </Grid>

        {selectedType === "hmo" ? (
          <Grid item xs={3}>
            <SelectInput
              label="Health Plans"
              size="small"
              options={[]}
              value={formik.values.mcHMOorFFS}
              getValue={(value) => formik.setFieldValue("mcHMOorFFS", value)}
              onBlur={() => formik.setFieldTouched("mcHMOorFFS")}
              error={
                Boolean(formik.errors.mcHMOorFFS) && formik.touched.mcHMOorFFS
              }
              errorMsg={formik.errors.mcHMOorFFS}
            />
          </Grid>
        ) : null}

        {selectedType === "medicare" ||
        selectedType === "medi/medi" ||
        selectedType === "hmo" ? (
          <Grid item xs={3}>
            <DatePickerComponent
              label="Part A Issue Date"
              value={formik.values.partADate}
              getValue={(value) => formik.setFieldValue("partADate", value)}
              onClose={() => console.log("closed")}
            />
          </Grid>
        ) : null}

        {selectedType === "medicare" ||
        selectedType === "medi/medi" ||
        selectedType === "hmo" ? (
          <Grid item xs={3}>
            <DatePickerComponent
              label="Part B Issue Date"
              value={formik.values.partBDate}
              getValue={(value) => formik.setFieldValue("partBDate", value)}
              onClose={() => console.log("closed")}
            />
          </Grid>
        ) : null}

        {selectedType === "medical" || selectedType === "medi/medi" ? (
          <Grid item xs={4}>
            <DatePickerComponent
              label="Medical Issue Date"
              value={formik.values.medicareIssueDate}
              getValue={(value) =>
                formik.setFieldValue("medicareIssueDate", value)
              }
              onClose={() => console.log("closed")}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} />

        <Grid item xs={12}>
          <h3 style={{ marginBottom: 0 }}>Doctor Details</h3>
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Clinic Name"
            placeholder="Enter the Clinic Name"
            value={formik.values.clinicName}
            getValue={(value) => formik.setFieldValue("clinicName", value)}
            onBlur={() => formik.setFieldTouched("clinicName")}
            error={
              Boolean(formik.errors.clinicName) && formik.touched.clinicName
            }
            errorMsg={formik.errors.clinicName}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Clinic Doctor"
            placeholder="Enter the clinic doctor"
            value={formik.values.clinicDoctor}
            getValue={(value) => formik.setFieldValue("clinicDoctor", value)}
            onBlur={() => formik.setFieldTouched("clinicDoctor")}
            error={
              Boolean(formik.errors.clinicDoctor) && formik.touched.clinicDoctor
            }
            errorMsg={formik.errors.clinicDoctor}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Insurance Plan"
            placeholder="Enter the insurance plan"
            value={formik.values.insurancePlan}
            getValue={(value) => formik.setFieldValue("insurancePlan", value)}
            onBlur={() => formik.setFieldTouched("insurancePlan")}
            error={
              Boolean(formik.errors.insurancePlan) &&
              formik.touched.insurancePlan
            }
            errorMsg={formik.errors.insurancePlan}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Hospital One"
            placeholder="Enter the Hospital One"
            value={formik.values.hospitalOne}
            getValue={(value) => formik.setFieldValue("hospitalOne", value)}
            onBlur={() => formik.setFieldTouched("hospitalOne")}
            error={
              Boolean(formik.errors.hospitalOne) && formik.touched.hospitalOne
            }
            errorMsg={formik.errors.hospitalOne}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Hospital Two"
            placeholder="Enter the Hospital Two"
            value={formik.values.hospitalTwo}
            getValue={(value) => formik.setFieldValue("hospitalTwo", value)}
            onBlur={() => formik.setFieldTouched("hospitalTwo")}
            error={
              Boolean(formik.errors.hospitalTwo) && formik.touched.hospitalTwo
            }
            errorMsg={formik.errors.hospitalTwo}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Relative First Name"
            placeholder="Enter the Relative First Name"
            value={formik.values.relativeFirstName}
            getValue={(value) =>
              formik.setFieldValue("relativeFirstName", value)
            }
            onBlur={() => formik.setFieldTouched("relativeFirstName")}
            error={
              Boolean(formik.errors.relativeFirstName) &&
              formik.touched.relativeFirstName
            }
            errorMsg={formik.errors.relativeFirstName}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Relative Last Name"
            placeholder="Enter the Relative Last Name"
            value={formik.values.relativeLastName}
            getValue={(value) =>
              formik.setFieldValue("relativeLastName", value)
            }
            onBlur={() => formik.setFieldTouched("relativeLastName")}
            error={
              Boolean(formik.errors.relativeLastName) &&
              formik.touched.relativeLastName
            }
            errorMsg={formik.errors.relativeLastName}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Relative phone"
            placeholder="Enter the Relative phone"
            value={formik.values.relativePhone}
            getValue={(value) => formik.setFieldValue("relativePhone", value)}
            onBlur={() => formik.setFieldTouched("relativePhone")}
            error={
              Boolean(formik.errors.relativePhone) &&
              formik.touched.relativePhone
            }
            errorMsg={formik.errors.relativePhone}
          />
        </Grid>

        <Grid item xs={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>More Relative Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    type="text"
                    label="Middle Name"
                    placeholder="Enter the relative middle name"
                    value={formik.values.relativeMiddleName}
                    getValue={(value) =>
                      formik.setFieldValue("relativeMiddleName", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeMiddleName")}
                    error={
                      Boolean(formik.errors.relativeMiddleName) &&
                      formik.touched.relativeMiddleName
                    }
                    errorMsg={formik.errors.relativeMiddleName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    type="text"
                    label="Email"
                    placeholder="Enter the relative email address"
                    value={formik.values.relativeEmailAddress}
                    getValue={(value) =>
                      formik.setFieldValue("relativeEmailAddress", value)
                    }
                    onBlur={() =>
                      formik.setFieldTouched("relativeEmailAddress")
                    }
                    error={
                      Boolean(formik.errors.relativeEmailAddress) &&
                      formik.touched.relativeEmailAddress
                    }
                    errorMsg={formik.errors.relativeEmailAddress}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    type="text"
                    label="Address"
                    placeholder="Enter the relative address"
                    value={formik.values.relativeAddress}
                    getValue={(value) =>
                      formik.setFieldValue("relativeAddress", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeAddress")}
                    error={
                      Boolean(formik.errors.relativeAddress) &&
                      formik.touched.relativeAddress
                    }
                    errorMsg={formik.errors.relativeAddress}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    type="text"
                    label="Address 2"
                    placeholder="Enter the relative address 2"
                    value={formik.values.relativeAddress2}
                    getValue={(value) =>
                      formik.setFieldValue("relativeAddress2", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeAddress2")}
                    error={
                      Boolean(formik.errors.relativeAddress2) &&
                      formik.touched.relativeAddress2
                    }
                    errorMsg={formik.errors.relativeAddress2}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    type="text"
                    label="City"
                    placeholder="Enter the relative city"
                    value={formik.values.relativeCity}
                    getValue={(value) =>
                      formik.setFieldValue("relativeCity", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeCity")}
                    error={
                      Boolean(formik.errors.relativeCity) &&
                      formik.touched.relativeCity
                    }
                    errorMsg={formik.errors.relativeCity}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    type="text"
                    label="State"
                    placeholder="Enter the relative state"
                    value={formik.values.relativeState}
                    getValue={(value) =>
                      formik.setFieldValue("relativeState", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeState")}
                    error={
                      Boolean(formik.errors.relativeState) &&
                      formik.touched.relativeState
                    }
                    errorMsg={formik.errors.relativeState}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    type="text"
                    label="Zip"
                    placeholder="Enter the relative zip"
                    value={formik.values.relativeZipCode}
                    getValue={(value) =>
                      formik.setFieldValue("relativeZipCode", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeZipCode")}
                    error={
                      Boolean(formik.errors.relativeZipCode) &&
                      formik.touched.relativeZipCode
                    }
                    errorMsg={formik.errors.relativeZipCode}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    type="text"
                    label="Zip+"
                    placeholder="Enter the relative zip+"
                    value={formik.values.relativeZipCodePlus}
                    getValue={(value) =>
                      formik.setFieldValue("relativeZipCodePlus", value)
                    }
                    onBlur={() => formik.setFieldTouched("relativeZipCodePlus")}
                    error={
                      Boolean(formik.errors.relativeZipCodePlus) &&
                      formik.touched.relativeZipCodePlus
                    }
                    errorMsg={formik.errors.relativeZipCodePlus}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {data.insurancedoc && data.insurancedoc.length > 0 && (
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>List of Insurance Documents</h3>
          </Grid>
        )}

        {data.insurancedoc && data.insurancedoc.length > 0 ? (
          <Grid item xs={12}>
            {data.insurancedoc.map((doc, index) => (
              <div key={index}>
                {doc.filename ? (
                  <div className="d-flex" style={{ marginBottom: 5 }}>
                    <p style={{ fontSize: 14, margin: 0 }}>{doc.filename} -</p>
                    <div style={{ paddingLeft: 5 }}>
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          aria-label="view"
                          color="primary"
                          size="small"
                          onClick={() => showPDF(doc)}
                        >
                          <VisibilityRoundedIcon style={{ fontSize: 17 }} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          size="small"
                          onClick={() => deleteTheDoc(doc.id)}
                        >
                          <DeleteRoundedIcon style={{ fontSize: 17 }} />
                        </IconButton>
                      </Stack>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}

            {/*  <iframe
              src={`${config.baseUrl}/customer/get-insurance-doc/639ff0a9a994e76a12b0ff78?token=${user.token}`}
              width="500px"
              height="500px"
            ></iframe> */}
          </Grid>
        ) : null}

        <Grid item xs={12} />

        <Grid item xs={12}>
          <h3 style={{ marginBottom: 0 }}>Test Details</h3>
          {/*  <hr /> */}
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Medications"
            placeholder="Enter the Medications"
            value={formik.values.medications}
            getValue={(value) => formik.setFieldValue("medications", value)}
            onBlur={() => formik.setFieldTouched("medications")}
            error={
              Boolean(formik.errors.medications) && formik.touched.medications
            }
            errorMsg={formik.errors.medications}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Diagnosis One"
            placeholder="Enter the Diagnosis One"
            value={formik.values.diagnosisOne}
            getValue={(value) => formik.setFieldValue("diagnosisOne", value)}
            onBlur={() => formik.setFieldTouched("diagnosisOne")}
            error={
              Boolean(formik.errors.diagnosisOne) && formik.touched.diagnosisOne
            }
            errorMsg={formik.errors.diagnosisOne}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Diagnosis Two"
            placeholder="Enter the Diagnosis Two"
            value={formik.values.diagnosisTwo}
            getValue={(value) => formik.setFieldValue("diagnosisTwo", value)}
            onBlur={() => formik.setFieldTouched("diagnosisTwo")}
            error={
              Boolean(formik.errors.diagnosisTwo) && formik.touched.diagnosisTwo
            }
            errorMsg={formik.errors.diagnosisTwo}
          />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={4}>
          <SelectInput
            label="HMO Happy"
            size="small"
            options={options}
            value={formik.values.hmoHappy ?? ""}
            getValue={(value) => formik.setFieldValue("hmoHappy", value)}
            onBlur={() => formik.setFieldTouched("hmoHappy")}
            error={Boolean(formik.errors.hmoHappy) && formik.touched.hmoHappy}
            errorMsg={formik.errors.hmoHappy}
          />
        </Grid>

        <Grid item xs={8}>
          <TextInput
            type="text"
            label="Reason HMO"
            placeholder="Enter the Reason HMO"
            value={formik.values.homHappyReason}
            getValue={(value) => formik.setFieldValue("homHappyReason", value)}
            onBlur={() => formik.setFieldTouched("homHappyReason")}
            error={
              Boolean(formik.errors.homHappyReason) &&
              formik.touched.homHappyReason
            }
            errorMsg={formik.errors.homHappyReason}
            multiline={true}
            rows={3}
          />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={4}>
          <SelectInput
            label="Doctor Happy"
            size="small"
            options={options}
            value={formik.values.doctorHappy ?? ""}
            getValue={(value) => formik.setFieldValue("doctorHappy", value)}
            onBlur={() => formik.setFieldTouched("doctorHappy")}
            error={
              Boolean(formik.errors.doctorHappy) && formik.touched.doctorHappy
            }
            errorMsg={formik.errors.doctorHappy}
          />
        </Grid>

        <Grid item xs={8}>
          <TextInput
            type="text"
            label="Reason Doctor"
            placeholder="Enter the Reason Doctor"
            value={formik.values.doctorHappyReason}
            getValue={(value) =>
              formik.setFieldValue("doctorHappyReason", value)
            }
            onBlur={() => formik.setFieldTouched("doctorHappyReason")}
            error={
              Boolean(formik.errors.doctorHappyReason) &&
              formik.touched.doctorHappyReason
            }
            errorMsg={formik.errors.doctorHappyReason}
            multiline={true}
            rows={3}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Comments"
            placeholder="Enter the Comments"
            value={formik.values.comments}
            getValue={(value) => formik.setFieldValue("comments", value)}
            onBlur={() => formik.setFieldTouched("comments")}
            error={Boolean(formik.errors.comments) && formik.touched.comments}
            errorMsg={formik.errors.comments}
            multiline={true}
            rows={5}
          />
        </Grid>

        {leadClassOption && (
          <>
            <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>Category Assignment</h3>
              {/*  <hr /> */}
            </Grid>

            <Grid item xs={4}>
              <SelectInput
                label="Patient Assignment"
                size="small"
                options={PAGE_PA_LIST}
                value={formik.values.patientAssignment}
                getValue={(value) =>
                  formik.setFieldValue("patientAssignment", value)
                }
                onBlur={() => formik.setFieldTouched("patientAssignment")}
                error={
                  Boolean(formik.errors.patientAssignment) &&
                  formik.touched.patientAssignment
                }
                errorMsg={formik.errors.patientAssignment}
              />
            </Grid>

            <Grid item xs={4}>
              <SelectInput
                label="Lead Class"
                size="small"
                options={LEAD_CLASS_OPTIONS}
                value={data.leadClass ?? formik.values.leadClass}
                getValue={(value) => formik.setFieldValue("leadClass", value)}
                onBlur={() => formik.setFieldTouched("leadClass")}
                error={
                  Boolean(formik.errors.leadClass) && formik.touched.leadClass
                }
                errorMsg={formik.errors.leadClass}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        >
          <PrintButton
            customerId={data._id}
            saveAndPrint={() => {
              setSaveAndPrint(true);
              formik.submitForm();
            }}
            showBlockButton
          />
        </FormButtons>
      </Box>
    </form>
  );
}

export default LeadForm;
