import API from "../../config/api";
import {
  SET_LOADING,
  SET_AUTH,
  REMOVE_AUTH,
  SET_USER,
  SHOW_MESSAGE,
} from "../constants/ActionTypes.js";

function showMessage(type, msg, dispatch) {
  dispatch({
    type: SHOW_MESSAGE,
    payload: { type, msg },
  });
}

export const setLoading = (value) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const login = (payload) => async (dispatch) => {
  try {
    console.log("login", payload);
    dispatch({ type: SET_LOADING, payload: true });

    let res = await API.post("/auth/login", payload);
    showMessage("success", "Login success. Redirecting, Please wait", dispatch);

    if (res.data.orgType && !res.data.accountType) {
      res.data.accountType = res.data.orgType;
    }

    setTimeout(() => {
      // dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_USER, payload: res.data });
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      dispatch({ type: SET_LOADING, payload: false });
      showMessage("error", "Login failed. Invalid credentials", dispatch);
    }, 1000);
  }
};

export const setUser = (data) => ({ type: SET_USER, payload: data });
export const setAuth = (data) => ({ type: SET_AUTH, payload: data });
export const removeAuth = () => ({ type: REMOVE_AUTH, payload: null });
