import { useSelector } from "react-redux";

function RolePermissionComponent(props) {
  const { user } = useSelector((state) => state.auth);

  if (user && props.roles) {
    const role = props.roles.find((role) => role === user.accountType);
    return role ? props.children : null;
  }

  return null;
}

export default RolePermissionComponent;
