import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePickerComponent from "../inputs/DatePicker";

import dayjs from "dayjs";

export default function MoveToWait({ open, handleClose, data }) {
  const [reminderDate, setReminderDate] = useState(
    data.reminderDate ?? dayjs()
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Move to Wait</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select a reminder date</DialogContentText>
        <br />

        <DatePickerComponent
          label="Select a date"
          value={reminderDate}
          getValue={(value) => setReminderDate(value)}
          onClose={() => console.log("closed")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose("cancel")}>Cancel</Button>
        <Button
          onClick={() => handleClose("save", dayjs(reminderDate).format())}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
}
