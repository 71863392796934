import React from "react";
import Swal from "sweetalert2";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import TotalComponent from "../TotalComponent";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { deleteHealthPlans } from "../../store/actions/CustomerActions";

// model
import AppModel from "../../models/AppModel";

export const HealthPlansTable = () => {
  const { loading, healthPlans } = useSelector((state) => state.customer);

  const handleDeletePlan = async (plan) => {
    const res = await Swal.fire({
      title: "Are you sure?",
      html: `Do you want to delete the plan <strong>${plan.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: `Yes, delete it!`,
    });

    if (res.isConfirmed) {
      store.dispatch(deleteHealthPlans(plan._id));
    }
  };

  return (
    <TableContainer component={Paper} sx={{ height: "520px" }}>
      <h2 style={{ textAlign: "center" }}>Health Plans</h2>

      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Plan Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            [1, 2, 3, 4, 5].map((nr) => {
              return (
                <TableRow key={nr}>
                  {[1, 2, 3].map((header, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={35}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

          {!loading &&
            healthPlans.map((plan, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  verticalAlign: "baseline",
                }}
              >
                <TableCell>{plan.name}</TableCell>
                <TableCell>{AppModel.formatDate(plan.createdAt)}</TableCell>
                <TableCell>
                  <Tooltip title="Delete the plan">
                    <IconButton
                      color="error"
                      aria-label="Edit"
                      onClick={() => handleDeletePlan(plan)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TotalComponent loading={loading} totalRecords={healthPlans.length} />
    </TableContainer>
  );
};

export default React.memo(HealthPlansTable);
