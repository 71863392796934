import {
  SET_LOADING,
  SET_VENDOR,
  SET_MODAL,
} from "../constants/ActionTypes.js";

const initialState = {
  loading: true,
  modal: {
    open: false,
  },
  totalRecords: 0,
  vendors: [],
  loadMoreOptions: {
    hasMore: false,
    skip: 100,
  },
};

const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_MODAL:
      return {
        ...state,
        modal: {
          open: action.payload,
        },
      };

    case SET_VENDOR:
      return {
        ...state,
        loading: false,
        modal: { open: false },
        vendors: action.payload.vendors,
        totalRecords: action.payload.totalRecords,
        loadMoreOptions:
          action.payload.loadMoreOptions ?? state.loadMoreOptions,
      };

    default:
      return state;
  }
};

export default VendorReducer;
