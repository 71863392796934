import { useEffect, useState } from "react";

// mui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// state
import { useSelector } from "react-redux";
import store from "../store";
import {
  saveCustomers,
  saveUserList,
  saveZipCodes,
  setLoading,
} from "../store/actions/CustomerActions";

// components
import MuiDataTable from "../components/tables/MuiDataTable";
import MainRootElement from "../components/layouts/MainRootElement";

// services
import {
  fetchUserListService,
  fetchZipcodesService,
} from "../services/CustomerServices";

// Models
import CustomerModel from "../models/CustomerModel";

function LeadGenerator() {
  const { loading } = useSelector((state) => state.customer);

  const [tableMainCategory, setTableMainCategory] = useState("NI");

  const fetchOtherDatas = async () => {
    const res = await fetchZipcodesService();
    const userList = await fetchUserListService();

    store.dispatch(saveUserList(userList));
    store.dispatch(saveZipCodes(res));
  };

  const _fetchCustomers = async (tab) => {
    const _urlParams = {
      tag: "",
      process: tab,
      filterYearStart: 0,
      filterYearEnd: 0,
      filterSearch: "",
      q: "",
      isLGPage: true,
      limit: 100,
      skip: 0,
    };

    if (!loading) store.dispatch(setLoading(true));

    const res = await CustomerModel.fetchCustomers(_urlParams);

    store.dispatch(saveCustomers(res));
  };

  useEffect(() => {
    _fetchCustomers(tableMainCategory);
    fetchOtherDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (e, v) => {
    setTableMainCategory(v);
    _fetchCustomers(v);
  };

  return (
    <MainRootElement>
      <Tabs
        value={tableMainCategory}
        onChange={handleTabChange}
        style={{ padding: "0px 10px" }}
      >
        <Tab
          label="Not Interested"
          value="NI"
          sx={{ p: 0, fontSize: "12px", mr: "5px" }}
        />
        <Tab label="In Process" value="IP" sx={{ p: 0, fontSize: "12px" }} />
      </Tabs>
      <MuiDataTable />
    </MainRootElement>
  );
}

export default LeadGenerator;
