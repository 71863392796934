import API from "../config/api";

export const fetchVendorsService = async (limit) => {
  try {
    let params = new URLSearchParams({
      limit: limit ?? 100,
      skip: 0,
      orgtype: "vendor",
    }).toString();

    const res = await API.get(`organization/list?${params}`);
    const count = await API.get(`organization/count`);

    return { total: count.data.total, vendors: res.data };
  } catch (error) {
    throw error.response;
  }
};

export const cerateNewVendorService = async (payload) => {
  try {
    const res = await API.post(`organization/create`, payload);

    return res.data;
  } catch (error) {
    console.log("cerateNewVendorService", error);
    throw error.response;
  }
};

export const updateVendorService = async (payload) => {
  try {
    const res = await API.post(`organization/update`, payload);

    return res.data;
  } catch (error) {
    console.log("updateVendorService", error);
    throw error.response;
  }
};

export const removeVendorService = async (payload) => {
  try {
    const res = await API.post(`organization/remove`, payload);

    return res.data;
  } catch (error) {
    console.log("removeVendorService", error);
    throw error.response;
  }
};
