import Stack from "@mui/material/Stack";

// components
import CancelButton from "./CancelButton";
import LoadingButtonComponent from "./LoadingButtonComponent";

function FormButtons({
  loading,
  onCancelClick,
  onSaveClick,
  disabled,
  children,
}) {
  return (
    <Stack direction="row-reverse" spacing={2}>
      <CancelButton loading={loading} handleClick={onCancelClick} />
      <LoadingButtonComponent
        loading={loading}
        handleClick={onSaveClick}
        label="Save"
        disabled={disabled}
      />
      {children && children}
    </Stack>
  );
}

export default FormButtons;
