import API from "../config/api";

export const fetchTwilioToken = async () => {
  try {
    const res = await API.get(`/twilio/token`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchBandwidthToken = async () => {
  try {
    const res = await API.post(`/bandwidth/token`);
    console.log(`res => `, res);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};
