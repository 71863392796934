import { useMemo } from "react";
import { useSelector } from "react-redux";

// MUI
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function MultiSelectSearch2(props) {
  const {
    label,
    onChange,
    options, // All available zip codes
    variant,
    defaultValue = [], // Ensure defaultValue is an array
  } = props;

  const { userList } = useSelector((state) => state.customer);

  // Extract all assigned zip codes from the userList
  const assignedZipCodes = useMemo(() => {
    return userList
      .flatMap((user) => user.zipCodes.split(",")) // Split zipCodes string into an array
      .map((zip) => zip.trim()); // Clean up extra spaces
  }, [userList]);

  // Filter options to exclude zip codes already assigned
  const filteredOptions = options.filter(
    (zip) => !assignedZipCodes.includes(zip)
  );

  return (
    <Autocomplete
      multiple
      size="small"
      fullWidth={true}
      id="zipcode-multi-select-search"
      options={filteredOptions} // Use filtered options
      defaultValue={defaultValue}
      getOptionLabel={(option) => option}
      filterOptions={
        (options, state) =>
          options
            .filter((option) =>
              option.toLowerCase().includes(state.inputValue.toLowerCase())
            )
            .slice(0, 20) // Limit the number of suggestions to 20
      }
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant ?? "standard"}
          label={label}
          placeholder={label}
        />
      )}
    />
  );
}
