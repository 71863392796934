import axios from "axios";
import store from "../store";
// import config from "./app";
// import { setToken } from "../store/actions/authAction";

// function getApi() {
//   if (process.env.REACT_APP_ENV === "DEV") {
//     // return "http://localhost:4000/api";
//     return "http://54.82.78.168/api";
//   }

//   return "/api";
// }

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// Add a request interceptor
API.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.user.token;
    config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("error", error);
    if (error.response.status !== 403) {
      return Promise.reject(error);
    }

    localStorage.clear();

    window.location.href = "/login";

    return;
    // try {
    //   const response = await API.post(`auth/token`, null);
    //   // store.dispatch(setToken(response.data.data.access_token));
    //   error.response.config.headers["Authorization"] =
    //     response.data.data.access_token;
    //   return await axios(error.response.config);
    // } catch (error_1) {
    //   return await Promise.reject(error_1);
    // }
  }
);
export default API;
