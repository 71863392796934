import { useState } from "react";

// state
import { useSelector } from "react-redux";
import store from "../../../store";
import { setBandwidth } from "../../../store/actions/AppActions";

// mui
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

// components
import NumberInput from "../../inputs/NumberInput";

const iconStyle = {
  fontSize: 18,
};

const Call = (props) => {
  const { number, iconOnly = false } = props;
  const [phoneNumber, setPhoneNumber] = useState(number);

  const handleSetPhoneNumber = (value) => {
    if (value > 5) {
      setPhoneNumber(value);
    }
  };

  // Twilio setup
  const { bandwidth } = useSelector((state) => state.app);

  const callNow = () => {
    if (!phoneNumber) return;

    store.dispatch(
      setBandwidth({
        callStatus: "Calling",
        customer: "",
        to: phoneNumber,
      })
    );
  };

  const endCall = () => {
    store.dispatch(
      setBandwidth({
        callStatus: "End",
        customer: "",
        to: "",
      })
    );
  };

  if (iconOnly) {
    return (
      <IconButton color="actionButton" aria-label="Call" onClick={callNow}>
        <CallOutlinedIcon style={iconStyle} />
      </IconButton>
    );
  }

  return (
    <>
      <div className="d-flex align-center">
        <NumberInput
          label="Call number"
          variant="outlined"
          getValue={handleSetPhoneNumber}
        />
        {bandwidth.callStatus === "Calling" ? (
          <Button
            variant="contained"
            color="error"
            onClick={endCall}
            style={{ marginLeft: 5 }}
          >
            End
          </Button>
        ) : (
          <Button
            style={{ marginLeft: 5 }}
            variant="contained"
            onClick={callNow}
          >
            Call
          </Button>
        )}
      </div>
      {bandwidth.customer ? (
        <p style={{ margin: 0 }}>
          Calling{" "}
          <strong>
            {bandwidth.customer.phone} | {bandwidth.customer.firstName}{" "}
            {bandwidth.customer.lastName}
          </strong>
        </p>
      ) : null}
    </>
  );
};

export default Call;
