import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateRangeIcon from "@mui/icons-material/DateRange";

export default function DatePickerResponsive(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        openTo="day"
        views={["year", "month", "day"]}
        label={props.label}
        value={props.value}
        onChange={(newValue) => {
          props.getValue(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} helperText={null} size="small" />
        )}
        onClose={props.onClose}
        disabled={props.disabled ?? false}
      />
    </LocalizationProvider>
  );
}
