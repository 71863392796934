import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

// reducers
import {
  AppReducer,
  AuthReducer,
  CustomerReducer,
  VendorReducer,
  ReportReducer,
} from "./reducers";

const persistConfig = {
  key: "medicentric",
  storage: storage,
  version: 0,
  blacklist: ["app", "customer", "vendor", "report"],
};

const rootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  customer: CustomerReducer,
  vendor: VendorReducer,
  report: ReportReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export default store;
