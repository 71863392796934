import { useEffect, useState } from "react";
// mui
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// state
import { useSelector } from "react-redux";
import store from "../store";
import {
  resetUrlParams,
  saveCustomers,
  saveUserList,
  saveZipCodes,
  setLoading,
} from "../store/actions/CustomerActions";

// components
import MuiDataTable from "../components/tables/MuiDataTable";
import MainRootElement from "../components/layouts/MainRootElement";
import {
  fetchUserListService,
  fetchZipcodesService,
} from "../services/CustomerServices";
import CustomerModel from "../models/CustomerModel";

function Closer() {
  const [tableSubCategory, setTableSubCategory] = useState("all");

  useEffect(() => {
    store.dispatch(resetUrlParams());
  }, []);

  const { loading } = useSelector((state) => state.customer);

  const _urlParams = {
    process: "LE",
    isCloserPage: true,
    pa: tableSubCategory === "all" ? "" : tableSubCategory,
    limit: 100,
    skip: 0,
  };

  const _fetchCustomers = async () => {
    if (!loading) store.dispatch(setLoading(true));
    const res = await CustomerModel.fetchCustomers(_urlParams);
    store.dispatch(saveCustomers(res));
  };

  useEffect(() => {
    const fetchOterDatas = async () => {
      const res = await fetchZipcodesService();
      let userList = await fetchUserListService();

      const AllOption = {
        email: "All",
        fname: "",
      };

      userList.unshift(AllOption);

      store.dispatch(saveUserList(userList));
      store.dispatch(saveZipCodes(res));
    };

    fetchOterDatas();
  }, []);

  useEffect(() => {
    _fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSubCategory]);

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff", mb: 2 }}>
        <Tabs
          value={tableSubCategory}
          onChange={(e, value) => setTableSubCategory(value)}
        >
          <Tab label="All" value="all" />
          <Tab label="Home Health" value="home-health" />
          <Tab label="Hospice" value="hospice" />
          <Tab label="Clinic" value="clinic" />
          <Tab label="Signup" value="sign-up" />
          <Tab label="ADHC" value="adhc" />
          <Tab label="Pharmacy" value="pharmacy" />
        </Tabs>
      </Box>

      <Box sx={{ backgroundColor: "#fff", pl: 1 }}>
        <MuiDataTable />
      </Box>
    </MainRootElement>
  );
}

export default Closer;
