import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../config/app";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
// import DatePickerComponent from "../inputs/DatePicker";
import TextInput from "../inputs/TextInput";

import {
  PATIENT_ASSIGNMENT_LIST,
  LEAD_CLASS_OPTIONS,
} from "../../constants/lists";
import OptionSelect from "../selects/OptionSelect";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import FormCloseButton from "../buttons/FormCloseButton";
import store from "../../store";
import {
  saveCustomers,
  setFormSubmitting,
} from "../../store/actions/CustomerActions";
import CustomerModel from "../../models/CustomerModel";
import AppModel from "../../models/AppModel";

function makeRequest(conf, form_data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.baseUrl}/customer/update`, form_data, conf)
      .then(({ data }) => {
        // console.log(data);
        resolve();
      })
      .catch((ex) => {
        // console.error(ex);
        reject();
      });
  });
}

function CustomerForm({ closeForm, data, loading, handleForm }) {
  console.log("Rendering CustomerForm");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const [patientAssignment, setPatientAssignment] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { urlParams } = useSelector((state) => state.customer);

  useEffect(() => {
    if (pathname === "/closer" || pathname === "/lead") {
      setPatientAssignment(true);
      return;
    }

    if (
      pathname === "/central" &&
      searchParams.get("category") === "assign-to-vendor"
    ) {
      setPatientAssignment(true);
      return;
    }

    if (patientAssignment) {
      setPatientAssignment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitTheForm = async (values) => {
    // console.log("values", values);
    // console.log("selectedFiles", selectedFiles);

    const conf = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: user.token,
      },
    };

    var form_data = new FormData();

    for (var key in values) {
      if (values[key] !== undefined && values[key] !== null) {
        form_data.append(key, values[key]);
      }
    }

    if (selectedFiles) {
      for (let insurancedoc of selectedFiles) {
        form_data.append("insurancedoc", insurancedoc);
      }
    }

    // if (values.patientAssignment === PATIENT_ASSIGNMENT.SEND_BACK) {
    //   console.log("WT?");
    // }

    try {
      await makeRequest(conf, form_data);

      if (!loading) store.dispatch(setFormSubmitting(true));

      const res = await CustomerModel.fetchCustomers(urlParams);
      store.dispatch(saveCustomers(res));

      closeForm();
    } catch (error) {
      // console.log("error", error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: data._id,
      firstName: data.firstName ?? "",
      middleName: data.middleName ?? "",
      lastName: data.lastName ?? "",
      birthDate: AppModel.formatDate(data.birthDate) ?? "",
      phone: data.phone ?? "",
      emailAddress: data.emailAddress ?? "",
      address: data.address ?? "",
      city: data.city ?? "",
      state: data.state ?? "",
      zipCode: data.zipCode ?? "",
      patientAssignment: data.patientAssignment ?? "",
      leadClass: data.leadClass ?? "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      emailAddress: Yup.string().email("Please enter valid email"),
    }),
    onSubmit: (values) => {
      var newValues = { ...values };

      if (isEmpty(data)) {
        delete newValues._id;

        newValues = {
          ...newValues,
          processResults: "LE",
          assignedToCloserId: user._id || "",
          assignedToCloserAt: new Date(),
          assignedToCloserById: user._id,
        };

        if (pathname !== "/closer") {
          delete newValues.assignedToCloserId;
          delete newValues.assignedToCloserAt;
          delete newValues.assignedToCloserById;
        }
      }

      if (selectedFiles && selectedFiles.length > 0) {
        submitTheForm(newValues);
      } else {
        handleForm("update-customer", newValues);
      }
    },
  });

  console.log("Errors => ", formik.errors);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>
              {data.firstName} {data.lastName} Details
            </h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="First Name"
            placeholder="Please enter the first name"
            value={formik.values.firstName}
            getValue={(value) => formik.setFieldValue("firstName", value)}
            onBlur={() => formik.setFieldTouched("firstName")}
            error={Boolean(formik.errors.firstName) && formik.touched.firstName}
            errorMsg={formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Middle Name"
            placeholder="Please enter the middle name"
            value={formik.values.middleName}
            getValue={(value) => formik.setFieldValue("middleName", value)}
            onBlur={() => formik.setFieldTouched("middleName")}
            error={
              Boolean(formik.errors.middleName) && formik.touched.middleName
            }
            errorMsg={formik.errors.middleName}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Last Name"
            placeholder="Please enter the last name"
            value={formik.values.lastName}
            getValue={(value) => formik.setFieldValue("lastName", value)}
            onBlur={() => formik.setFieldTouched("lastName")}
            error={Boolean(formik.errors.lastName) && formik.touched.lastName}
            errorMsg={formik.errors.lastName}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Email"
            placeholder="Please enter the email address"
            value={formik.values.emailAddress}
            getValue={(value) => formik.setFieldValue("emailAddress", value)}
            onBlur={() => formik.setFieldTouched("emailAddress")}
            error={
              Boolean(formik.errors.emailAddress) && formik.touched.emailAddress
            }
            errorMsg={formik.errors.emailAddress}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="number"
            label="Phone Number"
            placeholder="Please enter the phone number"
            value={formik.values.phone}
            getValue={(value) => formik.setFieldValue("phone", value)}
            onBlur={() => formik.setFieldTouched("phone")}
            error={Boolean(formik.errors.phone) && formik.touched.phone}
            errorMsg={formik.errors.phone}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Date of birth"
            placeholder="Please enter the date of birth"
            value={formik.values.birthDate}
            getValue={(value) => {
              if (AppModel.formatDate(value) === "Invalid Date") {
                formik.errors.birthDate = "Please enter valid date MM/DD/YYYY";
              } else {
                delete formik.errors.birthDate;
              }

              formik.setFieldValue("birthDate", value);
            }}
            onBlur={() => formik.setFieldTouched("birthDate")}
            error={Boolean(formik.errors.birthDate) && formik.touched.birthDate}
            errorMsg={formik.errors.birthDate}
          />
          {/* <DatePickerComponent
            label="Date of birth"
            value={formik.values.birthDate}
            getValue={(value) => formik.setFieldValue("birthDate", value)}
            onClose={() => console.log("closed")}
          /> */}
        </Grid>

        <Grid item xs={12}>
          <h3 style={{ marginBottom: 0 }}>Address Details</h3>
          {/*  <hr /> */}
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Address"
            placeholder="Please enter the address"
            value={formik.values.address}
            getValue={(value) => formik.setFieldValue("address", value)}
            onBlur={() => formik.setFieldTouched("address")}
            error={Boolean(formik.errors.address) && formik.touched.address}
            errorMsg={formik.errors.address}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="City"
            placeholder="Please enter the city"
            value={formik.values.city}
            getValue={(value) => formik.setFieldValue("city", value)}
            onBlur={() => formik.setFieldTouched("city")}
            error={Boolean(formik.errors.city) && formik.touched.city}
            errorMsg={formik.errors.city}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="State"
            placeholder="Please enter the state"
            value={formik.values.state}
            getValue={(value) => formik.setFieldValue("state", value)}
            onBlur={() => formik.setFieldTouched("state")}
            error={Boolean(formik.errors.state) && formik.touched.state}
            errorMsg={formik.errors.state}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            type="text"
            label="Zipcode"
            placeholder="Please enter the zipcode"
            value={formik.values.zipCode}
            getValue={(value) => formik.setFieldValue("zipCode", value)}
            onBlur={() => formik.setFieldTouched("zipCode")}
            error={Boolean(formik.errors.zipCode) && formik.touched.zipCode}
            errorMsg={formik.errors.zipCode}
          />
        </Grid>

        {patientAssignment && (
          <>
            <Grid item xs={12} />

            <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>Others</h3>
              {/*  <hr /> */}
            </Grid>

            <Grid item xs={4}>
              <OptionSelect
                label="Patient Assignment"
                value={formik.values.patientAssignment}
                options={PATIENT_ASSIGNMENT_LIST}
                onChange={(value) =>
                  formik.setFieldValue("patientAssignment", value)
                }
              />
            </Grid>

            <Grid item xs={4}>
              <OptionSelect
                label="Lead Class"
                value={formik.values.leadClass}
                options={LEAD_CLASS_OPTIONS}
                onChange={(value) => formik.setFieldValue("leadClass", value)}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        >
          {pathname === "/closer" || pathname === "/lead" ? (
            <div className="d-flex">
              {selectedFiles.length > 0 ? (
                <p
                  style={{
                    margin: "0px 10px",
                    backgroundColor: "#ffc0cb",
                    padding: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold", padding: "0px 7px" }}>
                    {selectedFiles.length}
                  </span>
                  Files Selected
                  <CloseOutlinedIcon
                    style={{ fontSize: 17, cursor: "hand" }}
                    onClick={() => setSelectedFiles([])}
                  />
                </p>
              ) : null}

              <Button
                variant="contained"
                component="label"
                style={{ width: 100 }}
              >
                Scan
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  onChange={(e) => setSelectedFiles(e.target.files)}
                />
              </Button>
            </div>
          ) : null}
        </FormButtons>
      </Box>
    </form>
  );
}

export default CustomerForm;
