import { useSearchParams } from "react-router-dom";

// state
import { useSelector } from "react-redux";
import store from "../../../store";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

// components
import SelectSearchInput from "../../inputs/SelectSearchInput";

const StaffFilter = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);

  const { userList, loading, urlParams } = useSelector(
    (state) => state.customer
  );
  const closers = userList.filter((user) => user.accountType === "closer");

  const _handleCloserFilter = async (e, v) => {
    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = { ...urlParams, assignedToCloserId: v._id };
    let options = { ...paramsObject, assignedToCloserId: v._id };

    if (!v._id) delete options.assignedToCloserId;

    setSearchParams(options);

    const customZipCodes = newUrlParams.customZipCodes ?? null;

    const res = await fetchCustomersService(newUrlParams, customZipCodes);

    let payload = {
      ...res,
      urlParams: newUrlParams,
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  return (
    <SelectSearchInput
      label="Filter by closer"
      options={closers}
      onChange={_handleCloserFilter}
    />
  );
};

export default StaffFilter;
