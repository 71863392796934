import { useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../inputs/TextInput";
import OptionSelect from "../selects/OptionSelect";

function NewAccountForm({ loading, createAccount, accountCreated }) {
  console.log("Rendering NewAccountForm");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      confirmEmailAddress: "",
      accountType: "admin",
      password: "",
      confirmPassword: "",
      securityQuestion: "What is the city of your birth?",
      securityAnswer: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      securityAnswer: Yup.string().required("Required"),
      emailAddress: Yup.string()
        .email("Please enter valid email address")
        .required("Required"),
      confirmEmailAddress: Yup.string()
        .email("Please enter valid email address")
        .when("emailAddress", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("emailAddress")],
            "Email address is not matching"
          ),
        }),
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password not matching"
        ),
      }),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      createAccount(values);
    },
  });

  useEffect(() => {
    if (!loading && accountCreated) {
      formik.resetForm();
    }
  }, [accountCreated]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            type="text"
            label="First Name"
            placeholder="Enter the fisrt name"
            value={formik.values.firstName}
            getValue={(value) => formik.setFieldValue("firstName", value)}
            onBlur={() => formik.setFieldTouched("firstName")}
            error={Boolean(formik.errors.firstName) && formik.touched.firstName}
            errorMsg={formik.errors.firstName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6} sx={{ marginBottom: 1 }}>
          <TextInput
            type="text"
            label="last Name"
            placeholder="Enter the last name"
            value={formik.values.lastName}
            getValue={(value) => formik.setFieldValue("lastName", value)}
            onBlur={() => formik.setFieldTouched("lastName")}
            error={Boolean(formik.errors.lastName) && formik.touched.lastName}
            errorMsg={formik.errors.lastName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <OptionSelect
            label="Account Type"
            value={formik.values.accountType}
            options={[
              { value: "Admin", key: "admin" },
              { value: "Lead Generator", key: "staff" },
              { value: "Central User", key: "central-user" },
              { value: "Closer", key: "closer" },
              { value: "Manager", key: "manager" },
            ]}
            onChange={(value) => formik.setFieldValue("accountType", value)}
          />
        </Grid>

        <Grid item xs={6} />
        <Grid item xs={6}>
          <TextInput
            type="text"
            label="Email"
            placeholder="Enter the email address"
            value={formik.values.emailAddress}
            getValue={(value) => formik.setFieldValue("emailAddress", value)}
            onBlur={() => formik.setFieldTouched("emailAddress")}
            error={
              Boolean(formik.errors.emailAddress) && formik.touched.emailAddress
            }
            errorMsg={formik.errors.emailAddress}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="text"
            label="Confirm Email"
            placeholder="Re-enter the email address"
            value={formik.values.confirmEmailAddress}
            getValue={(value) =>
              formik.setFieldValue("confirmEmailAddress", value)
            }
            onBlur={() => formik.setFieldTouched("confirmEmailAddress")}
            error={
              Boolean(formik.errors.confirmEmailAddress) &&
              formik.touched.confirmEmailAddress
            }
            errorMsg={formik.errors.confirmEmailAddress}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="password"
            label="Password"
            placeholder="Enter the password"
            value={formik.values.password}
            getValue={(value) => formik.setFieldValue("password", value)}
            onBlur={() => formik.setFieldTouched("password")}
            error={Boolean(formik.errors.password) && formik.touched.password}
            errorMsg={formik.errors.password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="password"
            label="Confirm Password"
            placeholder="Enter the confirm password"
            value={formik.values.confirmPassword}
            getValue={(value) => formik.setFieldValue("confirmPassword", value)}
            onBlur={() => formik.setFieldTouched("confirmPassword")}
            error={
              Boolean(formik.errors.confirmPassword) &&
              formik.touched.confirmPassword
            }
            errorMsg={formik.errors.confirmPassword}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6} sx={{ marginTop: 1 }}>
          <OptionSelect
            label="Security Question"
            value={formik.values.securityQuestion}
            options={[
              {
                value: "What is your pet's name?",
                key: "What is your pet's name?",
              },
              {
                value: "What is the city of your birth?",
                key: "What is the city of your birth?",
              },
            ]}
            onChange={(value) =>
              formik.setFieldValue("securityQuestion", value)
            }
          />
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={8}>
          <TextInput
            type="text"
            label="Answer"
            placeholder="Your answer"
            value={formik.values.securityAnswer}
            getValue={(value) => formik.setFieldValue("securityAnswer", value)}
            onBlur={() => formik.setFieldTouched("securityAnswer")}
            error={
              Boolean(formik.errors.securityAnswer) &&
              formik.touched.securityAnswer
            }
            errorMsg={formik.errors.securityAnswer}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <LoadingButton loading={loading} variant="contained" type="submit">
          Create
        </LoadingButton>
      </Box>
    </form>
  );
}

export default NewAccountForm;
