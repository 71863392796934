import { useEffect, useState, useCallback } from "react";

// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// components
import MainRootElement from "../components/layouts/MainRootElement";
import ApproveTable from "../components/tables/ApproveTable";
import DateRangePickerComponent from "../components/inputs/DateRangePickerTwo";

// state
// import { useSelector } from "react-redux";
import store from "../store";
import { saveCustomers, setLoading } from "../store/actions/CustomerActions";
import ApprovalFilterDropdown from "../components/dropdowns/ApprovalFilterDropdown";
import InfiniteScrollComponent from "../components/InfiniteScrollComponent";
import CustomerModel from "../models/CustomerModel";
import { useSelector } from "react-redux";
import { updateApprovalService } from "../services/CustomerServices";

const tableContainerStyles = {
  width: "100%",
  height: "480px",
  overflow: "auto",
  // marginBottom: 30,
};

const saveButtonStyle = {
  position: "absolute",
  bottom: 40,
  right: 30,
  zIndex: 1,
};

function Approval() {
  const { loadMoreOptions, customers, loading, urlParams, modifiedCustomers } =
    useSelector((state) => state.customer);
  const [filterBy, setFilterBy] = useState("");
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  const _fetchCustomers = useCallback(async () => {
    if (!loading) store.dispatch(setLoading(true));

    let _urlParams = {
      limit: 100,
      skip: 0,
      pa: 1,
      approval: filterBy.toLowerCase(),
      q: "",
      approvalStartDate: filterBy === "All" ? dateRange.start : "",
      approvalEndDate: filterBy === "All" ? dateRange.end : "",
    };

    if (!_urlParams.approvalStartDate) {
      delete _urlParams.approvalStartDate;
    }

    if (!_urlParams.approvalEndDate) {
      delete _urlParams.approvalEndDate;
    }

    if (!_urlParams.approvalStartDate) {
      delete _urlParams.approvalStartDate;
    }

    const res = await CustomerModel.fetchCustomers(_urlParams);
    store.dispatch(saveCustomers(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = async () => {
    if (loading) return;

    const res = await CustomerModel.loadMoreCustomers(
      urlParams,
      loadMoreOptions,
      customers
    );

    store.dispatch(saveCustomers(res));
  };

  useEffect(() => {
    _fetchCustomers();
  }, [filterBy, dateRange, _fetchCustomers]);

  const saveApproval = async () => {
    if (modifiedCustomers.length <= 0) {
      alert("You must ✓ Approve or Cancel");
      return;
    }

    try {
      store.dispatch(setLoading(true));

      await updateApprovalService(modifiedCustomers);

      setTimeout(() => {
        store.dispatch(setLoading(false));
      }, 1000);
    } catch (error) {
      console.log("error", error);
      alert(`You don't have permission`);
      store.dispatch(setLoading(false));
    }
  };

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff", px: 1 }}>
        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ApprovalFilterDropdown
            label={filterBy === "" ? "Pending" : filterBy}
            getValue={(value) => setFilterBy(value)}
          />
          {filterBy !== "Pending" && (
            <DateRangePickerComponent
              dateRangeFilter={(start, end) => setDateRange({ start, end })}
            />
          )}
        </Box>

        {!loading ? (
          <Button
            variant="contained"
            onClick={saveApproval}
            style={saveButtonStyle}
          >
            SAVE
          </Button>
        ) : null}
      </Box>

      <div style={tableContainerStyles}>
        <InfiniteScrollComponent
          hasMore={loadMoreOptions.hasMore}
          loadMore={loadMore}
        >
          <ApproveTable />
        </InfiniteScrollComponent>
      </div>
    </MainRootElement>
  );
}

export default Approval;
