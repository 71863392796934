// MUI
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

// other
import store from "../../../store";
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";
import { setBandwidth } from "../../../store/actions/AppActions";

const iconStyle = {
  fontSize: 18,
};

const ALLOWED_PATHNAMES = ["/lead-generator", "/info-list", "/closer", "/lead"];

export default function CallButton({ customer }) {
  const pathname = useGetCurrentPathname();

  const callNow = () => {
    store.dispatch(
      setBandwidth({
        callStatus: "Calling",
        customer,
        to: customer.phone,
      })
    );
  };

  if (!ALLOWED_PATHNAMES.includes(pathname)) {
    return null;
  }

  return (
    <Tooltip title="Call">
      <IconButton color="actionButton" aria-label="Call" onClick={callNow}>
        <CallOutlinedIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  );
}
