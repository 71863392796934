import API from "../config/api";

export const fetchCustomersService = async (filterParams, zipCodes) => {
  // console.log("fetchCustomersService", filterParams);
  try {
    let params = new URLSearchParams({
      ...filterParams,
    }).toString();

    if (zipCodes) {
      params = params + "&" + zipCodes;
    }

    const res = await API.get(`customer/list?${params}`);

    let _countparams = { ...filterParams };

    delete _countparams.skip;
    delete _countparams.limit;

    params = new URLSearchParams({
      ..._countparams,
    }).toString();

    if (zipCodes) {
      params = params + "&" + zipCodes;
    }

    let count = await API.get(`customer/count?${params}`);
    let total = count.data.total;
    let customers = res.data;

    if (filterParams.isLeadPage) {
      customers = res.data.filter((customer) => customer.staffAssigned);

      const difference = res.data.length - customers.length;
      total = count.data.total - difference;
    }

    return { total, customers };
  } catch (error) {
    console.log(error);
  }
};

export const updateCustomerService = async (formData) => {
  try {
    const res = await API.post(`customer/update`, formData);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchZipcodesService = async () => {
  try {
    const res = await API.get(`zipcode/ofme`);

    if (res.data.length > 0) {
      // remove duplicates
      const zipcodes = res.data.filter(
        (item, i, array) => array.indexOf(item) === i
      );
      return zipcodes;
    }

    return [];
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserListService = async (params) => {
  try {
    let endpoint = `user/list`;

    if (params && params.deletedUsers) {
      endpoint = `user/list?deleted=true`;
    }

    const res = await API.get(endpoint);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchVendorsService = async () => {
  try {
    const res = await API.get(
      `/organization/list?orgtype=vendor&registrationConfirmed=true`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchNonConfirmedVendorsService = async () => {
  try {
    const res = await API.get(`/organization/list?orgtype=vendor`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const reverseTheOperationService = async (endpoint, payload) => {
  try {
    const res = await API.post(`/customer/${endpoint}`, payload);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateApprovalService = async (formData) => {
  try {
    const res = await API.post(`customer/update-approval`, formData);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const updateUserService = async (formData) => {
  try {
    const res = await API.post(`user/update`, formData);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const deleteUserService = async (userId) => {
  try {
    const res = await API.get(`user/delete/${userId}`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchZipcodeListcodeService = async () => {
  try {
    const res = await API.get(`zipcode/listcode`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchStaffReportService = async (params) => {
  try {
    let endPoint = "customer/staff-report";

    if (params) {
      endPoint = `customer/staff-report?start=${params.start}&end=${params.end}`;
    }

    const res = await API.get(endPoint);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchHealthPlansService = async () => {
  try {
    const res = await API.get("healthplan/list");
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const updateHealthPlansService = async (formData) => {
  try {
    const res = await API.post("healthplan/update", formData);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const deleteHealthPlansService = async (planId) => {
  try {
    const res = await API.post("healthplan/delete/" + planId);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const downloadCsvService = async (urlParams) => {
  try {
    const res = await API.get("customer/export-data-lead", {
      params: { ...urlParams },
    });
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchFilesServices = async (urlParams) => {
  try {
    const res = await API.get("files");
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const verifyLeadService = async (formData) => {
  try {
    const res = await API.post(`customer/verify`, formData);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const deleteFilesServices = async (id) => {
  try {
    const res = await API.post("files/delete/" + id);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchLogListServices = async (filterParams) => {
  try {
    let params = new URLSearchParams({
      ...filterParams,
    }).toString();

    const res = await API.get(`log/list?${params}`);
    const countRes = await API.get(`log/count-leads?${params}`);

    return { logs: res.data, counts: countRes.data };
  } catch (error) {
    throw error.response;
  }
};

export const fetchSeqNoServices = async () => {
  try {
    const res = await API.get("customer/generate-seq-no");
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const customerApproveServices = async (_id) => {
  try {
    const res = await API.post("customer/approve", { _id });
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const deleteDocService = async (customerId, docId) => {
  try {
    const res = await API.get(`customer/deletedoc/${customerId}/${docId}`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const getCustomerInfo = async (customerId) => {
  try {
    const res = await API.get(`customer/get/${customerId}`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};
