import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

function TextInput({
  error,
  errorMsg,
  value,
  label,
  placeholder,
  type,
  onBlur,
  getValue,
  variant,
  size,
  disabled,
  multiline,
  rows,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    // Clear the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to wait for the user to finish typing
    const newTypingTimeout = setTimeout(() => {
      // Do something with the final input value
      getValue(value);
    }, 1000); // Adjust the delay as needed (e.g., 1000ms = 1 second)

    // Update the timeout state
    setTypingTimeout(newTypingTimeout);

    // Update the input value
    setInputValue(value);
  };

  return (
    <>
      <p className="input-label">{label}</p>
      <TextField
        id={`text-input-${label}`}
        variant="outlined"
        size={size ?? "small"}
        type={type}
        value={inputValue}
        onBlur={onBlur}
        onChange={handleInputChange}
        error={error}
        helperText={errorMsg}
        fullWidth
        multiline={multiline ?? false}
        rows={rows}
        disabled={disabled ?? false}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
}

export default TextInput;
