import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const FormCloseButton = ({ closeForm }) => {
  return (
    <IconButton color="error" aria-label="Close" onClick={closeForm}>
      <CloseIcon style={{ fontSize: 25 }} />
    </IconButton>
  );
};

export default FormCloseButton;
