import { useEffect, useCallback, useState } from "react";

// mui
import LoadingButton from "@mui/lab/LoadingButton";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import BirthYearSelect from "../../components/selects/BirthYearSelect";
import LeadReportTable from "../../components/tables/LeadReportTable";

// state
import store from "../../store";
import {
  fetchCustomers,
  saveCustomers,
  setLoading,
} from "../../store/actions/CustomerActions";
import { useSelector } from "react-redux";
import OptionSelect from "../../components/selects/OptionSelect";

// mui
import Grid from "@mui/material/Grid";
import CustomerModel from "../../models/CustomerModel";

import Swal from "sweetalert2";
const params = {
  limit: 100,
  skip: 0,
  process: "LE",
  filterYearStart: 0,
  filterYearEnd: 0,
};

function LeadReport() {
  const [doctorHappy, setDoctorHappy] = useState("--");
  const [dateRange, setDateRange] = useState({
    filterYearStart: "",
    filterYearEnd: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const { urlParams, loading, loadMoreOptions, customers } = useSelector(
    (state) => state.customer
  );
  const { user } = useSelector((state) => state.auth);

  const fetchCustomers = async () => {
    if (!loading) store.dispatch(setLoading(true));
    console.log("fetching");
    const res = await CustomerModel.fetchCustomers({ ...params, ...dateRange });
    store.dispatch(saveCustomers(res));
  };

  const loadMore = async () => {
    if (loading) return;
    console.log("loading more");

    const res = await CustomerModel.loadMoreCustomers(
      { ...params, ...dateRange },
      loadMoreOptions,
      customers
    );
    store.dispatch(saveCustomers(res));
  };

  useEffect(() => {
    fetchCustomers();
    // store.dispatch(fetchCustomers({ ...params, ...dateRange }));
  }, [dateRange]);

  const fetchFilterResult = useCallback(
    async (doctorHappy) => {
      setDoctorHappy(doctorHappy);
      store.dispatch(fetchCustomers({ ...params, doctorHappy }));
    },
    [urlParams]
  );

  const handlePrint = useCallback((customerId) => {
    CustomerModel.printDocument(customerId, user.token);
  }, []);

  const downloadCsv = async () => {
    const params = {
      process: "LE",
      filterYearStart: dateRange.filterYearStart,
      filterYearEnd: dateRange.filterYearEnd,
      doctorHappy:
        doctorHappy === true || doctorHappy === false ? doctorHappy : undefined,
    };

    try {
      const res = await CustomerModel.downloadCsv(params);
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
      Swal.fire("Sorry, Unable to download", "", "error");
    }
  };

  return (
    <MainRootElement>
      <div style={{ backgroundColor: "#ffffff", padding: 10, marginBottom: 5 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <OptionSelect
              label="Doctor Happy"
              value={doctorHappy}
              options={[
                { value: "--", key: "--" },
                { value: "Yes", key: true },
                { value: "No", key: false },
              ]}
              onChange={fetchFilterResult}
            />
          </Grid>
          <Grid item xs={8}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p className="filter-menu-label">Year of Birth</p>
              <BirthYearSelect
                startDate={dateRange.filterYearStart}
                endDate={dateRange.filterYearEnd}
                setStartDate={(value) =>
                  setDateRange({ ...dateRange, filterYearStart: value })
                }
                setEndDate={(value) =>
                  setDateRange({ ...dateRange, filterYearEnd: value })
                }
              />
            </div>
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <LoadingButton
              loading={isDownloading}
              variant="outlined"
              onClick={downloadCsv}
            >
              Download CSV
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
      <LeadReportTable loadMore={loadMore} handlePrint={handlePrint} />
    </MainRootElement>
  );
}

export default LeadReport;
