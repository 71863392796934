import { useEffect } from "react";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { fetchCuReport } from "../../store/actions/ReportActions";

// mui
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import DateRangePickerComponent from "../../components/inputs/DateRangePickerTwo";

function SkeletonLoader() {
  return (
    <>
      <Skeleton variant="text" animation="wave" width={250} />
      <Skeleton variant="rounded" animation="wave" width={150} height={40} />
    </>
  );
}

function CardTile({ label, value, loading }) {
  return (
    <Card>
      <div style={{ paddingLeft: 25 }}>
        {loading ? (
          <div style={{ paddingBottom: 22, paddingTop: 15 }}>
            <SkeletonLoader />
          </div>
        ) : (
          <>
            <p style={{ marginBottom: 0, fontSize: 15 }}>{label}</p>
            <h3 style={{ marginTop: 0 }}>{value}</h3>
          </>
        )}
      </div>
    </Card>
  );
}

function CuReports() {
  const { cuReport, loading } = useSelector((state) => state.report);

  useEffect(() => {
    store.dispatch(fetchCuReport());
  }, []);

  return (
    <MainRootElement>
      <div style={{ backgroundColor: "#ffffff", padding: 10, marginBottom: 5 }}>
        <DateRangePickerComponent
          dateRangeFilter={(start, end) =>
            store.dispatch(fetchCuReport(start, end))
          }
        />
      </div>

      <h2 style={{ textAlign: "center" }}>CU Report</h2>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Leads sent to Closers:"
            value={cuReport.totalWithCloser}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Leads have not sent to Closers:"
            value={cuReport.totalWithoutCloser}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Have no Assignment:"
            value={cuReport.totalWithoutAssignment}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Home Health:"
            value={cuReport.totalHomeHealth}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Hospice:"
            value={cuReport.totalHospice}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Clinic:"
            value={cuReport.totalClinic}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Sign Up:"
            value={cuReport.totalSignUp}
          />
        </Grid>

        <Grid item xs={4}>
          <CardTile
            loading={loading}
            label="Remind:"
            value={cuReport.totalHavingReminder}
          />
        </Grid>
      </Grid>
    </MainRootElement>
  );
}

export default CuReports;
