import API from "../config/api";

export const reportService = async (reportType, start, end) => {
  try {
    let URL = "customer/" + reportType + "?";

    if (start) {
      URL = `${URL}start=${start}`;
    }

    if (end) {
      URL = `${URL}&end=${end}`;
    }

    const res = await API.get(URL);

    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchStaffActivitesService = async (params) => {
  try {
    const res = await API.get(`auth/activities?${params}`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};
