import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

class AppModel {
  formatDate(dateString) {
    if (dateString) {
      const dateStringArray = dateString.split("/");
      if (dateStringArray.length === 3) {
        if (dateStringArray[2] && dateStringArray[2].length < 4) {
          return "Invalid Date";
        }
      }
    }

    return !dateString ? "" : dayjs(dateString).utc().format("MM/DD/YYYY");
  }

  formatDateWithTime(dateString) {
    return !dateString ? "" : dayjs(dateString).format("HH:MM MM DD YYYY");
  }

  getYear(dateString) {
    return !dateString ? "" : dayjs(dateString).format("YYYY");
  }

  getTime(dateString) {
    return !dateString ? "" : dayjs(dateString).format("HH:MM");
  }
}

export default new AppModel();
