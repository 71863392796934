// components
import Nav from "./nav";

export default function MainRootElement(props) {
  return (
    <>
      <Nav />
      {props.children}
    </>
  );
}
