// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

export default function DeleteLeadButton({ onClick }) {
  const pathname = useGetCurrentPathname();

  if (pathname !== "/lead") {
    return null;
  }

  return (
    <Tooltip title="Delete Lead">
      <IconButton
        color="actionButton"
        aria-label="Delete Lead"
        onClick={onClick}
      >
        <DeleteRoundedIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  );
}
