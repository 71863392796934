import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../inputs/TextInput";

function ResetOrgPassword({ loading, changePassword }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password not matching"
        ),
      }),
    }),
    onSubmit: (values) => {
      changePassword(values.password);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            type="password"
            label="Password"
            placeholder="Enter the password"
            value={formik.values.password}
            getValue={(value) => formik.setFieldValue("password", value)}
            onBlur={() => formik.setFieldTouched("password")}
            error={Boolean(formik.errors.password) && formik.touched.password}
            errorMsg={formik.errors.password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="password"
            label="Confirm Password"
            placeholder="Enter the confirm password"
            value={formik.values.confirmPassword}
            getValue={(value) => formik.setFieldValue("confirmPassword", value)}
            onBlur={() => formik.setFieldTouched("confirmPassword")}
            error={
              Boolean(formik.errors.confirmPassword) &&
              formik.touched.confirmPassword
            }
            errorMsg={formik.errors.confirmPassword}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <LoadingButton loading={loading} variant="contained" type="submit">
          Reset
        </LoadingButton>
      </Box>
    </form>
  );
}

export default ResetOrgPassword;
