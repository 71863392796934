import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
import FormCloseButton from "../buttons/FormCloseButton";
import TextInput from "../inputs/TextInput";

function NewHealthPlanForm({ closeForm, loading, handleForm }) {
  console.log("Rendering NewHealthPlanForm");

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a plan name."),
    }),
    onSubmit: (values) => {
      handleForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Add New Health Plan</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Plan Name"
            placeholder="Please enter the health plan name"
            value={formik.values.name}
            getValue={(value) => formik.setFieldValue("name", value)}
            onBlur={() => formik.setFieldTouched("name")}
            error={Boolean(formik.errors.name) && formik.touched.name}
            errorMsg={formik.errors.name}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default NewHealthPlanForm;
