import { useEffect, useState } from "react";
import { BandwidthUA } from "@bandwidth/bw-webrtc-sdk";
import { useSelector } from "react-redux";

// mui
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

// others
import store from "../../../store";
import { fetchBandwidthToken } from "../../../services/TwilioServices";
import { endBandwidthCall } from "../../../store/actions/AppActions";

export default function Bandwidth() {
  const sourceNumber = 13239552225;
  const [authToken, setAuthToken] = useState("");
  const [phone, setPhone] = useState(new BandwidthUA());
  const [activeCall, setActiveCall] = useState(null);

  const [snackBarOption, setSnackBarOption] = useState({
    open: false,
    message: "Working on it.",
    severity: "info",
  });

  const { bandwidth } = useSelector((state) => state.app);

  const fetchToken = async () => {
    const token = await fetchBandwidthToken();
    setAuthToken(token);
  };

  const endCall = () => {
    store.dispatch(
      endBandwidthCall({
        callStatus: "End",
        customer: "",
        to: "",
      })
    );
  };

  useEffect(() => {
    if (bandwidth && bandwidth.callStatus === "Calling") {
      if (!activeCall) fetchToken();
    } else if (bandwidth && bandwidth.callStatus === "End") {
      if (activeCall) {
        activeCall.terminate();

        showSnakcbar({
          message: "Call ended.",
          severity: "success",
        });

        if (bandwidth.callStatus === "End" && bandwidth.to !== "") {
          console.log(`end the call => `);
          endCall();
        }
      }
    }
  }, [bandwidth]);

  useEffect(() => {
    const serverConfig = {
      domain: "gw.webrtc-app.bandwidth.com",
      addresses: ["wss://gw.webrtc-app.bandwidth.com:10081"],
      iceServers: [
        "stun.l.google.com:19302",
        "stun1.l.google.com:19302",
        "stun2.l.google.com:19302",
      ],
    };
    const newPhone = new BandwidthUA();

    newPhone.setServerConfig(
      serverConfig.addresses,
      serverConfig.domain,
      serverConfig.iceServers
    );

    newPhone.setOAuthToken(authToken);
    setPhone(newPhone);

    try {
      if (bandwidth && bandwidth.callStatus === "Calling") {
        if (newPhone.isInitialized()) {
          showSnakcbar({
            message: "Connecting, please wait.",
            severity: "info",
          });
          // setDestNumber(bandwidth.to);

          setTimeout(() => {
            console.log("Place call >>> ");
            setActiveCall(newPhone.call(bandwidth.to));
            // setActiveCall(newPhone.call(`909 610 6336`));
          }, 1000);
        }
      } else if (bandwidth && bandwidth.callStatus === "End") {
        console.log(`end`);
        if (activeCall) {
          activeCall.terminate();
          showSnakcbar({
            message: "Call ended.",
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.log(`error => `, error);
      showSnakcbar({
        message: error,
        severity: "error",
      });
    }
  }, [authToken]);

  useEffect(() => {
    phone.setListeners({
      loginStateChanged: function (isLogin, cause) {
        // eslint-disable-next-line default-case
        switch ("cause" + cause) {
          case "connected":
            console.log("phone>>> loginStateChanged: connected");
            break;
          case "disconnected":
            console.log("phone>>> loginStateChanged: disconnected");
            if (phone.isInitialized())
              // after deinit() phone will disconnect SBC.
              console.log("Cannot connect to SBC server");
            break;
          case "login failed":
            console.log("phone>>> loginStateChanged: login failed");
            break;
          case "login":
            console.log("phone>>> loginStateChanged: login");
            break;
          case "logout":
            console.log("phone>>> loginStateChanged: logout");
            break;
        }
      },

      outgoingCallProgress: function (call, response) {
        console.log("phone>>> outgoing call progress");
        showSnakcbar({
          message: "Dialing, please wait.",
          severity: "info",
        });
      },

      callTerminated: function (call, message, cause) {
        console.log(`call => `, call);
        console.log(`message => `, message);
        console.log(`phone>>> call terminated callback, cause=${cause}`);
        if (call !== activeCall) {
          console.log("terminated no active call");
          showSnakcbar({
            message: "Call terminated.",
            severity: "error",
          });

          endCall();
        } else {
          setActiveCall(null);

          console.log(`Call terminated: ${cause}`);
          console.log("call_terminated_panel");

          endCall();

          showSnakcbar({
            message: "Call terminated. Cause:" + cause,
            severity: "error",
          });
        }
      },

      callConfirmed: function (call, message, cause) {
        console.log("phone>>> callConfirmed");

        showSnakcbar({
          message: "Call answered.",
          severity: "success",
        });

        activeCall.muteAudio(false);
      },

      callShowStreams: function (call, localStream, remoteStream) {
        console.log("phone>>> callShowStreams");

        let remoteVideo = document.getElementById("remote-video-container");
        remoteVideo.srcObject = remoteStream;
      },

      incomingCall: function (call, invite) {
        console.log("phone>>> incomingCall");
        call.reject();
      },

      callHoldStateChanged: function (call, isHold, isRemote) {
        console.log(
          `phone>>> callHoldStateChanged to ${isHold ? "hold" : "unhold"}`
        );
      },
    });
  }, [phone, activeCall]);

  useEffect(() => {
    const connect = async () => {
      await phone.checkAvailableDevices();
      phone.setAccount(`+${sourceNumber}`, "Medicentric", "");
      await phone.init();
    };
    connect();
  }, [sourceNumber]);

  const showSnakcbar = (data) => {
    setSnackBarOption({ ...snackBarOption, ...data, open: true });
  };

  const closeSnakcbar = () => {
    setSnackBarOption({ ...snackBarOption, open: false });
  };

  return (
    <div className="app-container">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOption.open}
        onClose={closeSnakcbar}
        autoHideDuration={3000}
      >
        <Alert
          onClose={closeSnakcbar}
          severity={snackBarOption.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackBarOption.message}
        </Alert>
      </Snackbar>
      <video
        autoPlay
        id="remote-video-container"
        style={{ display: "none" }}
      ></video>
    </div>
  );
}
