import {
  SET_LOADING,
  SET_STAFF_REPORT,
  SET_CU_REPORT,
} from "../constants/ActionTypes.js";

import { reportService } from "../../services/ReportServices.js";

export const setLoading = (value) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const fetchStaffReport = (start, end) => async (dispatch, getState) => {
  try {
    if (getState().report.loading === false) {
      dispatch({ type: SET_LOADING, payload: true });
    }

    let customers = await reportService("staff-report", start, end);

    customers.map((customer) => {
      var c = customer;
      const total =
        c.discontinued +
        c.inProcess +
        c.lead +
        c.noAnswer +
        c.notInterested +
        c.wrongNumber;

      c.total = total;

      return c;
    });

    setTimeout(() => {
      dispatch({ type: SET_STAFF_REPORT, payload: customers });
    }, 1500);
  } catch (error) {
    console.log(error);
  }
};

export const fetchCuReport = (start, end) => async (dispatch, getState) => {
  if (getState().report.loading === false) {
    dispatch({ type: SET_LOADING, payload: true });
  }

  let reports = await reportService("cu-report", start, end);

  setTimeout(() => {
    dispatch({ type: SET_CU_REPORT, payload: reports });
  }, 1500);
};
