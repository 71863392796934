// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectInput({ label, value, getValue, options, size }) {
  return (
    <>
      <p className="input-label">{label}</p>
      <FormControl fullWidth focused size={size ?? "normal"}>
        <Select
          labelId={`${label}-simple-select-label`}
          id={`${label}-simple-select`}
          defaultValue={value}
          value={value}
          onChange={(e) => {
            getValue(e.target.value);
          }}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem key={index} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
}
