import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../inputs/TextInput";

function LoginForm({ loading, doLogin }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailAddress: "",
      password: "",
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string()
        .email("Please enter valid email address")
        .required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      doLogin(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Email"
            placeholder="Enter the email address"
            value={formik.values.emailAddress}
            getValue={(value) => formik.setFieldValue("emailAddress", value)}
            onBlur={() => formik.setFieldTouched("emailAddress")}
            error={
              Boolean(formik.errors.emailAddress) && formik.touched.emailAddress
            }
            errorMsg={formik.errors.emailAddress}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="password"
            label="Password"
            placeholder="Enter the password"
            value={formik.values.password}
            getValue={(value) => formik.setFieldValue("password", value)}
            onBlur={() => formik.setFieldTouched("password")}
            error={Boolean(formik.errors.password) && formik.touched.password}
            errorMsg={formik.errors.password}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <LoadingButton loading={loading} variant="contained" type="submit">
          Login
        </LoadingButton>
      </Box>
    </form>
  );
}

export default LoginForm;
