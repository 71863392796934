import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

// components
import DatePickerComponent from "./DatePicker";
import DatePickerResponsive from "./DatePickerResponsive";
// import CustomTextInput from "./CustomTextInput";

export default function DateRangePicker({ dateRangeFilter }) {
  const [typeTimeout, setTypeTimeout] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function submitDateRange() {
    if (!startDate || !endDate) return;

    dateRangeFilter(
      dayjs(startDate || dayjs()).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      dayjs(endDate || dayjs()).format("YYYY-MM-DDTHH:mm:ss[Z]")
    );
  }

  const handleDateInput = (key, value) => {
    if (typeTimeout) {
      clearTimeout(typeTimeout);
    }

    setTypeTimeout(
      setTimeout(() => {
        if (key === "start") {
          setStartDate(value);
        } else {
          setEndDate(value);
        }
      }, 1000)
    );
  };

  useEffect(() => {
    if (startDate || endDate) {
      submitDateRange();
    }
  }, [startDate, endDate]);

  return (
    <Box sx={{ width: 150 }}>
      <DatePickerResponsive
        label="Start"
        value={startDate}
        getValue={(value) => setStartDate(value)}
        onClose={submitDateRange}
      />

      {/* <CustomTextInput
        placeholder="Start Date"
        getValue={(value) => handleDateInput("start", value)}
      /> */}

      <div style={{ marginBottom: 10 }} />

      <DatePickerResponsive
        label="End"
        value={endDate}
        getValue={(value) => setEndDate(value)}
        onClose={submitDateRange}
      />

      {/* <CustomTextInput
        placeholder="End Date"
        getValue={(value) => handleDateInput("end", value)}
      /> */}
    </Box>
  );
}
