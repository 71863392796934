// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
// import SelectSearchInput from "../inputs/SelectSearchInput";

// state
import { useSelector } from "react-redux";
import FormCloseButton from "../buttons/FormCloseButton";

function AssignToCloserForm({ closeForm, data, loading, handleForm }) {
  console.log("Rendering AssignToCloserForm");
  const { closers } = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.auth);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assignedToCloserId: "",
    },
    validationSchema: Yup.object({
      assignedToCloserId: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      let newData = {
        _id: data._id,
        assignedToCloserId: values.assignedToCloserId || "",
        assignedToCloserAt: new Date(),
        assignedToCloserById: user._id,
      };

      handleForm("assign-to-closer", newData);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Assign to Closer</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={12}>
          {data.assignedToCloserId ? (
            <div>
              <p>Closer Already Assigned: {data.assignedToCloser.email}</p>
            </div>
          ) : (
            <div>
              <p>No Closer Assigned: Please select a closer</p>
            </div>
          )}
        </Grid>
        {/*  <Grid item xs={6}>
          <SelectSearchInput
            label="Select or change closer"
            value={formik.values._id}
            options={closers}
            onChange={(event, value) =>
              formik.setFieldValue("assignedToCloserId", value._id)
            }
          />
          <p style={{ color: "#f00", margin: 0, paddingLeft: 3 }}>
            <small>{formik.errors.assignedToCloserId}</small>
          </p>
        </Grid> */}
        <Grid item xs={6}>
          <select
            style={{ width: "180%" }}
            className="select"
            onChange={(e) => {
              // alert(e.target.value);
              formik.setFieldValue("assignedToCloserId", e.target.value);
            }}
          >
            <option value=""></option>
            {closers &&
              closers.map((closer) => (
                <option
                  key={closer._id}
                  value={closer._id}
                  selected={
                    closer._id === formik.values.assignedToCloserId
                      ? true
                      : false
                  }
                >
                  {closer.fname} {closer.lname} | {closer.email}
                </option>
              ))}
          </select>
          {formik.errors.assignedToCloserId && (
            <p style={{ color: "#f00", margin: 0, paddingLeft: 3 }}>
              <small>{formik.errors.assignedToCloserId}</small>
            </p>
          )}
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default AssignToCloserForm;
