import { useSearchParams } from "react-router-dom";

// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

// state
import { useSelector } from "react-redux";
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

export default function AssignVendorButton({ customer, openModal }) {
  const [searchParams] = useSearchParams();
  const pathname = useGetCurrentPathname();
  const { user } = useSelector((state) => state.auth);

  const shouldShowButton =
    pathname === "/central" &&
    !["closer", "vendor"].includes(user.accountType) &&
    searchParams.get("category") !== "vendor-assigned";

  if (!shouldShowButton) {
    return null;
  }

  return (
    <Tooltip title="Assign">
      <IconButton
        color="actionButton"
        aria-label="Assign"
        onClick={() => openModal("assign-to-closer", customer, "xs")}
      >
        <AssignmentIndIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  );
}
