import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import RolePermissionComponent from "../../permissions/RolePermissionComponent";
import { clearCustomers } from "../../../store/actions/CustomerActions";
import store from "../../../store";

const defaultStyles = {
  fontSize: "12px",
  fontWeight: 400,
  mb: "23px",
};

export default function CustomMenu(props) {
  const { label, url, roles } = props;

  const navigate = useNavigate();

  return (
    <RolePermissionComponent roles={roles}>
      {url !== "/" ? (
        <Button
          onClick={() => {
            store.dispatch(
              clearCustomers(() => {
                // console.log(`callBack called => `);
                navigate(url);
              })
            );
          }}
          sx={{
            mb: defaultStyles.mb,
            display: "block",
            fontSize: defaultStyles.fontSize,
            fontWeight: defaultStyles.fontWeight,
          }}
        >
          {label}
        </Button>
      ) : (
        <Button
          onClick={() => {
            navigate(url);
          }}
          sx={{
            mb: defaultStyles.mb,
          }}
        >
          <HomeIcon fontSize="small" />
        </Button>
      )}
    </RolePermissionComponent>
  );
}
