import React from "react";
import Swal from "sweetalert2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppModel from "../../models/AppModel";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const columns = [
  {
    key: "name",
    name: "File Name",
    fieldName: "name",
    minWidth: 300,
    maxWidth: 300,
  },
  {
    key: "tag",
    name: "Tab Name",
    fieldName: "tag",
    minWidth: 200,
    maxWidth: 200,
  },
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: "Uploaded At",
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: "process",
    name: "Process",
    fieldName: "process",
    minWidth: 300,
    maxWidth: 300,
  },
  {
    key: "status",
    name: "Status",
    fieldName: "status",
    minWidth: 100,
    maxWidth: 100,
  },
  /*  {
    key: "delete",
    name: "",
    minWidth: 100,
    maxWidth: 100,
  }, */
];

export default function ImportedFilesTable(props) {
  const deleteFile = async (file) => {
    const res = await Swal.fire({
      title: "Are you sure?",
      html: `Do you want to delete <strong>${file.name.toLowerCase()}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: `Confirm`,
    });

    if (res.isConfirmed) {
      // confirmed
      props.handleDelete(file._id);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key} style={{ minWidth: column.minWidth }}>
                {column.name}
              </TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.files.length > 0 &&
            props.files.map((file) => (
              <TableRow key={file._id}>
                {columns.map((column, index) => {
                  const value = file[column.key];

                  if (column.key === "name") {
                    return (
                      <TableCell>
                        <a
                          href={`/upload-files/${file.name}`}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          {file.name}
                        </a>
                      </TableCell>
                    );
                  }

                  if (column.key === "createdAt") {
                    return (
                      <TableCell key={index}>
                        {AppModel.formatDateWithTime(value)}
                      </TableCell>
                    );
                  }

                  return <TableCell key={index}>{value}</TableCell>;
                })}

                <TableCell>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      aria-label="Delete"
                      onClick={() => deleteFile(file)}
                    >
                      <DeleteForeverIcon style={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
