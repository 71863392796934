import { useEffect, useState, useCallback } from "react";

// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import HealthPlansTable from "../../components/tables/HealthPlansTable";

// state
import store from "../../store";
import { setModal } from "../../store/actions/AppActions";
import { fetchHealthPlans } from "../../store/actions/CustomerActions";
import SmallFormModalComponent from "../../components/modals/SmallFormModalComponent";

function HealthPlans() {
  useEffect(() => {
    store.dispatch(fetchHealthPlans());
  }, []);

  const [modalOptions, setModalOptions] = useState({
    open: false,
    for: "customer-form",
    data: {},
    size: "md",
  });

  const handleOpen = useCallback(() => {
    setModalOptions({
      open: true,
      for: "new-health-plan",
      data: {},
      size: "xs",
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalOptions({ open: false, for: "", data: {} });
  }, []);

  return (
    <MainRootElement>
      <Box sx={{ width: "100%", backgroundColor: "#fff", p: 1, mb: 1 }}>
        <Button variant="contained" onClick={handleOpen}>
          Add a plan
        </Button>
      </Box>

      <HealthPlansTable openModal={handleOpen} />

      {modalOptions.for === "new-health-plan" && modalOptions.open ? (
        <SmallFormModalComponent
          modalOptions={modalOptions}
          closeModal={closeModal}
        />
      ) : null}
    </MainRootElement>
  );
}

export default HealthPlans;
