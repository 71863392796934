import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// components
import FormButtons from "../buttons/FormButtons";
import TextInput from "../inputs/TextInput";

// others
import {
  LEAD_CATEGORY_LIST,
  PATIENT_ASSIGNMENT_LIST,
} from "../../constants/lists";
// import DatePickerComponent from "../inputs/DatePicker";
import { truncate } from "lodash";
import { fetchSeqNoServices } from "../../services/CustomerServices";
import FormCloseButton from "../buttons/FormCloseButton";

function AssignCategoryForm({ closeForm, data, loading, handleForm }) {
  console.log("Rendering AssignCategoryForm");
  const [loadingTwo, setLoadingTwo] = useState(loading);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: data._id,
      processResults: "",
      patientAssignment: "",
      reminderDate: "",
      comments: data.comments,
      sequentialLeadNumber: "",
    },
    validationSchema: Yup.object({
      processResults: Yup.string().required("Required"),
      patientAssignment: Yup.string().when("processResults", {
        is: "LE",
        then: Yup.string().required("Select an option."),
      }),
      // reminderDate: Yup.string().when("processResults", {
      //   is: "NA",
      //   then: Yup.string().required("Select an option."),
      // }),
    }),
    onSubmit: (values) => {
      setLoadingTwo(true);
      handleForm("assign-category", { ...data, ...values });
    },
  });

  useEffect(() => {
    const fetchSeqNo = async () => {
      setLoadingTwo(true);

      const res = await fetchSeqNoServices();

      !formik.values.sequentialLeadNumber &&
        formik.setFieldValue("sequentialLeadNumber", res.number);

      setLoadingTwo(false);
    };

    !formik.values.sequentialLeadNumber &&
      formik.values.processResults === "LE" &&
      fetchSeqNo();
  }, [formik.values.patientAssignment]);

  return (
    <form onSubmit={formik.handleSubmit} className="bahahaha">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Assign a category</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Select a category
            </FormLabel>

            {formik.errors.processResults && (
              <p style={{ color: "#f00" }}>{formik.errors.processResults}</p>
            )}

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={formik.values.processResults}
              onChange={(e) =>
                formik.setFieldValue("processResults", e.target.value)
              }
            >
              {LEAD_CATEGORY_LIST.map((processResults, index) => (
                <FormControlLabel
                  key={index}
                  value={processResults.key}
                  control={<Radio />}
                  label={processResults.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {formik.values.processResults === "LE" && (
            <>
              <h5>Sequential Lead Number</h5>
              <TextInput
                type="text"
                label=""
                placeholder="Enter the Comments"
                value={data.seqNumber ?? formik.values.sequentialLeadNumber}
                getValue={(value) => formik.setFieldValue("comments", value)}
                onBlur={() => formik.setFieldTouched("comments")}
                error={
                  Boolean(formik.errors.comments) && formik.touched.comments
                }
                errorMsg={formik.errors.comments}
                disabled={truncate}
              />

              <br />
              <br />

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Select a lead
                </FormLabel>
                {formik.errors.patientAssignment && (
                  <p style={{ color: "#f00" }}>
                    {formik.errors.patientAssignment}
                  </p>
                )}
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formik.values.patientAssignment}
                  onChange={(e) =>
                    formik.setFieldValue("patientAssignment", e.target.value)
                  }
                >
                  {PATIENT_ASSIGNMENT_LIST.map((category, index) => (
                    <FormControlLabel
                      key={index}
                      value={category.key}
                      control={<Radio />}
                      label={category.value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          )}

          {/* {(formik.values.processResults === "NA" ||
            formik.values.processResults === "IP") && (
            <>
              <h3>Reminder Date</h3>
              {formik.errors.reminderDate && (
                <p style={{ color: "#f00" }}>{formik.errors.reminderDate}</p>
              )}
              <DatePickerComponent
                label="Select A Date"
                value={formik.values.reminderDate}
                getValue={(value) =>
                  formik.setFieldValue("reminderDate", value)
                }
                onClose={() => console.log("closed")}
              />
            </>
          )} */}

          <br />
          <br />

          {formik.values.processResults === "IP" && (
            <TextInput
              type="text"
              label="Comments"
              placeholder="Enter the Comments"
              value={formik.values.comments}
              getValue={(value) => formik.setFieldValue("comments", value)}
              onBlur={() => formik.setFieldTouched("comments")}
              error={Boolean(formik.errors.comments) && formik.touched.comments}
              errorMsg={formik.errors.comments}
              multiline={true}
              rows={7}
            />
          )}
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loadingTwo}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default AssignCategoryForm;
