import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";

import AppModel from "../../models/AppModel";

const columns = [
  {
    id: "createdAt",
    label: "Date",
    minWidth: 170,
    format: (value) => AppModel.formatDate(value),
  },
  {
    id: "createdAt",
    label: "Time",
    minWidth: 170,
    format: (value) => AppModel.getTime(value),
  },
  {
    id: "type",
    label: "Action",
    minWidth: 170,
    align: "left",
    format: (value) => value,
  },
  {
    id: "user",
    label: "Staff",
    minWidth: 170,
    align: "left",
    format: (user) => `${user.fname} ${user.lname}`,
  },
  {
    id: "user",
    label: "Email",
    minWidth: 170,
    align: "left",
    format: (user) => user.email,
  },
  {
    id: "user",
    label: "Role",
    minWidth: 170,
    align: "left",
    format: (user) => user.accountType,
  },
  {
    id: "user",
    label: "Status",
    minWidth: 170,
    align: "left",
    format: (user) => (user.deleted ? "DELETED" : "ACTIVE"),
  },
];

export default function StaffActivitiesTable({ loading, data }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {loading ? (
                    <Skeleton variant="rounded" height={30} />
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((arr, arIndex) => (
                <TableRow tabIndex={-1} key={arIndex}>
                  {columns.map((column, index) => {
                    return (
                      <TableCell key={index} align={column.align}>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}

            {!loading &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  return (
                    <TableRow hover tabIndex={-1} key={rowIndex}>
                      {columns.map((column, index) => {
                        const value = row[column.id];

                        return (
                          <TableCell key={index} align={column.align}>
                            {column.format(value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
