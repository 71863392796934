import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
import MultiSelectSearch2 from "../inputs/MultiSelectSearch2";
import TextInput from "../inputs/TextInput";
import FormCloseButton from "../buttons/FormCloseButton";

function EditUserForm({ closeForm, data, loading, handleForm }) {
  const listCodes = useSelector((state) => state.customer.listCodes);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: data._id,
      zipCodes: data.zipCodes ? data.zipCodes.split(",") : [],
      phonecall: data.phonecall ?? "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      let newValues = {
        ...values,
        zipCodes: values.zipCodes.length > 0 ? values.zipCodes.toString() : "",
      };

      handleForm(newValues);
    },
  });

  const onChange = (event, values, reason) => {
    formik.setFieldValue("zipCodes", values);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Zip code assignment</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Name"
            placeholder="Please enter the first name"
            value={data.fname + " " + data.lname}
            getValue={(value) => formik.setFieldValue("firstName", value)}
            onBlur={() => formik.setFieldTouched("firstName")}
            error={Boolean(formik.errors.firstName) && formik.touched.firstName}
            errorMsg={formik.errors.firstName}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Email"
            placeholder="Please enter the first name"
            value={data.email}
            getValue={(value) => formik.setFieldValue("firstName", value)}
            onBlur={() => formik.setFieldTouched("firstName")}
            error={Boolean(formik.errors.firstName) && formik.touched.firstName}
            errorMsg={formik.errors.firstName}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Phone Number"
            placeholder="Please enter the twilio Phone Number"
            value={formik.values.phonecall}
            getValue={(value) => formik.setFieldValue("phonecall", value)}
            onBlur={() => formik.setFieldTouched("phonecall")}
            error={Boolean(formik.errors.phonecall) && formik.touched.phonecall}
            errorMsg={formik.errors.phonecall}
          />
        </Grid>

        <Grid item xs={12}>
          <p className="input-label" style={{ marginBottom: 5 }}>
            Zipcodes
          </p>
          <MultiSelectSearch2
            label=""
            variant="outlined"
            options={listCodes}
            defaultValue={formik.values.zipCodes}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default EditUserForm;
