import { useEffect } from "react";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import DateRangePickerComponent from "../../components/inputs/DateRangePickerTwo";
import StaffReportTable from "../../components/tables/StaffReportTable";

// state
import store from "../../store";
import { fetchStaffReport } from "../../store/actions/ReportActions";

function LgReport() {
  useEffect(() => {
    store.dispatch(fetchStaffReport());
  }, []);

  return (
    <MainRootElement>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          backgroundColor: "#ffffff",
          padding: 10,
          marginBottom: 5,
        }}
      >
        <DateRangePickerComponent
          dateRangeFilter={(start, end) =>
            store.dispatch(fetchStaffReport(start, end))
          }
        />
      </div>

      <StaffReportTable />
    </MainRootElement>
  );
}

export default LgReport;
