import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const allOption = {
  accountType: "",
  createdAt: "",
  email: "All",
  fname: "All",
  ipAddress: "",
  lname: "",
  managerId: null,
  noOfLeads: 0,
  noOfZipCodes: 0,
  numberOfCustomer: 1,
  officeId: "",
  password: "",
  phonecall: "",
  registrationConfirmed: "",
  securityAnswer: "",
  securityQuestion: "",
  securityUUID: "b",
  temporary: "",
  updatedAt: "",
  zipCodes: "",
  __v: 0,
  _id: "",
};

export default function SelectSearchInput(props) {
  const { label, onChange, options } = props;

  let newOption = [];

  if (options.length > 0) newOption = [...options];

  newOption.unshift(allOption);

  return (
    <Autocomplete
      disablePortal
      size="small"
      defaultValue={newOption[0]}
      options={newOption}
      getOptionLabel={(option) => option.fname}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
