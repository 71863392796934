import { useEffect } from "react";

// state
import { useSelector } from "react-redux";
import store from "../store";
import { fetchCustomers } from "../store/actions/CustomerActions";

// components
import MuiDataTable from "../components/tables/MuiDataTable";
import MainRootElement from "../components/layouts/MainRootElement";

function ImportedData() {
  const { defaultFilterParams } = useSelector((state) => state.customer);

  const _fetchCustomers = async () => {
    store.dispatch(
      fetchCustomers({
        ...defaultFilterParams,
        tag: "61fb3bbc630c938844063242",
      })
    );
  };

  useEffect(() => {
    _fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainRootElement>
      <MuiDataTable />
    </MainRootElement>
  );
}

export default ImportedData;
