import React from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";

// mui
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

// state
import { useSelector } from "react-redux";

import AppModel from "../../../models/AppModel";
import _ from "lodash";
import {
  LEAD_CATEGORY_LIST,
  PATIENT_ASSIGNMENT_LIST,
} from "../../../constants/lists";

import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

// twilio
import PrintButton from "./PrintButton";
import ReverseAssignButton from "./ReverseAssignButton";
import AssignVendorButton from "./AssignVendorButton";
import VerifyVendorButton from "./VerifyVendorButton";
import CallButton from "./CallButton";
import VerifyLeadButton from "./VerifyLeadButton";
import EditCustomerButton from "./EditCustomerButton";
import ChangeCategoryButton from "./ChangeCategoryButton";
import { useLocation } from "react-router";
import DeleteLeadButton from "./DeleteLeadButton";
import { updateCustomerService } from "../../../services/CustomerServices";
import store from "../../../store";
import { removeCustomerFromState } from "../../../store/actions/CustomerActions";
// import Call from "../../layouts/components/Call";

const TableCellStyle = {
  paddingTop: 0,
  paddingRight: 25,
  paddingBottom: 0,
  paddingLeft: 1,
  border: "none",
};

const tableBodyTitleStyle = {
  color: "#484848",
  fontSize: "0.830rem",
  padding: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function TableContent({ openModal, children }) {
  const location = useLocation();
  const pathname = useGetCurrentPathname();

  let colSpan = 0;

  switch (pathname) {
    case "/lead":
      colSpan = 16;
      break;

    case "/central":
      colSpan = 13;
      break;

    case "/closer":
      colSpan = 17;
      break;

    default:
      break;
  }

  const { customers } = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.auth);

  function openLeadForm(customer, e) {
    if (e === undefined) return;

    if (
      !pathname.includes("lead-generator") &&
      !pathname.includes("info-list")
    ) {
      openModal("lead-form", customer, "lg");
    }
  }

  function isAtcTab() {
    if (location && location.search) {
      if (location.search.includes("tab=atc")) return true;
    }

    return false;
  }

  const deleteLead = React.useCallback(async (customerId) => {
    const result = await Swal.fire({
      title: "Would you like to delete this lead?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;

    try {
      // API call
      await updateCustomerService({
        _id: customerId,
        deleted: true,
      });

      store.dispatch(removeCustomerFromState(customerId));
      Swal.fire("Success", "Lead deleted", "success");
    } catch (error) {
      Swal.fire("Failed", "Lead not deleted", "error");
    }
  }, []);

  return (
    <TableBody>
      {customers.length <= 0 && (
        <TableRow>
          <TableCell
            colspan="10"
            style={{
              "text-align": "center",
              padding: "100px 0px",
              border: "none",
              fontWeight: "bold",
            }}
          >
            No Data
          </TableCell>
        </TableRow>
      )}

      {customers.map((customer, index) => {
        // console.log("rendering rows");
        let bgcolor = "";

        if (pathname === "/lead" || pathname === "/closer") {
          let diffDays = 0;

          if (customer.assignedToCloserId && customer.assignedToCloserAt) {
            diffDays = dayjs(new Date()).diff(
              dayjs(customer.assignedToCloserAt),
              "days"
            );
          }

          if (diffDays >= 7) bgcolor = "rgb(255,204,0)";
          else if (!customer.medicareNumber) bgcolor = "rgb(255,192,203)";
          else if (customer.lastServedVendorId) bgcolor = "rgb(194,229,211)";
        }

        return (
          <React.Fragment key={index}>
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ backgroundColor: bgcolor }}
              hover
              onClick={() => openLeadForm(customer)}
            >
              <TableCell
                style={{ ...TableCellStyle, textAlign: "center" }}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={tableBodyTitleStyle}>
                  {customer.seqNumber || customer.sequentialLeadNumber}
                </Typography>
              </TableCell>

              <TableCell
                style={{ ...TableCellStyle, maxWidth: 150 }}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 250 }}>
                  {customer.firstName}
                </Typography>
              </TableCell>

              {pathname !== "/closer" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  {customer.middleName ? (
                    <Tooltip
                      title={
                        customer && customer.middleName
                          ? customer.middleName
                          : ""
                      }
                      placement="top"
                    >
                      <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 30 }}>
                        {customer.middleName}
                      </Typography>
                    </Tooltip>
                  ) : null}
                </TableCell>
              ) : null}

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                  {customer.lastName}
                </Typography>
              </TableCell>

              {pathname === "/lead" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  {customer.insurancedoc && customer.insurancedoc.length > 0 ? (
                    <IconButton
                      aria-label="view"
                      size="small"
                      onClick={() => openModal("show-docs", customer, "xs")}
                    >
                      <VisibilityRoundedIcon style={{ fontSize: 17 }} />
                    </IconButton>
                  ) : (
                    <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                      N/A
                    </Typography>
                  )}
                </TableCell>
              ) : null}

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={tableBodyTitleStyle}>
                  {AppModel.formatDate(customer.birthDate)}
                </Typography>
              </TableCell>

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={tableBodyTitleStyle}>
                  {customer.phone}
                </Typography>
              </TableCell>

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 200 }}>
                  {customer.address}
                </Typography>
              </TableCell>

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 100 }}>
                  {customer.city}
                </Typography>
              </TableCell>

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography
                  style={{
                    maxWidth: 45,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "#484848",
                    fontSize: "0.830rem",
                  }}
                >
                  {customer.state}
                </Typography>
              </TableCell>

              <TableCell
                style={TableCellStyle}
                onClick={(e) => openLeadForm(customer, e)}
              >
                <Typography sx={{ ...tableBodyTitleStyle, maxWidth: 70 }}>
                  {customer.zipCode}
                </Typography>
              </TableCell>

              <TableCell style={TableCellStyle}>
                {/* Actions */}
                <Stack direction="row">
                  {children}

                  <VerifyLeadButton customer={customer} />
                  <EditCustomerButton
                    customer={customer}
                    openModal={openModal}
                  />
                  <ChangeCategoryButton
                    customer={customer}
                    openModal={openModal}
                  />
                  <ReverseAssignButton customer={customer} />
                  <AssignVendorButton
                    customer={customer}
                    openModal={openModal}
                  />
                  <VerifyVendorButton customer={customer} />
                  <PrintButton customerId={customer._id} />
                  <CallButton customer={customer} />

                  {user.accountType === "admin" ? (
                    <DeleteLeadButton
                      onClick={() => deleteLead(customer._id)}
                    />
                  ) : null}
                </Stack>
              </TableCell>

              {pathname === "/central" ||
              pathname === "/lead" ||
              pathname === "/closer" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  {pathname === "/central" ? (
                    <div>
                      {customer.reminderDate && (
                        <div
                          style={{
                            marginBottom: 5,
                            color: "red",
                            fontSize: 12,
                            minWidth: "75px",
                          }}
                        >
                          <div>{"Due date: "}</div>
                          <div>
                            {AppModel.formatDate(customer.reminderDate)}
                          </div>
                        </div>
                      )}
                      <button
                        className="btn-wait"
                        onClick={() => openModal("move-to-wait", customer)}
                      >
                        {customer.reminderDate ? "Update" : "Wait"}
                      </button>
                    </div>
                  ) : null}

                  {pathname === "/central" ||
                  pathname === "/lead" ||
                  pathname === "/closer" ? (
                    <button className="btn-open">
                      {customer.status !== "deleted" ? "Open" : "Deleted"}
                    </button>
                  ) : null}
                </TableCell>
              ) : null}

              {pathname === "/info-list" || pathname === "/lead-generator" ? (
                <TableCell
                  sx={{
                    paddingTop: 0,
                    paddingRight: 3,
                    paddingBottom: 0,
                    paddingLeft: 1,
                    border: "none",
                  }}
                >
                  <div style={{ fontSize: 12, width: 170 }}>
                    <b>
                      {_.get(
                        LEAD_CATEGORY_LIST.find(
                          (opt) => opt.key === customer.processResults
                        ),
                        "value",
                        ""
                      )}
                    </b>
                    {customer.changedProcessAt &&
                      ` (${AppModel.formatDate(customer.changedProcessAt)})`}
                  </div>
                </TableCell>
              ) : null}

              {pathname === "/info-list" || pathname === "/lead-generator" ? (
                <TableCell
                  component="td"
                  sx={{
                    paddingTop: 0,
                    paddingRight: 3,
                    paddingBottom: 0,
                    paddingLeft: 1,
                    border: "none",
                  }}
                >
                  {customer && customer.comments ? (
                    <Tooltip
                      title={
                        customer && customer.comments ? customer.comments : ""
                      }
                      placement="top"
                    >
                      <Typography
                        style={{
                          maxWidth: 200,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "#484848",
                          fontSize: "0.830rem",
                        }}
                      >
                        {customer.comments}
                      </Typography>
                    </Tooltip>
                  ) : null}
                </TableCell>
              ) : null}

              {pathname === "/info-list" || pathname === "/lead-generator" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <Typography sx={tableBodyTitleStyle}>
                    {AppModel.formatDate(customer.reminderDate)}
                  </Typography>
                </TableCell>
              ) : null}

              {pathname === "/lead-generator" || pathname === "/lead" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <Typography sx={{ ...tableBodyTitleStyle, width: 110 }}>
                    {AppModel.formatDate(customer.importDate)}
                  </Typography>
                </TableCell>
              ) : null}

              {pathname === "/closer" || pathname === "/central" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <div style={{ fontSize: 12, width: 170 }}>
                    <b>
                      {_.get(
                        LEAD_CATEGORY_LIST.find(
                          (opt) => opt.key === customer.processResults
                        ),
                        "value",
                        ""
                      )}
                    </b>
                    {customer.changedProcessAt &&
                      ` (${AppModel.formatDate(customer.changedProcessAt)})`}
                  </div>
                </TableCell>
              ) : null}

              {pathname === "/central" && isAtcTab() ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <Typography sx={{ ...tableBodyTitleStyle, width: 110 }}>
                    {AppModel.formatDate(customer.assignedToCloserAt)}
                  </Typography>
                </TableCell>
              ) : null}

              {/* Lead Created */}
              {pathname === "/lead" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <Typography sx={{ ...tableBodyTitleStyle, width: 110 }}>
                    {AppModel.formatDate(customer.updatedAt)}
                  </Typography>
                </TableCell>
              ) : null}

              {pathname === "/info-list" ||
              pathname === "/lead-generator" ||
              pathname === "/central" ||
              pathname === "/closer" ||
              pathname === "/lead" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <Typography sx={tableBodyTitleStyle}>
                    {`${
                      customer.staffAssigned && customer.staffAssigned.fname
                        ? customer.staffAssigned.fname
                        : ""
                    } ${
                      customer.staffAssigned && customer.staffAssigned.lname
                        ? customer.staffAssigned.lname
                        : ""
                    }`}
                  </Typography>
                </TableCell>
              ) : null}

              {pathname === "/closer" || pathname === "/lead" ? (
                <TableCell
                  style={TableCellStyle}
                  onClick={(e) => openLeadForm(customer, e)}
                >
                  <Typography sx={tableBodyTitleStyle}>
                    {`${
                      customer.assignedToCloserBy &&
                      customer.assignedToCloserBy.fname
                        ? customer.assignedToCloserBy.fname
                        : ""
                    } ${
                      customer.assignedToCloserBy &&
                      customer.assignedToCloserBy.lname
                        ? customer.assignedToCloserBy.lname
                        : ""
                    }`}
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>

            {pathname === "/lead" ||
            pathname === "/central" ||
            pathname === "/closer" ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{ backgroundColor: bgcolor }}
                hover
                onClick={(e) => openLeadForm(customer, e)}
              >
                <TableCell
                  colSpan={colSpan}
                  style={{ color: "#0078d7", padding: "5px" }}
                >
                  {customer.patientAssignment ? (
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                      {_.get(
                        PATIENT_ASSIGNMENT_LIST.find(
                          (opt) => opt.key === customer.patientAssignment
                        ),
                        "value",
                        ""
                      )}
                    </div>
                  ) : null}

                  {customer.leadCategory && (
                    <div style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: "bold", fontSize: 12 }}>
                        {_.get(
                          LEAD_CATEGORY_LIST.find(
                            (opt) => opt.key === customer.leadCategory
                          ),
                          "value",
                          ""
                        )}
                      </span>
                      {customer.closerRemind && (
                        <span>
                          {" - Remind at "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {AppModel.formatDate(customer.closerRemind)}
                          </span>
                        </span>
                      )}
                    </div>
                  )}

                  {pathname === "/central" && customer.assignedToCloser && (
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {`Closer: ${customer.assignedToCloser.fname} ${customer.assignedToCloser.lname} | ${customer.assignedToCloser.email}`}
                    </div>
                  )}

                  {user.accountType !== "vendor" &&
                    customer.assignedToVendor && (
                      <div style={{ fontWeight: "600", fontSize: 12 }}>
                        {`Vendor: ${customer.assignedToVendor.name} | ${customer.assignedToVendor.contactEmail}`}
                      </div>
                    )}

                  <div>
                    <i>{customer.comments || "--No comments--"}</i>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}

export default React.memo(TableContent);
