import React from "react";
import Swal from "sweetalert2";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import TotalComponent from "../TotalComponent";

import { VENDOR_TABLE_HEADERS } from "../../constants/lists";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { removeVendor } from "../../store/actions/VendorActions";

export const VendorTable = ({ openModal }) => {
  console.log("Rendering Vendor Table");
  const { loading, vendors, totalRecords } = useSelector(
    (state) => state.vendor
  );

  const handleDeletePlan = async (vendor) => {
    const res = await Swal.fire({
      title: "Are you sure?",
      html: `Do you want to delete the vendor <strong>${vendor.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: `Yes, remove it!`,
    });

    if (res.isConfirmed) {
      store.dispatch(removeVendor({ _id: vendor._id }));
    }
  };

  return (
    <TableContainer component={Paper} className="vendor-table">
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            {VENDOR_TABLE_HEADERS.map((header) => (
              <TableCell key={header.key}>{header.value}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((nr) => {
              return (
                <TableRow key={nr}>
                  {VENDOR_TABLE_HEADERS.map((header, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={35}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

          {!loading &&
            vendors.map((vendor, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {VENDOR_TABLE_HEADERS.map((header, index) => {
                  if (header.key === "registrationConfirmed") {
                    return (
                      <TableCell key={index}>
                        {vendor[header.key] === "true" ? (
                          <Box
                            sx={{
                              backgroundColor: "success.main",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: 1,
                              p: "5px 2px",
                              fontSize: 13,
                            }}
                          >
                            Verified
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              backgroundColor: "error.main",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: 1,
                              p: "5px 5px",
                              fontSize: 13,
                            }}
                          >
                            Unverified
                          </Box>
                        )}
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell key={index}>{vendor[header.key]}</TableCell>
                  );
                })}
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        size="small"
                        aria-label="Edit"
                        onClick={() => openModal("create-vendor-form", vendor)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        color="error"
                        size="small"
                        aria-label="Delete"
                        onClick={() => handleDeletePlan(vendor)}
                      >
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TotalComponent loading={loading} totalRecords={totalRecords} />
    </TableContainer>
  );
};

export default React.memo(VendorTable);
