import React from "react";
import Swal from "sweetalert2";

// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import UndoIcon from "@mui/icons-material/Undo";

// state
import { useSelector } from "react-redux";
import store from "../../../store";

import { reverseTheOperationService } from "../../../services/CustomerServices";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

export default function ReverseAssignButton(props) {
  const { customer } = props;

  const pathname = useGetCurrentPathname();

  const { customers, loadMoreOptions, urlParams, totalRecords } = useSelector(
    (state) => state.customer
  );
  const { user } = useSelector((state) => state.auth);

  const reverseOperation = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Would you like to reverse this assignment?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) return;

      const central = JSON.parse(localStorage.getItem("central"));

      store.dispatch(setLoading(true));

      await reverseTheOperationService(central.tableMainCategory.endpoint, {
        _id: id,
      });

      const filteredCustomers = customers.filter(
        (customer) => customer._id !== id
      );

      store.dispatch(
        saveCustomers({
          customers: filteredCustomers,
          total: totalRecords - 1,
          loadMoreOptions: { ...loadMoreOptions },
          urlParams,
        })
      );
    } catch (error) {
      console.log("error", error);
      alert(error);
    }
  };

  return (
    <>
      {pathname === "/central" &&
        user.accountType !== "closer" &&
        !customer.isVendorApproved && (
          <Tooltip title="Reverse Assign">
            <IconButton
              color="actionButton"
              aria-label="Revert Assign"
              onClick={() => reverseOperation(customer._id)}
            >
              <UndoIcon style={iconStyle} />
            </IconButton>
          </Tooltip>
        )}
    </>
  );
}
