import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RolePermissionComponent from "../../permissions/RolePermissionComponent";
import { fetchFilesServices } from "../../../services/CustomerServices";

const defaultStyles = {
  fontSize: "12px",
  fontWeight: 400,
  mb: "23px",
};

export default function InfoMenu(props) {
  const { anchorEl, openMenu, closeMenu } = props;

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const res = await fetchFilesServices();
      if (res) setFiles(res);
    };

    fetchFiles();
  }, []);

  return (
    <RolePermissionComponent roles={["admin", "staff", "manager"]}>
      <Button
        onClick={openMenu}
        sx={{
          mb: defaultStyles.mb,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: defaultStyles.fontSize,
          fontWeight: defaultStyles.fontWeight,
        }}
      >
        <p style={{ margin: 0 }}>Info</p>
        <KeyboardArrowDownIcon fontSize="small" sx={{ pb: "3px" }} />
      </Button>
      <Menu
        sx={{ mt: "25px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "left",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>
          <Typography
            component={Link}
            to={`/info-list`}
            sx={{
              textDecoration: "none",
              color: "#222",
              width: "100%",
            }}
          >
            All
          </Typography>
        </MenuItem>
        {files.map((file, index) => (
          <MenuItem key={index} onClick={closeMenu}>
            <Typography
              component={Link}
              to={`/info-list?tag=${file._id}`}
              sx={{
                textDecoration: "none",
                color: "#222",
                width: "100%",
              }}
            >
              {file.tag}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </RolePermissionComponent>
  );
}
