import {
  SET_LOADING,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  SET_DIALOG,
  SET_MODAL,
  SET_FORM_SUBMITTING,
  SET_TWILIO,
  SET_TWILIO_CALL_STATUS,
  SET_BANDWIDTH,
} from "../constants/ActionTypes.js";

const initialState = {
  loading: false,
  formSubmitting: false,
  twilio: {
    isReady: false,
    callStatus: "Call",
    token: "",
    device: "",
  },
  bandwidth: {
    isReady: false,
    callStatus: "Call",
    token: "",
    device: "",
  },
  modal: {
    open: false,
    for: "",
    data: {},
    size: "sm",
  },
  dialog: {
    open: false,
    for: "",
    data: {},
  },
  message: {
    show: false,
    type: "",
    msg: "",
  },
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };

    case SET_FORM_SUBMITTING:
      console.log(`SET_FORM_SUBMITTING => `, action.payload);
      return {
        ...state,
        formSubmitting: action.payload,
        modal: {
          ...state.modal,
          open: action.keepModal ? true : action.payload,
        },
      };

    case SET_MODAL:
      return {
        ...state,
        modal: {
          open: action.payload.open,
          for: action.payload.for,
          data: action.payload.data,
          size: action.payload.size ?? state.modal.size,
        },
      };

    case SET_DIALOG:
      return {
        ...state,
        dialog: {
          open: action.payload.open,
          for: action.payload.for,
          data: action.payload.data,
        },
      };

    case SHOW_MESSAGE:
      return {
        ...state,
        formSubmitting: false,
        message: {
          show: true,
          type: action.payload.type,
          msg: action.payload.msg,
        },
      };

    case HIDE_MESSAGE:
      return {
        ...state,
        message: { show: false, type: "info", msg: "" },
      };

    case SET_TWILIO_CALL_STATUS:
      return {
        ...state,
        twilio: { ...state.twilio, ...action.payload },
      };

    case SET_BANDWIDTH:
      console.log(`payload => `, action.payload);
      return { ...state, bandwidth: { ...state.bandwidth, ...action.payload } };

    default:
      return state;
  }
};

export default AppReducer;
