const config = {
  // baseUrl: "http://localhost:4000/api",
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  defaultFilterParams: {
    tag: "",
    process: "",
    pa: "",
    staffAssignedId: "",
    assignedToCloserId: "",
    assignedToVendorId: "",
    filterYearStart: 0,
    filterYearEnd: 0,
    filterSearch: "",
    filterReminder: false,
    leadClass: "",
    limit: "100",
    skip: "0",
  },
};

export default config;
