import Swal from "sweetalert2";

// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

// state
import { useSelector } from "react-redux";
import store from "../../../store";
import { verifyLeadService } from "../../../services/CustomerServices";
import { saveCustomers } from "../../../store/actions/CustomerActions";
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

export default function VerifyLeadButton(props) {
  const { customer } = props;
  const pathname = useGetCurrentPathname();
  const { customers, loadMoreOptions, urlParams } = useSelector(
    (state) => state.customer
  );

  const verifyLead = async (c) => {
    const result = await Swal.fire({
      title: "Would you like to verify this lead?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;

    await verifyLeadService({ _id: c._id });

    Swal.fire("Success", "Lead verified", "success");

    // verify the lead
    const filteredCustomers = customers.filter(
      (customer) => customer._id !== c._id
    );

    store.dispatch(
      saveCustomers({
        customers: filteredCustomers,
        total: filteredCustomers.length,
        loadMoreOptions: { ...loadMoreOptions },
        urlParams,
      })
    );
  };

  return (
    <>
      {pathname === "/closer" ? (
        <Tooltip title="Verify Lead">
          <IconButton
            color="actionButton"
            aria-label="Verify Lead"
            onClick={() => verifyLead(customer)}
          >
            <TaskAltIcon style={iconStyle} />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
}
