import Button from "@mui/material/Button";
import React from "react";

function CancelButton({ loading, handleClick }) {
  return (
    <Button
      variant="contained"
      color="error"
      disabled={loading}
      onClick={handleClick}
    >
      Cancel
    </Button>
  );
}

export default CancelButton;
