import { useEffect, useState, useCallback } from "react";

// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// components
import MainRootElement from "../components/layouts/MainRootElement";
import VendorTable from "../components/tables/VendorTable";

// state
import store from "../store";
import { fetchVendors } from "../store/actions/VendorActions";
import SmallFormModalComponent from "../components/modals/SmallFormModalComponent";

function Vendor() {
  useEffect(() => {
    store.dispatch(fetchVendors());
  }, []);

  const [modalOptions, setModalOptions] = useState({
    open: false,
    for: "customer-form",
    data: {},
    size: "md",
  });

  const handleOpen = useCallback((type, data) => {
    setModalOptions({
      open: true,
      for: "create-vendor-form",
      data: data,
      size: "sm",
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalOptions({ open: false, for: "", data: {} });
  }, []);

  return (
    <MainRootElement>
      <Box className="vendor-table-container">
        <Button
          sx={{ m: 1 }}
          color="secondary"
          size="small"
          variant="contained"
          onClick={() => handleOpen("create-vendor-form", "")}
        >
          Add New Vendor
        </Button>

        <VendorTable openModal={handleOpen} />
      </Box>

      {modalOptions.open ? (
        <SmallFormModalComponent
          modalOptions={modalOptions}
          closeModal={closeModal}
        />
      ) : null}
    </MainRootElement>
  );
}

export default Vendor;
