import {
  UPDATE_CUSTOMERS,
  SET_CUSTOMERS,
  LOAD_MORE_CUSTOMERS,
  SET_LOADING,
  FILTERED_CUSTOMERS,
  SET_ZIPCODES,
  SET_USER_LIST,
  RESET_LOAD_MORE_OPTIONS,
  RESET_URL_PARAMS,
  SET_USER_LIST_ONLY,
  SET_HEALTH_PLANS,
  SAVE_CUSTOMERS,
  SAVE_USER_LIST,
  ASSIGN_CATEGORY,
  SAVE_FILES,
  SAVE_VENDOR_LIST,
  CLEAR_CUSTOMERS,
  REMOVE_CUSTOMER_FROM_STATE,
} from "../constants/ActionTypes.js";

import config from "../../config/app.js";

const initialState = {
  loading: true,
  defaultFilterParams: config.defaultFilterParams,
  totalRecords: 0,
  defaultCustomers: [], // response from server
  customers: [], // use to filter the result
  modifiedCustomers: [],
  zipCodes: [],
  listCodes: [],
  userList: [],
  closers: [],
  staffs: [],
  vendors: [],
  loadMoreOptions: {
    hasMore: false,
    skip: 0,
  },
  urlParams: {},
  healthPlans: [],
  files: [],
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };

    case SET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        loadMoreOptions:
          action.payload.loadMoreOptions ?? state.loadMoreOptions,
        defaultCustomers: action.payload.customers,
        customers: action.payload.customers,
        totalRecords: action.payload.totalRecords,
        urlParams: action.payload.urlParams,
      };

    case LOAD_MORE_CUSTOMERS:
      return {
        ...state,
        loading: false,
        loadMoreOptions:
          action.payload.loadMoreOptions ?? state.loadMoreOptions,
        customers: action.payload.customers,
        urlParams: action.payload.urlParams,
      };

    case UPDATE_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.payload.customers,
        modifiedCustomers: action.payload.modifiedCustomers,
      };

    case ASSIGN_CATEGORY:
      return {
        ...state,
        loading: false,
        customers: action.payload,
        totalRecords: state.totalRecords - 1,
      };

    case FILTERED_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.payload.filter(
          (customer) => customer.staffAssigned !== null
        ),
      };

    case SET_ZIPCODES:
      return { ...state, zipCodes: action.payload };

    case SET_USER_LIST:
      return {
        ...state,
        loading: false,
        customers: action.payload.customers.filter(
          (customer) => customer.staffAssigned !== null
        ),
        userList: action.payload.users,
        staffs: action.payload.users.filter((e) => e.accountType === "staff"),
        closers: action.payload.users.filter((e) => e.accountType === "closer"),
        vendors: action.payload.vendors.map((vendor) => ({
          _id: vendor._id,
          email: vendor.contactEmail,
          name: vendor.name,
        })),
        totalRecords: action.payload.totalRecords,
        urlParams: action.payload.urlParams,
        loadMoreOptions:
          action.payload.loadMoreOptions ?? state.loadMoreOptions,
      };

    case RESET_LOAD_MORE_OPTIONS:
      return { ...state, loadMoreOptions: { hasMore: false, skip: 100 } };

    case RESET_URL_PARAMS:
      return {
        ...state,
        urlParams: {},
        loadMoreOptions: { hasMore: false, skip: 100 },
      };

    case SET_USER_LIST_ONLY:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };

    case SET_HEALTH_PLANS:
      return {
        ...state,
        loading: false,
        healthPlans: action.payload,
        totalRecords: action.payload.length,
      };

    case SAVE_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.payload.customers,
        totalRecords: action.payload.total,
        loadMoreOptions: {
          hasMore: action.payload.loadMoreOptions.hasMore,
          skip: action.payload.loadMoreOptions.skip,
        },
        urlParams: action.payload.urlParams,
      };

    case SAVE_USER_LIST:
      return {
        ...state,
        userList: action.payload,
        staffs: action.payload.filter((e) => e.accountType === "staff"),
        closers: action.payload.filter((e) => e.accountType === "closer"),
      };

    case SAVE_FILES:
      return { ...state, files: action.payload };

    case SAVE_VENDOR_LIST:
      return { ...state, vendors: action.payload };

    case CLEAR_CUSTOMERS:
      return { ...state, loading: true, customers: [] };

    case REMOVE_CUSTOMER_FROM_STATE:
      return {
        ...state,
        customers: action.payload.customers,
        totalRecords: action.payload.totalRecords,
      };

    default:
      return state;
  }
};

export default CustomerReducer;
