import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import store from "../../../store";

// components
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

// others
import BirthYearSelect from "../../selects/BirthYearSelect";

const ProcessResultFilter = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { loading, urlParams } = useSelector((state) => state.customer);

  const handleFilterMenu = async (data) => {
    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = {
      ...urlParams,
      filterYearStart: startDate === "--" ? "" : startDate,
      filterYearEnd: endDate === "--" ? "" : endDate,
    };

    const customZipCodes = urlParams.customZipCodes ?? null;
    const res = await fetchCustomersService(newUrlParams, customZipCodes);

    let payload = {
      ...res,
      urlParams: newUrlParams,
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  useEffect(() => {
    if (startDate !== "" || endDate !== "") {
      handleFilterMenu(
        { start: startDate || 1900, end: endDate || 2023 },
        "birth-year"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className="d-flex align-center">
      <p className="filter-menu-label">Year of Birth</p>
      <BirthYearSelect
        startDate={startDate}
        endDate={endDate}
        setStartDate={(value) => setStartDate(value ?? "--")}
        setEndDate={(value) => setEndDate(value ?? "--")}
      />
    </div>
  );
};

export default ProcessResultFilter;
