import { useEffect, useState } from "react";

// mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

// img
import MainLogo from "../assets/images/main-logo.png";
import LoginCover from "../assets/images/login-cover.jpg";

// components
import LoginForm from "../components/forms/LoginForm";
import SnackbarComponent from "../components/alerts/SnackbarComponent";

// state
import { useSelector } from "react-redux";
import store from "../store";
import { removeAuth, setLoading, setUser } from "../store/actions/UserActions";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  loginService,
  resetOrganizationPassword,
  verifyOrganizationEmail,
} from "../services/AuthServices";
import { showMessage } from "../store/actions/AppActions";
import { redirectRoutes } from "../constants/permissions";
import ResetOrgPassword from "../components/forms/ResetOrgPassword";

const styles = {
  paperContainer: {
    backgroundImage: `url(${LoginCover})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  },
};

function Login(props) {
  console.log("Rendering Login");

  const Navigation = useNavigate();

  const [searchParams] = useSearchParams();

  const [verify, setVerify] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const [forms, setForms] = useState({
    showLoginForm: false,
    showPasswordResetForm: false,
  });

  const { loading } = useSelector((state) => state.auth);

  const doLogin = async (formData) => {
    try {
      let res = await loginService(formData);

      if (res.orgType && !res.accountType) {
        res.accountType = res.orgType;
      }

      console.log("res", res);

      store.dispatch(setUser(res));

      const found = redirectRoutes.find(
        (redirectRoute) => redirectRoute.accountType === res.accountType
      );

      if (found) {
        Navigation(found.redirectTo);
      }

      // window.location.reload();
    } catch (error) {
      console.log("error", error);
      store.dispatch(showMessage("error", "Login failed. Invalid credentials"));
    }
  };

  const handlEverifyOrganizationEmail = async () => {
    const uuid = searchParams.get("uuid");

    if (!uuid) {
      setForms({
        ...forms,
        showLoginForm: true,
      });

      return;
    }

    try {
      store.dispatch(setLoading(true));

      await verifyOrganizationEmail(uuid);

      store.dispatch(setLoading(false));

      setVerify({
        status: true,
        error: false,
        msg: "Your account is verified. Please change the password.",
      });

      setForms({
        ...forms,
        showLoginForm: false,
        showPasswordResetForm: true,
      });
    } catch (error) {
      store.dispatch(setLoading(false));
      console.log("error", error);
      setVerify({
        status: true,
        error: true,
        msg: error.data.message,
      });
    }
  };

  useEffect(() => {
    store.dispatch(removeAuth());

    handlEverifyOrganizationEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePassword = async (password) => {
    console.log("changePassword");

    try {
      store.dispatch(setLoading(true));

      await resetOrganizationPassword(password);

      store.dispatch(
        showMessage("success", "Password changed, Proceed to login")
      );

      store.dispatch(setLoading(false));

      setVerify({
        status: false,
        error: false,
        msg: "",
      });
    } catch (error) {
      console.log("error", error);
      store.dispatch(showMessage("error", error.data.message));
    }
  };

  return (
    <Paper style={styles.paperContainer}>
      <Box sx={{ height: "100vh" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "40%",
            position: "absolute",
            left: "30%",
            top: 80,
            p: 3,
            borderRadius: 5,
          }}
        >
          <div
            style={{ textAlign: "center", paddingBottom: 10, color: "#777" }}
          >
            <img
              src={MainLogo}
              width="350"
              style={{ marginBottom: 10 }}
              alt="login-cover"
            />
            <h3>Welcome to Medicentric</h3>
            {verify.status && (
              <Box
                sx={{
                  backgroundColor: verify.error ? "error.main" : "success.main",
                  color: "#fff",
                  textAlign: "center",
                  p: "10px 0px",
                  mb: 3,
                  borderRadius: 2,
                }}
              >
                {verify.msg}
              </Box>
            )}
          </div>

          {forms.showPasswordResetForm && (
            <ResetOrgPassword
              loading={loading}
              changePassword={changePassword}
            />
          )}

          {forms.showLoginForm && (
            <LoginForm loading={loading} doLogin={doLogin} />
          )}
        </Box>
      </Box>

      <SnackbarComponent />
    </Paper>
  );
}

export default Login;
