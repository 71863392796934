import {
  SET_LOADING,
  SET_VENDOR,
  SHOW_MESSAGE,
  SET_MODAL,
  SET_FORM_SUBMITTING,
} from "../constants/ActionTypes.js";

import {
  fetchVendorsService,
  removeVendorService,
} from "../../services/VendorServices.js";

function showMessage(type, msg, dispatch) {
  dispatch({
    type: SHOW_MESSAGE,
    payload: { type, msg },
  });
}

export const setLoading = (value) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const setModal = (value) => (dispatch) => {
  dispatch({ type: SET_MODAL, payload: value });
};

export const fetchVendors = (skipAmount) => async (dispatch, getState) => {
  try {
    if (getState().vendor.loading === false) {
      dispatch({ type: SET_LOADING, payload: true });
    }

    const res = await fetchVendorsService();

    setTimeout(() => {
      dispatch({
        type: SET_VENDOR,
        payload: {
          totalRecords: res.total,
          vendors: res.vendors,
          loadMoreOptions: {
            hasMore: res.vendors.length < 90 ? false : true,
            skip: skipAmount ?? 100,
          },
        },
      });
    }, 1500);
  } catch (error) {
    console.log(error);
  }
};

export const createVendor = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_FORM_SUBMITTING, payload: true });

    if (res && res.code === 0 && res.message) {
      showMessage("error", res.message, dispatch);
      setTimeout(() => {
        dispatch({
          type: SET_FORM_SUBMITTING,
          payload: false,
          keepModal: true,
        });
      }, 1500);
    } else {
      let vendors = getState().vendor.vendors;

      vendors.unshift(res);

      showMessage("success", "New vendor created successfully!.", dispatch);

      dispatch({
        type: SET_VENDOR,
        payload: {
          totalRecords: vendors.length,
          vendors: vendors,
          loadMoreOptions: {
            hasMore: vendors.length < 90 ? false : true,
            skip: getState().vendor.skipAmount,
          },
        },
      });

      setTimeout(() => {
        dispatch({ type: SET_FORM_SUBMITTING, payload: false });
      }, 1500);
    }
  } catch (error) {
    if (error.status === 404) {
      showMessage("error", "Server error, Please try again", dispatch);
    }
  }
};

export const updateVendor = (formData, res) => async (dispatch, getState) => {
  try {
    let vendors = getState().vendor.vendors.filter(
      (vendor) => vendor !== formData._id
    );

    vendors.unshift(res);

    dispatch({
      type: SET_VENDOR,
      payload: {
        totalRecords: vendors.length,
        vendors: vendors,
        loadMoreOptions: {
          hasMore: vendors.length < 90 ? false : true,
          skip: getState().vendor.skipAmount,
        },
      },
    });

    setTimeout(() => {
      dispatch({ type: SET_FORM_SUBMITTING, payload: false });
    }, 1500);
  } catch (error) {
    if (error.status === 404) {
      showMessage("error", "Server error, Please try again", dispatch);
    }
  }
};

export const removeVendor = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });

    await removeVendorService(formData);

    console.log("formData", formData);

    let vendors = getState().vendor.vendors.filter(
      (vendor) => vendor._id !== formData._id
    );

    showMessage("success", "Vendor removed successfully!.", dispatch);

    dispatch({
      type: SET_VENDOR,
      payload: {
        totalRecords: vendors.length,
        vendors: vendors,
        loadMoreOptions: {
          hasMore: vendors.length < 90 ? false : true,
          skip: getState().vendor.skipAmount,
        },
      },
    });
  } catch (error) {
    if (error.status === 404) {
      showMessage("error", "Server error, Please try again", dispatch);
    }
  }
};
