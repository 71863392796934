import { useEffect, useState } from "react";
import config from "../../config/app";
import axios from "axios";
import Swal from "sweetalert2";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import ImportedFilesTable from "../../components/tables/ImportedFilesTable";
import FileUploaderComponent from "../../components/uploader";
import {
  deleteFilesServices,
  fetchFilesServices,
} from "../../services/CustomerServices";
import { useSelector } from "react-redux";

function ImportData() {
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const startLoading = () => {
    if (!loading) setLoading(true);
  };
  const stopLoading = () => {
    if (loading) setLoading(false);
  };

  const fetchFiles = async () => {
    startLoading();

    const res = await fetchFilesServices();

    setFiles(res);

    stopLoading();
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const startUploading = async (file) => {
    const result = await Swal.fire({
      title: "Enter Tab Name",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Continue",
      showLoaderOnConfirm: true,
      preConfirm: async (textValue) => {
        if (files.length == 0) return;

        var formData = new FormData();

        formData.append("image", file);
        formData.append("tag", textValue);

        axios
          .post(config.baseUrl + "/files/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `${user.token}`,
            },
          })
          .then(() => {
            fetchFiles();
            Swal.fire("Success", "Upload successfully completed", "success");
          })
          .catch(() => {
            Swal.fire("Failed", "Cannot upload", "error");
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    console.log("result", result);
  };

  const handleDelete = async (id) => {
    await deleteFilesServices(id);
    fetchFiles();
    Swal.fire("Success", "File successfully deleted", "success");
  };

  return (
    <MainRootElement>
      <FileUploaderComponent startUploading={startUploading} />

      <div
        style={{
          width: "95%",
          margin: "0px auto",
          border: "1px solid #f1f1f1",
          borderRadius: 3,
        }}
      >
        <ImportedFilesTable files={files} handleDelete={handleDelete} />
      </div>
    </MainRootElement>
  );
}

export default ImportData;
