import {
  ASSIGNMENT_LOGGING_ACTION,
  USER_TYPE,
  LEAD_STATUS,
  LEAD_CATEGORY,
  PATIENT_ASSIGNMENT,
} from "./enums";

export const PATIENT_ASSIGNMENT_LIST = [
  {
    key: PATIENT_ASSIGNMENT.HOME_HEALTH,
    value: "Home health",
  },
  {
    key: PATIENT_ASSIGNMENT.HOSPICE,
    value: "Hospice",
  },
  {
    key: PATIENT_ASSIGNMENT.CLINIC,
    value: "Clinic",
  },
  {
    key: PATIENT_ASSIGNMENT.SIGN_UP,
    value: "Sign up",
  },
  {
    key: PATIENT_ASSIGNMENT.ADHC,
    value: "ADHC",
  },
  {
    key: PATIENT_ASSIGNMENT.PHARMACY,
    value: "Pharmacy",
  },
  /*{
    key: PATIENT_ASSIGNMENT.NOT_INTERESTED,
    value: "Not interested",
  },
  {
    key: PATIENT_ASSIGNMENT.SEND_BACK,
    value: "Send back",
  },
  {
    key: PATIENT_ASSIGNMENT.ENGLISH,
    value: "English",
  },
  {
    key: PATIENT_ASSIGNMENT.PASSED_AWAY,
    value: "Passed Away",
  },
  {
    key: PATIENT_ASSIGNMENT.ANSWERING_MATCHING,
    value: "Answering Maching",
  },
  {
    key: PATIENT_ASSIGNMENT.PROBLEM,
    value: "Problem",
  },*/
];

//English, Passed Away, Answering Maching, Problem

export const PA_ACTION_LIST = [
  {
    key: ASSIGNMENT_LOGGING_ACTION.ASSIGN_TO_CU,
    value: "Assign to CU",
  },
  {
    key: ASSIGNMENT_LOGGING_ACTION.SEND_BACK_TO_CU,
    value: "Send back to CU",
  },
  {
    key: ASSIGNMENT_LOGGING_ACTION.ASSIGN_TO_CLOSER,
    value: "Assign to Closer",
  },
  {
    key: ASSIGNMENT_LOGGING_ACTION.MARK_AS_DELETED,
    value: "Mark as deleted",
  },
];

export const USER_TYPE_LIST = [
  {
    key: USER_TYPE.ADMIN,
    value: "Administrator",
  },
  {
    key: USER_TYPE.MANAGER,
    value: "Manager",
  },
  {
    key: USER_TYPE.CENTRAL_USER,
    value: "Central user",
  },
  {
    key: USER_TYPE.CLOSER,
    value: "Closer",
  },
  {
    key: USER_TYPE.STAFF,
    value: "Staff",
  },
];

export const LEAD_STATUS_LIST = [
  {
    key: LEAD_STATUS.OPEN,
    value: "Open",
  },
  {
    key: LEAD_STATUS.DELETED,
    value: "Deleted",
  },
];

export const LEAD_CATEGORY_LIST = [
  {
    key: LEAD_CATEGORY.ALL,
    value: "All",
  },
  {
    key: LEAD_CATEGORY.LEAD,
    value: "Lead",
  },
  {
    key: LEAD_CATEGORY.NOT_INTERESTED,
    value: "Not Interested",
  },
  {
    key: LEAD_CATEGORY.DISCONNECTED,
    value: "Disconnected",
  },
  {
    key: LEAD_CATEGORY.WRONG_NUMBER,
    value: "Wrong Number",
  },
  {
    key: LEAD_CATEGORY.NO_ANSWER,
    value: "No Answer",
  },
  {
    key: LEAD_CATEGORY.IN_PROCESS,
    value: "In Process",
  },
  {
    key: LEAD_CATEGORY.ENGLISH,
    value: "English",
  },
  {
    key: LEAD_CATEGORY.PASSED_AWAY,
    value: "Passed Away",
  },
  {
    key: LEAD_CATEGORY.ANSWERING_MATCHING,
    value: "Answering Maching",
  },
  {
    key: LEAD_CATEGORY.PROBLEM,
    value: "Problem",
  },
  {
    key: LEAD_CATEGORY.DO_NOT_KNOW,
    value: "Do not know",
  },
  {
    key: LEAD_CATEGORY.CALL_BACK,
    value: "Call back",
  },
  {
    key: LEAD_CATEGORY.EMPTY,
    value: "--Empty--",
  },
];

export const LEAD_CLASS_OPTIONS = [
  {
    key: "",
    value: "All",
  },
  {
    key: "served",
    value: "Served",
  },
  {
    key: "good",
    value: "Good",
  },
  {
    key: "bad",
    value: "Bad",
  },
];

export const VENDOR_TABLE_HEADERS = [
  {
    key: "name",
    value: "Full Name",
  },
  {
    key: "contactName",
    value: "Contact Name",
  },
  {
    key: "address1",
    value: "Address 1",
  },
  {
    key: "address2",
    value: "Address 2",
  },
  {
    key: "city",
    value: "City",
  },
  {
    key: "state",
    value: "State",
  },
  {
    key: "phone",
    value: "Phone",
  },
  {
    key: "zipcode",
    value: "Zip Code",
  },
  {
    key: "contactPhone",
    value: "Contact Phone",
  },
  {
    key: "contactEmail",
    value: "contactEmail",
  },
  {
    key: "registrationConfirmed",
    value: "Status",
  },
];

export const STAFF_REPORT = [
  {
    key: "fname",
    value: "Staff",
  },
  {
    key: "lead",
    value: "Lead",
  },
  {
    key: "inProcess",
    value: "In Process",
  },
  {
    key: "notInterested",
    value: "Not Interested",
  },
  {
    key: "discontinued",
    value: "Discontinued",
  },
  {
    key: "wrongNumber",
    value: "Wrong Number",
  },
  {
    key: "noAnswer",
    value: "No Answer",
  },
  {
    key: "total",
    value: "Total",
  },
];

export const LEAD_REPORT = [
  {
    key: "firstName",
    value: "First Name",
    size: 100,
  },
  {
    key: "middleName",
    value: "Middle Name",
    size: 150,
  },
  {
    key: "lastName",
    value: "Last Name",
    size: 100,
  },
  {
    key: "birthDate",
    value: "Birth Date",
    size: 150,
  },
  {
    key: "phone",
    value: "Phone",
    size: 0,
  },
  {
    key: "address",
    value: "Address",
    size: 0,
  },
  {
    key: "city",
    value: "City",
    size: 0,
  },
  {
    key: "state",
    value: "State",
    size: 0,
  },
  {
    key: "zipCode",
    value: "Zip Code",
    size: 100,
  },
  {
    key: "importDate",
    value: "Import Date",
    size: 150,
  },
  {
    key: "staffAssigned",
    value: "LG Assign",
    size: 150,
  },
];
