import { useSelector } from "react-redux";
import store from "../../../store";

// components
import MultiSelectSearch from "../../inputs/MultiSelectSearch";
import {
  saveCustomers,
  setLoading,
} from "../../../store/actions/CustomerActions";
import { fetchCustomersService } from "../../../services/CustomerServices";

const ZipcodeFilter = () => {
  const { zipCodes, loading, urlParams } = useSelector(
    (state) => state.customer
  );

  const handleFilterMenu = async (event, values, reason) => {
    if (!loading) store.dispatch(setLoading(true));

    // console.log(`values`, values);

    var myArray = values;

    var myArrayQry = myArray
      .map(function (el, idx) {
        return "zipCodes[]=" + el;
      })
      .join("&");

    const res = await fetchCustomersService(urlParams, myArrayQry);

    let payload = {
      ...res,
      urlParams: { ...urlParams, customZipCodes: myArrayQry },
      loadMoreOptions: {
        hasMore: res.customers.length < 90 ? false : true,
        skip: 0,
      },
    };

    store.dispatch(saveCustomers(payload));
  };

  return (
    <MultiSelectSearch
      label="Filter by Zipcodes"
      variant="outlined"
      options={zipCodes}
      onChange={handleFilterMenu}
    />
  );
};

export default ZipcodeFilter;
