export const PATIENT_ASSIGNMENT = {
  HOME_HEALTH: "home-health",
  HOSPICE: "hospice",
  CLINIC: "clinic",
  SIGN_UP: "sign-up",
  ADHC: "adhc",
  PHARMACY: "pharmacy",
  DO_NOT_KNOW: "do-not-know",
  NOT_INTERESTED: "not-interested",
  CALL_BACK: "call-back",
  SEND_BACK: "send-back",
  ENGLISH: "english",
  PASSED_AWAY: "passed-away",
  ANSWERING_MATCHING: "answering-maching",
  PROBLEM: "problem"
};

export const ASSIGNMENT_LOGGING_ACTION = {
  ASSIGN_TO_CLOSER: "assign-to-closer",
  SEND_BACK_TO_CU: "send-back-to-cu",
  ASSIGN_TO_CU: "assign-to-cu",
  MARK_AS_DELETED: "mark-as-deleted",
};

export const USER_TYPE = {
  STAFF: "staff",
  MANAGER: "manager",
  ADMIN: "admin",
  CENTRAL_USER: "central-user",
  CLOSER: "closer",
};

export const LEAD_STATUS = {
  OPEN: "open",
  DELETED: "deleted",
};

export const LEAD_CATEGORY = {
  LEAD: "LE",
  NOT_INTERESTED: "NI",
  DISCONNECTED: "DS",
  WRONG_NUMBER: "WN",
  NO_ANSWER: "NA",
  IN_PROCESS: "IP",
  ENGLISH: "EN",
  PASSED_AWAY: "PA",
  ANSWERING_MATCHING: "AM",
  PROBLEM: "PB",
  DO_NOT_KNOW: "do-not-know",
  CALL_BACK: "call-back",
  ALL: "all",
  EMPTY: "empty",
};
