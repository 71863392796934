import { FileUploader } from "react-drag-drop-files";
import "./styles.css";

const fileTypes = ["CSV"];

export default function FileUploaderComponent({ startUploading }) {
  return (
    <div className="uploader-input">
      <h3>IMPORT DATA</h3>
      <FileUploader
        multiple={false}
        handleChange={startUploading}
        name="file"
        types={fileTypes}
      />
    </div>
  );
}
