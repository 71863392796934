import API from "../config/api";

export const loginService = async (payload) => {
  try {
    const res = await API.post(`auth/login`, payload);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const user = async () => {
  try {
    const res = await API.get(`user`);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const createAccount = async (formData) => {
  try {
    const res = await API.post(`auth/register`, formData);
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const verifyOrganizationEmail = async (uuid) => {
  try {
    const res = await API.post(`organization/active-organization`, { uuid });
    return res.data;
  } catch (error) {
    throw error.response;
  }
};

export const resetOrganizationPassword = async (password) => {
  try {
    const res = await API.post(`organization/resetPassword`, { password });
    console.log("res", res);
    // return res.data;
  } catch (error) {
    console.log("error", error);
    // throw error.response;
  }
};
