import React from "react";

// mui
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// components
// import HeaderCellComponent from "./HeaderCellComponent";
import CustomTextInput from "../../inputs/CustomTextInput";
import DateRangePicker from "../../inputs/DateRangePicker";
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";
import LGFilterJs from "../../dropdowns/LGFilter";
import { useLocation } from "react-router";

// style
const TableCellStyle = {
  paddingTop: 2,
  paddingRight: 3,
  paddingBottom: 5,
  paddingLeft: 1,
};

const tableHeaderTitleStyle = {
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "0.750rem",
  fontWeight: 500,
  mb: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function TableHeadComponent({ dateRangeFilter, textFilter }) {
  console.log("Rendering => TableHeadComponent");

  const location = useLocation();
  const pathname = useGetCurrentPathname();

  function isAtcTab() {
    if (location && location.search) {
      if (location.search.includes("tab=atc")) return true;
    }

    return false;
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ ...TableCellStyle, maxWidth: 100 }}>
          <Typography sx={{ ...tableHeaderTitleStyle, textAlign: "center" }}>
            #
          </Typography>
          <div style={{ width: 100, height: 27 }}></div>
        </TableCell>
        <TableCell style={{ ...TableCellStyle, maxWidth: 150 }}>
          <Typography sx={tableHeaderTitleStyle}>First Name</Typography>
          <CustomTextInput
            placeholder="Find First Name"
            getValue={(value) => textFilter("filterFirstName", value)}
          />
        </TableCell>

        {pathname !== "/closer" ? (
          <TableCell style={TableCellStyle}>
            <Tooltip title="Middle Name" placement="top">
              <Typography sx={{ ...tableHeaderTitleStyle, maxWidth: 30 }}>
                Middle Name
              </Typography>
            </Tooltip>
            <CustomTextInput
              placeholder="Find Middle Name"
              getValue={(value) => textFilter("filterMiddleName", value)}
            />
          </TableCell>
        ) : null}

        <TableCell style={TableCellStyle}>
          <Typography sx={{ ...tableHeaderTitleStyle, minWidth: 100 }}>
            Last Name
          </Typography>
          <CustomTextInput
            placeholder="Find Last Name"
            getValue={(value) => textFilter("filterLastName", value)}
          />
        </TableCell>

        {pathname === "/lead" ? (
          <TableCell style={TableCellStyle}>
            <Typography sx={{ ...tableHeaderTitleStyle, minWidth: 100 }}>
              Insurance
            </Typography>
            <div style={{ width: 100, height: 27 }}></div>
          </TableCell>
        ) : null}

        <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
          <Typography sx={{ ...tableHeaderTitleStyle, width: 120 }}>
            Birth Date
          </Typography>
          <CustomTextInput
            placeholder="Find DOB"
            getValue={(value) => textFilter("filterDob", value)}
          />
        </TableCell>

        <TableCell style={{ ...TableCellStyle, maxWidth: 50 }}>
          <Typography sx={{ ...tableHeaderTitleStyle, maxWidth: 50 }}>
            Phone
          </Typography>
          <CustomTextInput
            placeholder="Find Phone"
            getValue={(value) => textFilter("filterPhone", value)}
          />
        </TableCell>

        <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
          <Typography sx={{ ...tableHeaderTitleStyle, width: 180 }}>
            Address
          </Typography>
          <CustomTextInput
            placeholder="Find Address"
            getValue={(value) => textFilter("filterAddress", value)}
          />
        </TableCell>

        <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
          <Typography sx={tableHeaderTitleStyle}>City</Typography>
          <CustomTextInput
            placeholder="Find City"
            getValue={(value) => textFilter("filterCity", value)}
          />
        </TableCell>

        <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
          <Typography sx={tableHeaderTitleStyle}>State</Typography>
          <div style={{ width: 45, height: 27 }}></div>
        </TableCell>

        <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
          <Typography sx={tableHeaderTitleStyle}>Zip</Typography>
          <CustomTextInput
            placeholder="Find Zip"
            getValue={(value) => textFilter("filterZipCode", value)}
          />
        </TableCell>

        <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
          <Typography sx={{ ...tableHeaderTitleStyle }}>Actions</Typography>
          <div style={{ width: 100, height: 27 }}></div>
        </TableCell>

        {pathname === "/central" ||
        pathname === "/closer" ||
        pathname === "/lead" ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
            <Typography sx={tableHeaderTitleStyle}>Status</Typography>
            <div style={{ width: 70, height: 27 }}></div>
          </TableCell>
        ) : null}

        {["/info-list", "/lead-generator", "/closer", "/central"].includes(
          pathname
        ) && (
          <TableCell style={{ ...TableCellStyle, maxWidth: 170 }}>
            <Typography sx={tableHeaderTitleStyle}>Process Result</Typography>
            <DateRangePicker dateRangeFilter={dateRangeFilter} />
          </TableCell>
        )}

        {pathname === "/info-list" || pathname === "/lead-generator" ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 200 }}>
            <Typography sx={tableHeaderTitleStyle}>Comments</Typography>
            <div style={{ width: 200, height: 27 }}></div>
          </TableCell>
        ) : null}

        {pathname === "/info-list" || pathname === "/lead-generator" ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
            <Typography sx={tableHeaderTitleStyle}>Reminder Date</Typography>
            <div style={{ width: 100, height: 27 }}></div>
          </TableCell>
        ) : null}

        {pathname === "/lead-generator" || pathname === "/lead" ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
            <Typography sx={tableHeaderTitleStyle}>Import Date</Typography>
            <div style={{ width: 100, height: 27 }}></div>
          </TableCell>
        ) : null}

        {pathname === "/central" && isAtcTab() ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
            <Typography sx={tableHeaderTitleStyle}>Assigned Date</Typography>
            <DateRangePicker dateRangeFilter={dateRangeFilter} />
          </TableCell>
        ) : null}

        {pathname === "/lead" ? (
          <TableCell style={{ ...TableCellStyle, minWidth: 100 }}>
            <div style={{ paddingTop: 45 }}>
              <Typography sx={tableHeaderTitleStyle}>Lead Created</Typography>
              <DateRangePicker dateRangeFilter={dateRangeFilter} />
            </div>
          </TableCell>
        ) : null}

        {pathname === "/info-list" ||
        pathname === "/lead-generator" ||
        pathname === "/central" ||
        pathname === "/closer" ||
        pathname === "/lead" ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
            <Typography sx={tableHeaderTitleStyle}>LG Assigned</Typography>
            <LGFilterJs textFilter={textFilter} />
          </TableCell>
        ) : null}

        {pathname === "/closer" || pathname === "/lead" ? (
          <TableCell style={{ ...TableCellStyle, maxWidth: 70 }}>
            <Typography sx={tableHeaderTitleStyle}>CU Assigned</Typography>
            <div style={{ width: 150, height: 27 }}></div>
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
}

export default React.memo(TableHeadComponent);
