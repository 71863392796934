import Box from "@mui/material/Box";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function OptionSelect(props) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <p className="input-label">{props.label}</p>
      <FormControl fullWidth>
        <Select
          labelId={"demo-simple-select-label" + props.label}
          id={"demo-simple-select" + props.label}
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
          size="small"
        >
          {props.options.map((option, index) => (
            <MenuItem key={index} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
