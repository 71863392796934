import { useEffect, useState } from "react";

// mui
import Box from "@mui/material/Box";

// components
import MainRootElement from "../../components/layouts/MainRootElement";
import AssignmentLoggingReportTable from "../../components/tables/AssignmentLoggingReportTable";
import { fetchLogListServices } from "../../services/CustomerServices";

function AssignmentLoggingReport() {
  const [filters, setFilters] = useState({
    limit: 100,
    skip: 0,
    searchType: "",
    q: "",
  });

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [logs, setLogs] = useState([]);
  const [counts, setCounts] = useState({ assigned: 0, total: 0 });

  const fetchData = async () => {
    const res = await fetchLogListServices(filters);

    setLogs(res.logs);
    setCounts(res.counts);

    if (res.logs.length < 90) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadMore = async () => {
    const newFilters = {
      ...filters,
      skip: filters.skip + 100,
    };

    const res = await fetchLogListServices(newFilters);

    console.log("res", res);

    setLogs([...logs, ...res.logs]);
    setCounts(res.counts);

    if (res.logs.length < 90) {
      setHasMore(false);
    }

    setFilters(newFilters);
  };

  const filterCustomer = async (customerFilters) => {
    setLoading(true);

    const newFilters = { ...filters, ...customerFilters };

    if (
      customerFilters.filterCustomer &&
      customerFilters.filterCustomer.length < 2
    ) {
      return;
    }

    if (
      customerFilters.filterAssignTo &&
      customerFilters.filterAssignTo.length < 2
    ) {
      return;
    }

    const res = await fetchLogListServices(newFilters);

    setLogs(res.logs);
    setCounts(res.counts);

    if (res.logs.length < 90) {
      setHasMore(false);
    }

    setFilters(newFilters);
    setLoading(false);
  };

  return (
    <MainRootElement>
      <div style={{ margin: "0px 20px" }}>
        <AssignmentLoggingReportTable
          loading={loading}
          hasMore={hasMore}
          logs={logs}
          filterCustomer={filterCustomer}
          loadMore={loadMore}
        />
      </div>

      <Box
        sx={{
          backgroundColor: "#023e8a",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            padding: "5px 5px",
            fontWeight: 600,
            color: "#fff",
            fontSize: 13,
          }}
        >
          Total Leads: {counts.total}
        </p>

        <p
          style={{
            margin: 0,
            padding: "5px 10px",
            fontWeight: 600,
            color: "#fff",
            fontSize: 13,
          }}
        >
          Total Assigned to Close: {counts.assigned}
        </p>
      </Box>
    </MainRootElement>
  );
}

export default AssignmentLoggingReport;
