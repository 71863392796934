export const redirectRoutes = [
  {
    accountType: "admin",
    redirectTo: "/",
  },
  {
    accountType: "manager",
    redirectTo: "/",
  },
  {
    accountType: "central-user",
    redirectTo: "/central?category=assign-to-closer",
  },
  {
    accountType: "closer",
    redirectTo: "/central?tab=atv&category=assign-to-vendor",
  },
  {
    accountType: "staff",
    redirectTo: "/info-list",
  },
  {
    accountType: "vendor",
    redirectTo: "/central?category=vendor-assigned",
  },
];

// tab=tbstc&category=assign-to-closer
