import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tooltip from "@mui/material/Tooltip";

const defaultStyles = {
  fontSize: "12px",
  fontWeight: 400,
  mb: "23px",
};

export default function ProfileMenu(props) {
  const { anchorEl, openMenu, closeMenu } = props;

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const getUserDetails = useCallback(() => {
    if (user.orgType === "vendor") {
      return user.name;
    }

    return `${user.fname} ${user.lname} | ${user.office.name}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    closeMenu();
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <Button
          onClick={openMenu}
          sx={{
            mb: defaultStyles.mb,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: defaultStyles.fontSize,
            fontWeight: defaultStyles.fontWeight,
          }}
        >
          <p style={{ margin: 0 }}>{getUserDetails()}</p>
          <KeyboardArrowDownIcon fontSize="small" sx={{ pb: "3px" }} />
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "25px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={logout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
