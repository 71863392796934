// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { Button } from "@mui/material";

// state
import { useSelector } from "react-redux";
import config from "../../../config/app";
import { useGetCurrentPathname } from "../../../hooks/useGetCurrentPathname";

const iconStyle = {
  fontSize: 18,
};

const ALLOWED_PATHNAMES = ["/lead-generator", "/info-list", "/closer", "/lead"];

export default function PrintButton({
  customerId,
  saveAndPrint,
  showBlockButton,
}) {
  const pathname = useGetCurrentPathname();
  const { user } = useSelector((state) => state.auth);

  const handlePrint = () => {
    const url = `${config.baseUrl}/customer/pdf/${customerId}?i=1&token=${user.token}`;
    const win = window.open(url);
    win.focus();
  };

  if (!ALLOWED_PATHNAMES.includes(pathname)) {
    return null;
  }

  return (
    <Tooltip title="Print">
      {!showBlockButton ? (
        <IconButton
          color="actionButton"
          aria-label="Print"
          onClick={handlePrint}
        >
          <PrintOutlinedIcon style={iconStyle} />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={saveAndPrint}>
          Print
        </Button>
      )}
    </Tooltip>
  );
}
