import {
  SET_AUTH,
  REMOVE_AUTH,
  SET_USER,
  SET_LOADING,
} from "../constants/ActionTypes.js";

const initialState = {
  loading: false,
  status: false, // auth status
  user: {},
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };

    case SET_USER:
      return {
        ...state,
        status: action.payload ? true : false,
        user: action.payload,
        loading: false,
      };

    case SET_AUTH:
      return { ...state, status: action.payload, loading: false };

    case REMOVE_AUTH:
      return { ...state, status: false, user: {}, loading: false };

    default:
      return state;
  }
};

export default AuthReducer;
