import React from "react";
import dayjs from "dayjs";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import { updateApproval } from "../../store/actions/CustomerActions";
import TotalComponent from "../TotalComponent";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const labels = {
  "home-health": "Home Health",
  hospice: "Hospice",
  clinic: "Clinic",
  "sign-up": "Sign up",
};

export const VendorTable = () => {
  const { customers, loading } = useSelector((state) => state.customer);

  const handleCheckbox = (checked, customer, type) => {
    store.dispatch(updateApproval(customer._id, checked, type));
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Assigned To</TableCell>
            <TableCell>Approval Date</TableCell>
            <TableCell>Approval</TableCell>
            <TableCell>Cancel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            [1, 2, 3, 4, 5, 6, 7].map((nr) => {
              return (
                <TableRow key={nr}>
                  {[1, 2, 3, 4, 5, 6].map((header, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={35}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

          {!loading &&
            customers.map((customer, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {customer.firstName}
                </TableCell>
                <TableCell>{customer.lastName}</TableCell>
                <TableCell>{labels[customer.patientAssignment]}</TableCell>
                <TableCell>
                  {customer.patientAssignmentApproval &&
                    customer.patientAssignmentApprovalDate &&
                    dayjs(customer.patientAssignmentApprovalDate).format(
                      "MMM DD YYYY"
                    )}
                </TableCell>
                <TableCell>
                  <Checkbox
                    {...label}
                    checked={
                      customer.patientAssignmentApproval === "approval"
                        ? true
                        : false
                    }
                    onChange={(event) =>
                      handleCheckbox(event.target.checked, customer, "approval")
                    }
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    {...label}
                    checked={
                      customer.patientAssignmentApproval === "cancel"
                        ? true
                        : false
                    }
                    onChange={(event) =>
                      handleCheckbox(event.target.checked, customer, "cancel")
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TotalComponent />
    </TableContainer>
  );
};

export default React.memo(VendorTable);
