import { useFormik } from "formik";
import * as Yup from "yup";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Custom components
import FormButtons from "../buttons/FormButtons";
import TextInput from "../inputs/TextInput";
import FormCloseButton from "../buttons/FormCloseButton";

function ManageUserForm({ closeForm, data, loading, handleForm }) {
  console.log("Rendering ManageUserForm");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: data._id,
      zipCodes: data.zipCodes.split(",") ?? "",
      fname: data.fname,
      lname: data.lname,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      confirmPassword: Yup.string().when("password", {
        is: (FieldA) => FieldA,
        then: Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("password")], "Passwords must match"),
      }),
    }),
    onSubmit: (values) => {
      let newValues = {
        ...data,
        ...values,
        zipCodes: values.zipCodes.length > 1 ? values.zipCodes.toString() : "",
      };

      handleForm(newValues);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Edit User</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="text"
            label="First Name"
            placeholder="Please enter the first name"
            value={formik.values.fname}
            getValue={(value) => formik.setFieldValue("fname", value)}
            onBlur={() => formik.setFieldTouched("fname")}
            error={Boolean(formik.errors.fname) && formik.touched.fname}
            errorMsg={formik.errors.fname}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="text"
            label="Last Name"
            placeholder="Please enter the lastname name"
            value={formik.values.lname}
            getValue={(value) => formik.setFieldValue("lname", value)}
            onBlur={() => formik.setFieldTouched("lname")}
            error={Boolean(formik.errors.lname) && formik.touched.lname}
            errorMsg={formik.errors.lname}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="text"
            label="Email"
            placeholder="Please enter the first name"
            value={data.email}
            getValue={(value) => formik.setFieldValue("email", value)}
            onBlur={() => formik.setFieldTouched("email")}
            error={Boolean(formik.errors.email) && formik.touched.email}
            errorMsg={formik.errors.email}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="password"
            label="Password"
            placeholder="Please enter the password"
            value={formik.values.password}
            getValue={(value) => formik.setFieldValue("password", value)}
            onBlur={() => formik.setFieldTouched("password")}
            error={Boolean(formik.errors.password) && formik.touched.password}
            errorMsg={formik.errors.password}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            type="password"
            label="Confirm Password"
            placeholder="Please enter the password"
            value={formik.values.confirmPassword}
            getValue={(value) => formik.setFieldValue("confirmPassword", value)}
            onBlur={() => formik.setFieldTouched("confirmPassword")}
            error={
              Boolean(formik.errors.confirmPassword) &&
              formik.touched.confirmPassword
            }
            errorMsg={formik.errors.confirmPassword}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default ManageUserForm;
