import config from "../config/app";
import {
  downloadCsvService,
  fetchCustomersService,
} from "../services/CustomerServices";

class CustomerModel {
  async fetchCustomers(urlParams) {
    const res = await fetchCustomersService(urlParams);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          ...res,
          urlParams,
          loadMoreOptions: {
            hasMore: res.customers.length < 90 ? false : true,
            skip: 0,
          },
        });
      }, 1000);
    });
  }

  async loadMoreCustomers(urlParams, loadMoreOptions, customers) {
    const res = await fetchCustomersService({
      ...urlParams,
      skip: loadMoreOptions.skip + 100,
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          urlParams,
          customers: [...customers, ...res.customers],
          total: res.total,
          loadMoreOptions: {
            hasMore: res.customers.length < 90 ? false : true,
            skip: loadMoreOptions.skip + 100,
          },
        });
      }, 1000);
    });
  }

  async downloadCsv(params) {
    return new Promise((resolve, reject) => {
      downloadCsvService(params)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  }

  printDocument(customerId, token) {
    const url =
      config.baseUrl + "/customer/pdf/" + customerId + "?i=1&token=" + token;
    const win = window.open(url);
    win.focus();
  }
}

const newModel = new CustomerModel();
export default newModel;
