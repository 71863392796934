// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";

// components
import FormButtons from "../buttons/FormButtons";
import FormCloseButton from "../buttons/FormCloseButton";
import DatePickerComponent from "../inputs/DatePicker";

function ConfirmServiceForm({ closeForm, data, loading, handleForm }) {
  console.log("Rendering ConfirmServiceForm");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceEndDate: data.serviceEndDate ?? "",
      serviceStartDate: data.serviceStartDate ?? "",
    },
    validationSchema: Yup.object({
      serviceEndDate: Yup.string().required("Required"),
      serviceStartDate: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log("values", values);

      let newData = {
        ...values,
        _id: data._id,
      };

      handleForm("assign-to-vendor", newData);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex justify-between">
            <h3 style={{ marginBottom: 0 }}>Confirm as served</h3>
            <FormCloseButton closeForm={closeForm} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <DatePickerComponent
            label="Service Start Date"
            value={formik.values.serviceStartDate}
            getValue={(value) =>
              formik.setFieldValue("serviceStartDate", value)
            }
            onClose={() => console.log("closed")}
          />

          <br />
          <br />

          <DatePickerComponent
            label="Service End Date"
            value={formik.values.serviceEndDate}
            getValue={(value) => formik.setFieldValue("serviceEndDate", value)}
            onClose={() => console.log("closed")}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }}>
        <FormButtons
          loading={loading}
          onSaveClick={() => formik.submitForm()}
          onCancelClick={closeForm}
        />
      </Box>
    </form>
  );
}

export default ConfirmServiceForm;
